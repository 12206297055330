import React, { Component } from 'react';
import {
  Col,
  Row,
  Grid,
  Form,
  FormControl,
  FormGroup,
  ControlLabel
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Parallax } from 'react-parallax';
import { Helmet } from 'react-helmet';
import request from '../../utils/Request';
import ErrorAlert from '../utils/ErrorAlert';
import townhouses from '../../images/townhouses.jpg';

class NewPassword extends Component {
  constructor(props) {
    super(props);
    const searchParams = new URLSearchParams(props.location.search);
    this.state = {
      newPassword: '',
      id: searchParams.get('id'),
      token: searchParams.get('token'),
      confirm: '',
      disabled: false,
      error: false,
      errorMessage: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this);

    this.notify = (text) =>
      toast(text, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  send(e) {
    e.preventDefault();
    this.setState({ disabled: true });
    request
      .post('/account/reset', {
        password: this.state.newPassword,
        id: this.state.id,
        token: this.state.token
      })
      .then(() => {
        this.notify('Password Reset Successfully');

        setTimeout(() => {
          // Start the timer
          this.props.history.push('/login');
        }, 3000);
      })
      .catch((err) => {
        this.setState({ error: true, errorMessage: err.message });
        this.setState({ disabled: false });
      });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>New Password | Korecki Real Estate Services</title>
          <meta
            name="description"
            content="Set your new password to our Client Property Account System (CPAS) where we provide the tools to enable owners to access all information pertaining to their property at a touch of a button."
          />
          <meta
            name="keywords"
            content="korecki,Real Estate,Strata Management services, Rent, Properties, Property Management, Client Property Account System, CPAS, Property Information, password, Account"
          />
        </Helmet>
        <ToastContainer />
        <Parallax bgImage={townhouses} bgImageAlt="Residential" strength={200}>
          <Grid>
            <Row>
              <Col md={12}>
                <h1>Login</h1>
              </Col>
            </Row>
          </Grid>
        </Parallax>
        <div className="contact-section">
          <Grid>
            <Row>
              <Col smOffset={3} md={5}>
                <Form horizontal>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>
                      New
                    </Col>
                    <Col sm={10}>
                      <FormControl
                        name="newPassword"
                        type="password"
                        placeholder="New Password"
                        value={this.state.newPassword}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>
                      Confirm
                    </Col>
                    <Col sm={10}>
                      <FormControl
                        name="confirm"
                        type="password"
                        placeholder="Confirm Password"
                        value={this.state.confirm}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col smOffset={2} sm={10}>
                      <button
                        disabled={this.state.disabled}
                        type="submit"
                        onClick={this.send.bind(this)}
                        className="btn btn-primary">
                        Submit
                      </button>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row>
              {this.state.error ? (
                <ErrorAlert errorMessage={this.state.errorMessage} />
              ) : null}
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default NewPassword;
