import React, { useEffect, useState } from 'react';
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Row,
  HelpBlock
} from 'react-bootstrap';
import useValidation from '../../../validation/useValidation';
import {
  emailValidator,
  phoneValidator,
  requiredValidator
} from '../../../validation/validators';

const formatPhone = (phone) => {
  if (phone && /^\d+$/.test(phone) && phone.length === 10) {
    return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(
      6,
      phone.length
    )}`;
  }
  return phone;
};

export default ({ strataUnitUser, setStrataUnitUser, setValid }) => {
  const [validateRole, roleValid, roleErrors] = useValidation([
    requiredValidator
  ]);
  const [validateUserName, userNameValid, userNameErrors] = useValidation([
    requiredValidator
  ]);
  const [validateEmail, emailValid, emailErrors] = useValidation([
    requiredValidator,
    emailValidator
  ]);
  const [validatePhone, phoneValid, phoneErrors] = useValidation([
    requiredValidator,
    phoneValidator
  ]);
  const [validateFirstName, firstNameValid, firstNameErrors] = useValidation([
    requiredValidator
  ]);
  const [validateLastName, lastNameValid, lastNameErrors] = useValidation([
    requiredValidator
  ]);

  const onChange = (event) => {
    const { name, type, value, checked } = event.target;
    setStrataUnitUser((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const onBlurPhone = (event) => {
    const { name, value } = event.target;
    setStrataUnitUser((prev) => ({ ...prev, [name]: formatPhone(value) }));
    validatePhone(formatPhone(value));
  };

  // editing, so do a validate on load
  const [pendingInitialValidation, setPendingInitialValidation] = useState(
    true
  );
  useEffect(() => {
    if (pendingInitialValidation) {
      validateUserName(strataUnitUser.userName);
      validateFirstName(strataUnitUser.firstName);
      validateLastName(strataUnitUser.lastName);
      validatePhone(strataUnitUser.phoneNumber);
      validateEmail(strataUnitUser.email);
      validateRole(strataUnitUser.strataUnitRole);
      setPendingInitialValidation(false);
    }
  }, [
    pendingInitialValidation,
    strataUnitUser,
    validateUserName,
    validateFirstName,
    validateLastName,
    validatePhone,
    validateEmail,
    validateRole
  ]);

  // compute validity every time a validation changes
  useEffect(() => {
    setValid(
      roleValid &&
        userNameValid &&
        firstNameValid &&
        lastNameValid &&
        phoneValid &&
        emailValid &&
        roleValid
    );
  }, [
    setValid,
    userNameValid,
    firstNameValid,
    lastNameValid,
    phoneValid,
    emailValid,
    roleValid
  ]);

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Edit User</h3>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup validationState={userNameErrors.length && 'error'}>
                    <ControlLabel>Strata Plan / Username</ControlLabel>
                    <FormControl
                      name="userName"
                      type="text"
                      placeholder="Strata Plan / Username"
                      value={strataUnitUser.userName}
                      onChange={(event) => {
                        onChange(event);
                        validateUserName(event.target.value);
                      }}
                      onBlur={(event) => validateUserName(event.target.value)}
                    />
                    {userNameErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup validationState={roleErrors.length && 'error'}>
                    <ControlLabel>Owner Role</ControlLabel>
                    <FormControl
                      componentClass="select"
                      name="strataUnitRole"
                      placeholder="Owner Role"
                      value={strataUnitUser.strataUnitRole}
                      onChange={(event) => {
                        onChange(event);
                        validateRole(event.target.value);
                      }}
                      onBlur={(event) => validateRole(event.target.value)}>
                      <option value="">Select One:</option>
                      <option value={2}>Former Primary/Secondary</option>
                      <option value={3}>Current Tenant</option>
                      <option value={4}>Former Tenant</option>
                      <option value={5}>Defunct</option>
                    </FormControl>
                  </FormGroup>
                  {roleErrors.map((error) => (
                    <HelpBlock key={error}>{error}</HelpBlock>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup
                    validationState={firstNameErrors.length && 'error'}>
                    <ControlLabel>First Name</ControlLabel>
                    <FormControl
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      value={strataUnitUser.firstName}
                      onChange={(event) => {
                        onChange(event);
                        validateFirstName(event.target.value);
                      }}
                      onBlur={(event) => validateFirstName(event.target.value)}
                    />
                    {firstNameErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup validationState={lastNameErrors.length && 'error'}>
                    <ControlLabel>Last Name</ControlLabel>
                    <FormControl
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      value={strataUnitUser.lastName}
                      onChange={(event) => {
                        onChange(event);
                        validateLastName(event.target.value);
                      }}
                      onBlur={(event) => validateLastName(event.target.value)}
                    />
                    {lastNameErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup validationState={phoneErrors.length && 'error'}>
                    <ControlLabel>Phone</ControlLabel>
                    <FormControl
                      name="phoneNumber"
                      type="text"
                      maxLength="12"
                      placeholder="Phone"
                      value={strataUnitUser.phoneNumber}
                      onChange={(event) => {
                        onChange(event);
                        validatePhone(event.target.value);
                      }}
                      onBlur={onBlurPhone}
                    />
                    {phoneErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup validationState={emailErrors.length && 'error'}>
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                      name="email"
                      type="text"
                      placeholder="Email"
                      value={strataUnitUser.email}
                      onChange={(event) => {
                        onChange(event);
                        validateEmail(event.target.value);
                      }}
                      onBlur={(event) => validateEmail(event.target.value)}
                    />
                    {emailErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Grid>
        </div>
      </section>
    </div>
  );
};
