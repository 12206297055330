import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import Datatable from 'react-bs-datatable';

// Testing out datatables
const header = [
  {
    title: 'RM Number',
    prop: 'property_link',
    sortable: true,
    filterable: true
  },
  { title: 'Address Type', prop: 'street', sortable: true, filterable: true },
  { title: 'City', prop: 'city', sortable: true, filterable: true },
  { title: 'Action', prop: 'button', sortable: false }
];

const onSortFunction = {
  date(columnValue) {
    // Convert the string date format to UTC timestamp
    // So the table could sort it by number instead of by string
    return new Date(columnValue);
  }
};

const DocumentTable = (props) => (
  <di>
    <Grid>
      <Row>
        <Col md={12}>
          <h5>Properties</h5>
        </Col>
      </Row>
    </Grid>
    <Grid>
      <Row>
        <Col md={12}>
          <Datatable
            tableHeader={header}
            tableBody={props.properties}
            keyName="documentTable"
            tableClass="table table-striped table-bordered table-condensed table-hover"
            rowsPerPage={10}
            rowsPerPageOption={[5, 10, 15, 20]}
            initialSort={{ prop: 'date', isAscending: true }}
            onSort={onSortFunction}
          />
        </Col>
      </Row>
    </Grid>
  </di>
);

export default DocumentTable;
