import React, { useEffect, useState } from 'react';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import useValidation from '../../../validation/useValidation';
import {
  emailValidator,
  phoneValidator,
  requiredValidator
} from '../../../validation/validators';

const formatPhone = (phone) => {
  if (phone && /^\d+$/.test(phone) && phone.length === 10) {
    return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(
      6,
      phone.length
    )}`;
  }
  return phone;
};

export default ({ agent, setAgent, setValid, edit }) => {
  const [validateName, nameValid, nameErrors] = useValidation([
    requiredValidator
  ]);
  const [validatePhone, phoneValid, phoneErrors] = useValidation([
    requiredValidator,
    phoneValidator
  ]);
  const [validateEmail, emailValid, emailErrors] = useValidation([
    requiredValidator,
    emailValidator
  ]);

  // if we're editing, do a validate on load
  const [pendingInitialValidation, setPendingInitialValidation] = useState(
    edit
  );
  useEffect(() => {
    if (pendingInitialValidation) {
      validateName(agent.name);
      validateEmail(agent.email);
      validatePhone(agent.phone);
      setPendingInitialValidation(false);
    }
  }, [
    agent,
    edit,
    pendingInitialValidation,
    validatePhone,
    validateEmail,
    validateName
  ]);

  // compute validity
  useEffect(() => {
    setValid(nameValid && phoneValid && emailValid);
  }, [emailValid, nameValid, phoneValid, setValid]);

  const onChange = (event) => {
    const { name, type, value, checked } = event.target;
    setAgent((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const onBlurPhone = (event) => {
    const { name, value } = event.target;
    setAgent((prev) => ({ ...prev, [name]: formatPhone(value) }));
    validatePhone(formatPhone(value));
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Strata Agent</h3>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Col md={4}>
                <FormGroup validationState={nameErrors.length && 'error'}>
                  <ControlLabel>Name</ControlLabel>
                  <FormControl
                    name="name"
                    type="text"
                    placeholder="Name"
                    value={agent.name}
                    onChange={(event) => {
                      onChange(event);
                      validateName(event.target.value);
                    }}
                    onBlur={(event) => validateName(event.target.value)}
                  />
                  {nameErrors.map((error) => (
                    <HelpBlock key={error}>{error}</HelpBlock>
                  ))}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup validationState={phoneErrors.length && 'error'}>
                  <ControlLabel>Phone</ControlLabel>
                  <FormControl
                    name="phone"
                    type="text"
                    placeholder="Phone"
                    value={agent.phone}
                    onChange={(event) => {
                      onChange(event);
                      validatePhone(event.target.value);
                    }}
                    onBlur={onBlurPhone}
                  />
                  {phoneErrors.map((error) => (
                    <HelpBlock key={error}>{error}</HelpBlock>
                  ))}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup validationState={emailErrors.length && 'error'}>
                  <ControlLabel>Email</ControlLabel>
                  <FormControl
                    name="email"
                    type="text"
                    placeholder="Email"
                    value={agent.email}
                    onChange={(event) => {
                      onChange(event);
                      validateEmail(event.target.value);
                    }}
                    onBlur={(event) => validateEmail(event.target.value)}
                  />
                  {emailErrors.map((error) => (
                    <HelpBlock key={error}>{error}</HelpBlock>
                  ))}
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
