import React, { Component } from 'react';
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Row
} from 'react-bootstrap';

const violationOptions = {
  'Noise Violations': [
    'Furniture Dragging',
    'Party',
    'Music',
    'Shouting',
    'Thumbing',
    'Other'
  ],
  'Parking Violations': [
    'Expired Insurance',
    'Oil Spill',
    'Speeding',
    'Visitor Parking',
    'Other'
  ],
  'Pet Violations': [
    'Building Exterior',
    'Common Hallway',
    'Elevator',
    'Lobby',
    'Gym',
    'Other'
  ],
  'Property Damage': [
    'Building Exterior',
    'Common Hallway',
    'Elevator Buttons',
    'Elevator Cab',
    'Elevator Door',
    'Entrance Door - Building',
    'Entrance Door - My Unit',
    'Gym Equipment',
    'Other'
  ],
  'Smoking Violations': [
    'Cooking Smell - On Balcony',
    'Cooking Smell - In Unit',
    'Cooking Smell - In Common Area',
    'Cigarette Smoke - On Balcony',
    'Cigarette Smoke - In Unit',
    'Cigarette Smoke - In Common Area',
    'Marijuana Smoke - On Balcony',
    'Marijuana Smoke - In Unit',
    'Marijuana Smoke - In Common Area',
    'Other'
  ],
  'Storage Violations': [
    'Common Hallway',
    'In Common Driveway',
    'In Front of Unit',
    'In Locker Room Walkway',
    'In Parking Stall',
    'On Top of Lockers',
    'Other'
  ],
  'Waste Disposal Violations': [
    'Improper Cardboard Disposal',
    'Improper Household Garbage Disposal',
    'Improper Organic Disposal',
    'Improper Recycling Disposal',
    'Other'
  ],
  Other: []
};
class StrataFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        violationCategory: 'Noise Violations',
        violationSubcategory: '',
        sourceBuildingAddress: '',
        sourceUnitNumber: '',
        parkingStall: '',
        additionalInfo: '',
        complainantType: '',
        date: '',
        sig1: '',
        sig2: '',

        contactInfo: {
          firstName: '',
          lastName: '',
          buildingName: '',
          buildingAddress: '',
          unitNumber: '',
          strataLotNumber: '',
          strataPlanNumber: '',
          homePhone: '',
          cellPhone: '',
          workPhone: ''
        },
        violations: [
          {
            date: '',
            from: '',
            to: ''
          },
          {
            date: '',
            from: '',
            to: ''
          },
          {
            date: '',
            from: '',
            to: ''
          },
          {
            date: '',
            from: '',
            to: ''
          },
          {
            date: '',
            from: '',
            to: ''
          }
        ]
      }
    };
    this.handleContactInfoInputChange = this.handleContactInfoInputChange.bind(
      this
    );
    this.handleViolationsInputChange = this.handleViolationsInputChange.bind(
      this
    );
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState((prevState) => ({
      form: { ...prevState.form, [name]: value }
    }));
  }

  handleContactInfoInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        contactInfo: { ...prevState.form.contactInfo, [name]: value }
      }
    }));
  }

  handleViolationsInputChange(slotNum, field, event) {
    const val = event.target.value;

    this.setState((prevState) => {
      const form = { ...prevState.form };
      if (field === 'date') {
        form.violations[slotNum].date = val;
      }
      if (field === 'from') {
        form.violations[slotNum].from = val;
      }
      if (field === 'to') {
        form.violations[slotNum].to = val;
      }
      return { form };
    });
  }

  render() {
    return (
      <div>
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h1>Bylaw Complaint</h1>
                </Col>
              </Row>
            </Grid>
            <Form>
              <Grid>
                <Row>
                  <h3>Complainant(s) Information</h3>
                  <p>
                    Provide your building information name and contact details.
                    The information will be verified against building records.
                    Invalid forms or forms received for unregistered tenants
                    will not be processed.
                  </p>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <ControlLabel>Building Name</ControlLabel>
                      <FormControl
                        name="buildingName"
                        type="text"
                        placeholder="Building Name"
                        value={this.state.form.contactInfo.buildingName}
                        onChange={this.handleContactInfoInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <ControlLabel>Building Address </ControlLabel>
                      <FormControl
                        name="buildingAddress"
                        type="text"
                        placeholder="Building Address"
                        value={this.state.form.contactInfo.buildingAddress}
                        onChange={this.handleContactInfoInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <ControlLabel>Unit Number</ControlLabel>
                      <FormControl
                        name="unitNumber"
                        type="text"
                        placeholder="Unit Number"
                        value={this.state.form.contactInfo.unitNumber}
                        onChange={this.handleContactInfoInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <ControlLabel>First Name</ControlLabel>
                      <FormControl
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        value={this.state.form.contactInfo.firstName}
                        onChange={this.handleContactInfoInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <ControlLabel>Last Name</ControlLabel>
                      <FormControl
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        value={this.state.form.contactInfo.lastName}
                        onChange={this.handleContactInfoInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <ControlLabel>Strata Plan Number</ControlLabel>
                      <FormControl
                        name="strataLotNumber"
                        type="text"
                        placeholder="Strata Plan Number"
                        value={this.state.form.contactInfo.strataPlanNumber}
                        onChange={this.handleContactInfoInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <ControlLabel>Home Phone </ControlLabel>
                      <FormControl
                        name="homePhone"
                        type="text"
                        placeholder="Home Phone"
                        value={this.state.form.contactInfo.homePhone}
                        onChange={this.handleContactInfoInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <ControlLabel>Cell Phone</ControlLabel>
                      <FormControl
                        name="cellPhone"
                        type="text"
                        placeholder="Cell Phone"
                        value={this.state.form.contactInfo.cellPhone}
                        onChange={this.handleContactInfoInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <ControlLabel>Work Phone</ControlLabel>
                      <FormControl
                        name="workPhone"
                        type="text"
                        placeholder="Work Phone"
                        value={this.state.form.contactInfo.workPhone}
                        onChange={this.handleContactInfoInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <p>
                      Are you a registered tenant or the owner of the property?{' '}
                    </p>
                  </Col>
                  <Col md={6}>
                    <ControlLabel>Select:</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="complainantType"
                      value={this.state.form.complainantType}
                      onChange={this.handleInputChange}>
                      <option value="1">Owner</option>
                      <option value="2">Tenant</option>
                    </FormControl>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <h3>Nature of Violation</h3>
                  <p>
                    Select the applicable violation type and subcategory. If you
                    need to file multiple types of complaints a new from must be
                    completed for every violation type and subcategory.
                  </p>
                </Row>
                <Row>
                  <Col md={6}>
                    <ControlLabel>Nature of Violation</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="violationCategory"
                      value={this.state.form.violationCategory}
                      onChange={this.handleInputChange}>
                      {Object.keys(violationOptions).map((option) => (
                        <option value={option} key={option}>
                          {option}
                        </option>
                      ))}
                    </FormControl>
                  </Col>
                  {this.state.form.violationCategory !== 'Other' ? (
                    <Col md={6}>
                      <ControlLabel>Subcategory of Violation</ControlLabel>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        name="violationSubcategory"
                        value={this.state.form.violationSubcategory}
                        onChange={this.handleInputChange}>
                        {violationOptions[
                          this.state.form.violationCategory
                        ].map((option) => (
                          <option
                            value={option}
                            key={`${this.state.form.violationCategory}-${option}`}>
                            {option}
                          </option>
                        ))}
                      </FormControl>
                    </Col>
                  ) : (
                    <Col md={6}>
                      <FormGroup>
                        <ControlLabel>Please Specify:</ControlLabel>
                        <FormControl
                          name="specifyCategory"
                          type="text"
                          placeholder="Please Specify"
                          value={this.state.form.violationSubcategory}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <hr />
                <Row>
                  <h3>Violation Date(s) & Time(s)</h3>
                  <p>
                    Provide information on the date and time of the infraction.
                    If the infraction is repetitive (e.g. loud music/smoking),
                    provide the date and time of every infraction.
                  </p>
                </Row>
                <Row>
                  <Col md={4}>
                    <ControlLabel>Date:</ControlLabel>
                    <FormControl
                      name="bungus"
                      type="text"
                      placeholder="Date"
                      value={this.state.form.violations[0].date}
                      onChange={(e) =>
                        this.handleViolationsInputChange(0, 'date', e)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <ControlLabel>From:</ControlLabel>
                    <FormControl
                      type="text"
                      placeholder="From"
                      value={this.state.form.violations[0].from}
                      onChange={(e) =>
                        this.handleViolationsInputChange(0, 'from', e)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <ControlLabel>To:</ControlLabel>
                    <FormControl
                      type="text"
                      placeholder="To"
                      value={this.state.form.violations[0].to}
                      onChange={(e) =>
                        this.handleViolationsInputChange(0, 'to', e)
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col md={4}>
                    <FormControl
                      type="text"
                      placeholder="Date"
                      value={this.state.form.violations[1].date}
                      onChange={(e) =>
                        this.handleViolationsInputChange(1, 'date', e)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <FormControl
                      type="text"
                      placeholder="From"
                      value={this.state.form.violations[1].from}
                      onChange={(e) =>
                        this.handleViolationsInputChange(1, 'from', e)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <FormControl
                      type="text"
                      placeholder="To"
                      value={this.state.form.violations[1].to}
                      onChange={(e) =>
                        this.handleViolationsInputChange(1, 'to', e)
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col md={4}>
                    <FormControl
                      type="text"
                      placeholder="Date"
                      value={this.state.form.violations[2].date}
                      onChange={(e) =>
                        this.handleViolationsInputChange(2, 'date', e)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <FormControl
                      type="text"
                      placeholder="From"
                      value={this.state.form.violations[2].from}
                      onChange={(e) =>
                        this.handleViolationsInputChange(2, 'from', e)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <FormControl
                      type="text"
                      placeholder="To"
                      value={this.state.form.violations[2].to}
                      onChange={(e) =>
                        this.handleViolationsInputChange(2, 'to', e)
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col md={4}>
                    <FormControl
                      type="text"
                      placeholder="Date"
                      value={this.state.form.violations[3].date}
                      onChange={(e) =>
                        this.handleViolationsInputChange(3, 'date', e)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <FormControl
                      type="text"
                      placeholder="From"
                      value={this.state.form.violations[3].from}
                      onChange={(e) =>
                        this.handleViolationsInputChange(3, 'from', e)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <FormControl
                      type="text"
                      placeholder="To"
                      value={this.state.form.violations[3].to}
                      onChange={(e) =>
                        this.handleViolationsInputChange(3, 'to', e)
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col md={4}>
                    <FormControl
                      type="text"
                      placeholder="Date"
                      value={this.state.form.violations[4].date}
                      onChange={(e) =>
                        this.handleViolationsInputChange(4, 'date', e)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <FormControl
                      type="text"
                      placeholder="From"
                      value={this.state.form.violations[4].from}
                      onChange={(e) =>
                        this.handleViolationsInputChange(4, 'from', e)
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <FormControl
                      type="text"
                      placeholder="To"
                      value={this.state.form.violations[4].to}
                      onChange={(e) =>
                        this.handleViolationsInputChange(4, 'to', e)
                      }
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <h3>Source Unit/Parking</h3>
                  <p>
                    Provide information as to what unit or parking stall the
                    infraction originated from. In most cases action cannot be
                    taken unless details on the source unit or parking stall are
                    provided.
                  </p>
                </Row>
                <Row className="mt-5">
                  <Col md={7}>
                    <p>Building Address (if different from above): </p>
                  </Col>
                  <Col md={5}>
                    <FormControl
                      name="sourceBuildingAddress"
                      type="text"
                      placeholder="Building Address"
                      value={this.state.form.sourceBuildingAddress}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <FormControl
                      name="sourceUnitNumber"
                      type="text"
                      placeholder="Unit Number"
                      value={this.state.form.sourceUnitNumber}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col md={2} className="center">
                    <strong>or</strong>
                  </Col>
                  <Col md={5}>
                    <FormControl
                      name="parkingStall"
                      type="text"
                      placeholder="Parking Stall"
                      value={this.state.form.parkingStall}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <h3>Additional Information</h3>
                  <p>
                    Provide additional information that might help identify the
                    source of the infraction or clarify the nature of the
                    violation. The more concise the information the
                    easier/quicker it will be to confirm the infraction and
                    issue a violation letter.
                  </p>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormControl
                      name="additionalInfo"
                      type="text"
                      placeholder="Additional Info"
                      value={this.state.form.additionalInfo}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>*200 Word Limit</p>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <h3>Acknowledgements</h3>
                  <p>
                    I/We hereby certify that the above information is correct
                    and has been witnessed by me/us. I/We understand that in
                    accordance with the Strata Property Act copies of complaints
                    may be requested by the person against whom they are made.
                    If requested, the complaint will be release with the
                    complainants identifying factors redacted. I/We further
                    understand that if legal action, Civil Right Tribunal or
                    Human Rights Tribunal claim is initiated by the person
                    against whom the complaints are made this document may need
                    to be released in its entirety.
                  </p>
                </Row>
                <Row>
                  <Col md={4}>
                    <ControlLabel>Date</ControlLabel>
                    <FormControl
                      name="date"
                      type="text"
                      placeholder="Date"
                      value={this.state.form.date}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col md={4}>
                    <ControlLabel>Signature</ControlLabel>
                    <FormControl
                      name="sig1"
                      type="text"
                      placeholder="Signature"
                      value={this.state.form.sig1}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col md={4}>
                    <ControlLabel>Signature</ControlLabel>
                    <FormControl
                      name="sig2"
                      type="text"
                      placeholder="Signature"
                      value={this.state.form.sig2}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <h3>Procedure</h3>
                  <p>
                    You will be advised once this form has been received. The
                    form will be verified for content and an infraction letter
                    issued in accordance with the Strata Property Act and your
                    respective strata corporation’s bylaws. In most cases an
                    infraction letter will be issued within five business days
                    of the violation form being received.
                  </p>
                  <p>
                    For issues such as noise and waste disposal we recommend
                    speaking to the responsible party first, as they might not
                    be aware of the infraction.
                  </p>
                  <p>
                    Details of the actions taken relating to specific complaints
                    will be reported in the subsequent meeting minutes. The
                    reporting will be done in a format that does not identify
                    the person making the complaint or against who the complaint
                    has been made.
                  </p>
                </Row>
              </Grid>
            </Form>
          </div>
        </section>
      </div>
    );
  }
}
export default StrataFormComponent;
