import React from 'react';
import { Grid, Row, Col, Alert } from 'react-bootstrap';

const ErrorAlert = (props) => (
  <div>
    <Grid>
      <Row>
        <Col md={12}>
          <Alert bsStyle="danger">{props.errorMessage}</Alert>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default ErrorAlert;
