import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Button,
  Glyphicon,
  Accordion,
  Panel
} from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      property: [],
      categories: [],
      staff: [],
      agent: {},
      error: false,
      errorMessage: ''
    };

    this.icon = (header) => (
      <div>
        {header}{' '}
        <Glyphicon
          glyph="glyphicon glyphicon-plus"
          style={{ float: 'right' }}
        />
      </div>
    );

    this.compare = (a, b) => {
      const alpha = a.title;
      const beta = b.title;
      let comparison = 0;
      if (alpha < beta) {
        comparison = 1;
      } else if (alpha > beta) {
        comparison = -1;
      }
      return comparison;
    };
  }

  componentDidMount() {
    this.loadProperty();
    this.loadCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.property !== this.state.property) {
      this.loadAgent();
      this.loadStaff();
      this.loadDocuments(this.state.property.id);
    }
  }

  getCategory(id) {
    const c = this.state.categories;
    if (c === undefined || c.length === 0) {
      return id;
    }
    for (let i = 0; i < c.length; i += 1) {
      if (c[i].id === id) return c[i].name;
    }
    return id;
  }

  loadStaff() {
    request
      .get(`/stratapropertystaff/property/${this.state.property.id}`)
      .then((staff) =>
        this.setState({
          staff
        })
      )
      .catch(() => {
        toast('Could Not Load Staff.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  loadAgent() {
    request
      .get(`/strataAgent/${this.state.property.agentId}`)
      .then((res) => this.setState({ agent: res }))
      .catch(() => {
        toast('Could Not Load Agent.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  loadCategories() {
    return request
      .get('/StrataDocumentCategories/all')
      .then((categories) =>
        this.setState({
          categories
        })
      )
      .catch(() => {
        toast('Could Not Load Categories.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  loadProperty() {
    const { match } = this.props;
    return request
      .get(`/StrataProperty/${match.params.id}`)
      .then((property) => this.setState({ property }))
      .catch(() => {
        toast('Could Not Load Property.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  loadDocuments(id) {
    request
      .get(`/stratadocuments/property/${id}`)
      .then((documents) => this.setState({ documents }))
      .catch(() => {
        toast('Could Not Load Documents.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  generatePanels() {
    const { categories } = this.state;
    const { documents } = this.state;

    return categories.map((c) => {
      const catDocs = [];
      documents.forEach((d) => {
        if (c.id === d.strataDocumentCategoryId) {
          if (!d.title) {
            catDocs.push({ ...d, title: 'No File Name' });
          }
          catDocs.push(d);
        }
      });
      catDocs.sort(this.compare);
      if (catDocs.length > 0) {
        return (
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <Accordion>
                    <Panel header={this.icon(c.name)} eventKey="1">
                      {catDocs.map((d) => (
                        <Grid>
                          <Row
                            id={d.id}
                            style={{
                              clear: ' both',
                              overflow: 'hidden',
                              paddingRight: '40px'
                            }}>
                            <Col md={6}>
                              <p style={{ fontSize: '16px' }}>
                                <a
                                  href={`${process.env.REACT_APP_SITE_API}/api/stratadocuments/${d.id}/file`}>
                                  {d.title}
                                </a>
                              </p>
                            </Col>
                            <Col md={6}>
                              <Button
                                bsSize="small"
                                bsStyle="info"
                                style={{
                                  marginRight: 5,
                                  marginLeft: 5,
                                  float: 'right'
                                }}
                                onClick={() => {
                                  this.showDocument(d.id);
                                }}>
                                <Glyphicon glyph="eye-open" />
                              </Button>
                              <Button
                                bsSize="small"
                                bsStyle="success"
                                style={{
                                  marginRight: 5,
                                  marginLeft: 5,
                                  float: 'right'
                                }}
                                href={`${process.env.REACT_APP_SITE_API}/api/stratadocuments/${d.id}/file`}>
                                <Glyphicon glyph="download-alt" />
                              </Button>
                            </Col>
                          </Row>
                        </Grid>
                      ))}
                    </Panel>
                  </Accordion>
                </Col>
              </Row>
            </Grid>
          </div>
        );
      }
      return null;
    });
  }

  loadStaffInfo() {
    const s = this.state.staff;
    if (s === undefined || s.length === 0) {
      return (
        <p style={{ marginBottom: 10 }}>
          <span style={{ fontWeight: 'bold' }}>No Building Staff </span>
        </p>
      );
    }
    return s.map((staff) => {
      const daysArray = staff.hours.split(',');
      return (
        <Col md={6}>
          <Panel style={{ marginBottom: 5 }} id={staff.id}>
            <h4>{staff.position}</h4>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Name : </span>
              {staff.name}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Phone : </span>
              <a href={`tel:${staff.phone}`}>{staff.phone}</a>
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Email : </span>
              <a href={`mailto:${staff.email}`}>{staff.email}</a>
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Monday: </span>
              {daysArray[0]}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Tuesday: </span>
              {daysArray[1]}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Wednesday: </span>
              {daysArray[2]}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Thursday: </span>
              {daysArray[3]}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Friday: </span>
              {daysArray[4]}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Saturday: </span>
              {daysArray[5]}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Sunday: </span>
              {daysArray[6]}
            </p>
          </Panel>
        </Col>
      );
    });
  }

  showDocument(id) {
    this.props.history.push(`/strataUnitOwner/document/view/${id}`);
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3 style={{ marginBottom: 15 }}>
                    Strata Plan {this.state.property.strataPlanNumber} -{' '}
                    {this.state.property.buildingName} ||{' '}
                    {this.state.property.address}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Panel header="Building Details">
                    <p style={{ marginBottom: 10 }}>
                      <span style={{ fontWeight: 'bold' }}>
                        Building Name :{' '}
                      </span>
                      {this.state.property.buildingName}
                    </p>
                    <p style={{ marginBottom: 10 }}>
                      <span style={{ fontWeight: 'bold' }}>Strata Plan : </span>
                      {this.state.property.strataPlanNumber}
                    </p>
                    <p style={{ marginBottom: 10 }}>
                      <span style={{ fontWeight: 'bold' }}>
                        Building Address :{' '}
                      </span>
                      {this.state.property.address}
                    </p>
                  </Panel>
                </Col>
                <Col md={6}>
                  <Panel header="Strata Agent">
                    <p style={{ marginBottom: 10 }}>
                      <span style={{ fontWeight: 'bold' }}>Agent Name : </span>
                      {this.state.agent.name}
                    </p>
                    <p style={{ marginBottom: 10 }}>
                      <span style={{ fontWeight: 'bold' }}>Agent Phone : </span>
                      <a href={`tel:${this.state.agent.phone}`}>
                        {this.state.agent.phone}
                      </a>
                    </p>
                    <p style={{ marginBottom: 10 }}>
                      <span style={{ fontWeight: 'bold' }}>Agent Email : </span>
                      <a href={`mailto:${this.state.agent.email}`}>
                        {this.state.agent.email}
                      </a>
                    </p>
                  </Panel>
                </Col>
              </Row>
              <Accordion>
                <Panel header={this.icon('Onsite Staff')} eventKey="3">
                  {this.loadStaffInfo()}
                </Panel>
              </Accordion>
              <h3 style={{ marginBottom: 15 }}>Documents</h3>
              <Row>
                {this.state.error ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}
              </Row>
            </Grid>
            {this.generatePanels()}
          </div>
        </section>
      </div>
    );
  }
}

export default Detail;
