import React, { Component } from 'react';
import {
  Modal,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  Button
} from 'react-bootstrap';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

class EditStrataUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: '',
      loading: false
    };
    this.update = this.update.bind(this);
  }

  update(e) {
    e.preventDefault();
    this.setState({ loading: true });
    request
      .put(`/strataUnit/${this.props.strataUnit.id}`, this.props.strataUnit)
      .then(() => {
        this.setState({ loading: false });
        this.props.loadStrataUnits();
        this.props.closeModal();
      })

      .catch(() => {
        this.setState({
          error: true,
          errorMessage: 'Error updating strata unit',
          loading: false
        });
      });
  }

  render() {
    return (
      <Modal
        dialogClassName="custom-modal"
        size="lg"
        show={this.props.show}
        onHide={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Strata Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Strata Lot # </ControlLabel>
                <FormControl
                  name="lotNumber"
                  type="text"
                  placeholder="Strata Lot Number"
                  value={this.props.strataUnit.lotNumber}
                  onChange={this.props.inputChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Unit Number </ControlLabel>
                <FormControl
                  name="unitNumber"
                  type="text"
                  placeholder="Unit Number"
                  value={this.props.strataUnit.unitNumber}
                  onChange={this.props.inputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {this.state.error ? (
              <ErrorAlert errorMessage={this.state.errorMessage} />
            ) : null}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {this.state.loading === false ? (
            <Button
              bsStyle="primary"
              bsSize="large"
              block
              type="submit"
              onClick={this.update}>
              Update
            </Button>
          ) : (
            <div className="loader" />
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditStrataUnit;
