import React, { useState } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import request from '../../../../utils/Request';
import PADForm from '../../forms/PAD';
import ErrorAlert from '../../../utils/ErrorAlert';

export default ({ history, location, match }) => {
  const [error, setError] = useState(null);

  const [PAD, setPAD] = useState({
    postalCode: null,
    address: null,
    financialInstitutionName: null,
    financialInstitutionAddress: null,
    financialInstitutionCity: null,
    financialInstitutionProvince: null,
    bankTransitNumber: null,
    institutionNumber: null,
    accountHolderFirstAndLastName: null,
    accountNumber: null,
    strataOwnerId: null
  });

  const create = async (event) => {
    event.preventDefault();
    const data = {
      ...PAD,
      strataOwnerId: match.params.ownerId
    };
    try {
      const response = await request.post(`/preAuthorizedDebit`, data);
      if (response) {
        history.push(`/strataUnitOwner/strataUnit/${location.state.unitId}`);
      } else {
        setError(
          `Error Creating Pre Authorized Debit${response}${response.id}`
        );
      }
    } catch (e) {
      setError('Error Creating Pre Authorized Debit.');
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <PADForm PAD={PAD} setPAD={setPAD} />
          <Grid>
            <Row>
              <Col md={12}>
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  blocktype="submit"
                  onClick={create}>
                  Create PAD
                </Button>
              </Col>
            </Row>
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
