import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import StrataPropertyTable from '../utils/dataTable/StrataPropertyTable';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import request from '../../utils/Request';
import StrataUnitPropertyTable from '../utils/dataTable/StrataUnitTable';
import ErrorAlert from '../utils/ErrorAlert';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: '',
      properties: [],
      agents: [],
      strataUnits: []
    };

    this.getStrataOwner = (strataOwners) => {
      let strataOwner = 'No Registered Owners';
      if (strataOwners.length > 0) {
        strataOwners.forEach((owner) => {
          if (owner.isCurrent === true) {
            strataOwner = owner.nameOnTitle;
          }
        });
      }
      return strataOwner;
    };
  }

  componentDidMount() {
    this.loadAgents();
    this.loadProperties();
  }

  deleteProperty(id) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to archive this?')) {
      request
        .archive(`/strataproperty/archive/${id}`)
        .then(() => this.loadProperties())
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Something went Wrong. Please try again Later.'
          });
        });
    }
  }

  loadAgents() {
    return request
      .get('/strataAgent')
      .then((res) => this.setState({ agents: res }))
      .catch(() => {
        toast('Could not load Strata Agents.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  getStrataAgentName(id) {
    const a = this.state.agents;

    if (a === undefined || a.length === 0) {
      return id;
    }
    if (id === undefined || id == null) {
      return 'No Agent';
    }

    for (let i = 0; i < a.length; i += 1) {
      if (a[i].id === id) {
        return a[i].name;
      }
    }
    return 'No Agent';
  }

  getStrataAgentPhone(id) {
    const a = this.state.agents;

    if (a === undefined || a.length === 0) {
      return id;
    }
    if (id === undefined || id == null) {
      return 'No Agent';
    }

    for (let i = 0; i < a.length; i += 1) {
      if (a[i].id === id) {
        return a[i].phone;
      }
    }
    return 'No Agent';
  }

  loadProperties() {
    request
      .get(
        `/strataUnitUser/strataProperties/${localStorage.getItem('user_id')}`
      )
      .then((properties) =>
        this.setState({
          properties: properties.map((p) => ({
            ...p,
            property_link: (
              <Link to={`/strataUnitOwner/strataProperty/${p.id}`}>
                {p.buildingName}
              </Link>
            ),
            plan: p.strataPlanNumber,
            agent: this.getStrataAgentName(p.agentId),
            agent_phone: this.getStrataAgentPhone(p.agentId),
            manager: p.buildingManagerName,
            manager_phone: p.buildingManagerPhone
          }))
        })
      )
      .then(() => this.loadStrataUnits())
      .catch(() => {
        toast('Could not load Properties.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  loadStrataUnits() {
    return request
      .get(`/strataUnitUser/strataUnits/${localStorage.getItem('user_id')}`)
      .then((units) => {
        this.setState({
          strataUnits: units.map((u) => ({
            ...u,
            property_link: (
              <Link to={`/strataUnitOwner/strataUnit/${u.id}`}>
                {u.unitNumber}
              </Link>
            ),
            strataLotNumber: u.lotNumber,
            strataPlan: u.strataProperty.strataPlanNumber,
            nameOnTitle: this.getStrataOwner(u.strataOwners)
          }))
        });
      })
      .catch(() => {
        toast('Could not load Strata Units.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3>Strata Unit Owner Dashboard</h3>
                </Col>
              </Row>
              <Row>
                {this.state.error ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}
              </Row>
            </Grid>
            <StrataPropertyTable properties={this.state.properties} />
            <StrataUnitPropertyTable strataUnits={this.state.strataUnits} />
          </div>
        </section>
      </div>
    );
  }
}

export default Dashboard;
