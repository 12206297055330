import React from 'react';
import '../css/App.css';
import { BrowserRouter } from 'react-router-dom';
import Main from './Main';

const App = () => (
  <BrowserRouter>
    <Main />
  </BrowserRouter>
);

export default App;
