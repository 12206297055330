import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import ScrollToTop from '../../utils/ScrollToTop';
import logo from '../../../images/logoV2.png';
import StrataDocuments from './strataDocuments';

export default ({ children }) => {
  const [currentForm, setCurrentForm] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function HeaderStyle() {
    if (scrollPosition > 70) {
      return {
        marginBottom: '0px',
        top: '0px',
        background: 'rgba(255, 255, 255, 0.8)'
      };
    }
    return {
      marginBottom: '0px',
      top: '0px',
      background: 'rgba(255, 255, 255, 1)'
    };
  }
  const rentalManagement = 'rentalManagement';
  const strataCorporations = 'strataCorporations';
  const developerServices = 'developerServices';

  const rentalManagementUrl =
    'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/11';
  const strataCorporationsUrl =
    'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/10';
  const strataManagementLoginNew =
    'https://korecki.securecafe.com/residentservices/korecki-condocafe/userlogin.aspx';
  const developerServicesUrl =
    'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/17';
  return (
    <div>
      <Navbar collapseOnSelect style={HeaderStyle()}>
        <Navbar.Header>
          <Navbar.Brand>
            <img
              src={logo}
              className="logo"
              alt="Korecki Real Estate Services"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            <MenuItem href={process.env.REACT_APP_PUBLIC_SITE}>Home</MenuItem>
            <NavDropdown eventKey={3} title="Strata" id="strata-nav-dropdown">
              <MenuItem
                eventKey={3.1}
                href={`${process.env.REACT_APP_PUBLIC_SITE}/strata-management-services`}>
                Strata Management Services
              </MenuItem>
              <MenuItem
                eventKey={3.2}
                onClick={() => setCurrentForm(strataCorporations)}>
                Strata Corporations Request
                <StrataDocuments
                  open={currentForm === strataCorporations}
                  openModal={setCurrentForm}
                  documentUrl={strataCorporationsUrl}
                />
              </MenuItem>
              <NavDropdown
                eventKey={2.3}
                title="Areas We Serve"
                id="basic-nav-dropdown">
                <MenuItem
                  eventKey={2.8}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/strata-management-services/areas-we-serve-strata-management-services/burnaby-strata-management-services`}>
                  Burnaby
                </MenuItem>
                <MenuItem
                  eventKey={2.9}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/strata-management-services/areas-we-serve-strata-management-services/coquitlam-strata-management-services`}>
                  Coquitlam
                </MenuItem>
                <MenuItem
                  eventKey={2.1}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/strata-management-services/areas-we-serve-strata-management-services/delta-strata-management-services`}>
                  Delta
                </MenuItem>
                <MenuItem
                  eventKey={2.11}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/strata-management-services/areas-we-serve-strata-management-services/new-westminster-strata-management-services`}>
                  New Westminster
                </MenuItem>
                <MenuItem
                  eventKey={2.12}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/strata-management-services/areas-we-serve-strata-management-services/north-vancouver-strata-management-services`}>
                  North Vancouver
                </MenuItem>
                <MenuItem
                  eventKey={2.7}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/strata-management-services/areas-we-serve-strata-management-services/port-moody-strata-management-services`}>
                  Port Moody
                </MenuItem>
                <MenuItem
                  eventKey={2.5}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/strata-management-services/areas-we-serve-strata-management-services/richmond-strata-management-services`}>
                  Richmond
                </MenuItem>
                <MenuItem
                  eventKey={2.6}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/strata-management-services/areas-we-serve-strata-management-services/surrey-strata-management-services`}>
                  Surrey
                </MenuItem>
                <MenuItem
                  eventKey={2.4}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/strata-management-services/areas-we-serve-strata-management-services/vancouver-strata-management-services`}>
                  Vancouver
                </MenuItem>
                <MenuItem
                  eventKey={2.13}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/strata-management-services/areas-we-serve-strata-management-services/west-vancouver-strata-management-services`}>
                  West Vancouver
                </MenuItem>
              </NavDropdown>
            </NavDropdown>
            <NavDropdown
              eventKey={2}
              title="Rental"
              id="residential-nav-dropdown">
              <MenuItem
                eventKey={2.1}
                href={`${process.env.REACT_APP_PUBLIC_SITE}/rental-management-services`}>
                Rental Management Services
              </MenuItem>
              <MenuItem
                eventKey={2.2}
                onClick={() => setCurrentForm(rentalManagement)}>
                Rental Management Proposal Request
                <StrataDocuments
                  open={currentForm === rentalManagement}
                  openModal={setCurrentForm}
                  documentUrl={rentalManagementUrl}
                />
              </MenuItem>
              <NavDropdown
                eventKey={2.3}
                title="Areas We Serve"
                id="basic-nav-dropdown">
                <MenuItem
                  eventKey={2.8}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/rental-management-services/areas-we-serve-rental-management-services/burnaby-rental-management-services`}>
                  Burnaby
                </MenuItem>
                <MenuItem
                  eventKey={2.9}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/rental-management-services/areas-we-serve-rental-management-services/coquitlam-rental-management-services`}>
                  Coquitlam
                </MenuItem>
                <MenuItem
                  eventKey={2.1}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/rental-management-services/areas-we-serve-rental-management-services/delta-rental-management-services`}>
                  Delta
                </MenuItem>
                <MenuItem
                  eventKey={2.11}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/rental-management-services/areas-we-serve-rental-management-services/new-westminster-rental-management-services`}>
                  New Westminster
                </MenuItem>
                <MenuItem
                  eventKey={2.12}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/rental-management-services/areas-we-serve-rental-management-services/north-vancouver-rental-management-services`}>
                  North Vancouver
                </MenuItem>
                <MenuItem
                  eventKey={2.7}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/rental-management-services/areas-we-serve-rental-management-services/port-moody-rental-management-services`}>
                  Port Moody
                </MenuItem>
                <MenuItem
                  eventKey={2.5}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/rental-management-services/areas-we-serve-rental-management-services/richmond-rental-management-services`}>
                  Richmond
                </MenuItem>
                <MenuItem
                  eventKey={2.6}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/rental-management-services/areas-we-serve-rental-management-services/surrey-rental-management-services`}>
                  Surrey
                </MenuItem>
                <MenuItem
                  eventKey={2.4}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/rental-management-services/areas-we-serve-rental-management-services/vancouver-rental-management-services`}>
                  Vancouver
                </MenuItem>
                <MenuItem
                  eventKey={2.13}
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/rental-management-services/areas-we-serve-rental-management-services/west-vancouver-rental-management-services`}>
                  West Vancouver
                </MenuItem>
              </NavDropdown>
            </NavDropdown>
            <NavDropdown
              eventKey={4}
              title="Developers"
              id="basic-nav-dropdown">
              <MenuItem
                eventKey={4.1}
                href={`${process.env.REACT_APP_PUBLIC_SITE}/developers`}>
                {' '}
                Development Services{' '}
              </MenuItem>
              <MenuItem
                eventKey={4.2}
                onClick={() => setCurrentForm(developerServices)}>
                <StrataDocuments
                  open={currentForm === developerServices}
                  openModal={setCurrentForm}
                  documentUrl={developerServicesUrl}
                />
                Developer Services Proposal Request
              </MenuItem>
            </NavDropdown>
            <NavDropdown
              eventKey={9}
              title="Documents"
              id="documents-nav-dropdown">
              <MenuItem
                eventKey={9.1}
                href={`${process.env.REACT_APP_PUBLIC_SITE}/documents/strata-management-documents`}>
                Strata Management Documents
              </MenuItem>
              <MenuItem
                eventKey={9.3}
                href={`${process.env.REACT_APP_PUBLIC_SITE}/documents/rental-management-documents`}>
                Rental Management Documents
              </MenuItem>
              <MenuItem
                eventKey={9.2}
                href={`${process.env.REACT_APP_PUBLIC_SITE}/documents/proposal-requests`}>
                Proposal Requests
              </MenuItem>
            </NavDropdown>
            <MenuItem href={`${process.env.REACT_APP_PUBLIC_SITE}/listings`}>
              Listings
            </MenuItem>
            <NavDropdown eventKey={5} title="About" id="about-nav-dropdown">
              <MenuItem
                eventKey={5.1}
                href={`${process.env.REACT_APP_PUBLIC_SITE}/company`}>
                Corporate Profile
              </MenuItem>
              <MenuItem
                eventKey={5.2}
                href={`${process.env.REACT_APP_PUBLIC_SITE}/careers`}>
                Careers
              </MenuItem>
            </NavDropdown>
            <MenuItem href={`${process.env.REACT_APP_PUBLIC_SITE}/contact`}>
              Contact
            </MenuItem>
            <NavDropdown eventKey={6} title="Login" id="login-nav-dropdown">
              <MenuItem
                eventKey={6.1}
                href={`${process.env.REACT_APP_SITE_API}/strataLogin`}>
                Strata Management Login
              </MenuItem>
              <MenuItem
                eventKey={6.2}
                href={`${process.env.REACT_APP_SITE_API}/login`}>
                Rental Management Login
              </MenuItem>
              <MenuItem eventKey={8.3} href={strataManagementLoginNew}>
                Strata Management Login (New)
              </MenuItem>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ScrollToTop>
        <main>{children}</main>
      </ScrollToTop>
    </div>
  );
};
