import React, { Component } from 'react';
import { Grid, Row, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DocumentTable from '../../../utils/dataTable/DocumentTable';
import request from '../../../../utils/Request';

class Detail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      owner: {},
      user: {},
      properties: [],
      documents: []
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
  }

  componentDidMount() {
    this.loadOwner();
    this.loadProperties();
    this.loadDirectories();
  }

  handleFilterInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      filterOptions: {
        ...prevState.filterOptions,
        [name]: value
      }
    }));
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  loadOwner() {
    const {
      match: { params }
    } = this.props;
    request.get(`/ownerprofile/${params.id}`).then((owner) => {
      this.setState({
        owner
      });
      this.setState({
        user: owner.user
      });
    });
  }

  loadProperties() {
    const {
      match: { params }
    } = this.props;
    request.get(`/property/archived/owner/${params.id}`).then((properties) => {
      this.setState({
        properties
      });
    });
  }

  loadDirectories() {
    const {
      match: { params }
    } = this.props;
    request
      .get(`/documents/owner/${params.id}/all`)
      .then((documents) => {
        this.setState({
          documents: documents.map((d) => ({
            ...d,
            type: d.documentType.name,
            date: moment(d.documentDate).format('MMMM YYYY'),
            link: (
              <a
                href={`${process.env.REACT_APP_SITE_API}/api/documents/${d.id}/file`}
                download={d.Title}>
                Download File
              </a>
            )
          }))
        });
      })
      .catch(() => {});
  }

  linkDocumentFormatter(cell) {
    let name = '';
    this.state.documents.forEach((d) => {
      if (
        d.id === cell &&
        d.documentTypeId === this.state.filterOptions.documentTypeId &&
        d.month === this.state.filterOptions.month &&
        d.year === this.state.filterOptions.year
      ) {
        name = d.fileName;
      }
    });
    return <Link to={`/admin/owner/${cell}`}>{name}</Link>;
  }

  render() {
    return (
      <div>
        <section className="m-none">
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Archived Owner Details</h3>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Col md={2}>Name</Col>
              <Col md={10}>{this.state.owner.name}</Col>
            </Row>
            <Row>
              <Col md={2}>Address</Col>
              <Col md={10}>
                {this.state.owner.address}, {this.state.owner.city}{' '}
                {this.state.owner.postal}
              </Col>
            </Row>
            <Row>
              <Col md={2}>Phone</Col>
              <Col md={10}>{this.state.owner.phone}</Col>
            </Row>
            <Row>
              <Col md={2}>Email</Col>
              <Col md={10}>{this.state.user.email}</Col>
            </Row>
          </Grid>
        </section>

        <section className="m-top">
          <Grid>
            <Row>
              <Col md={6}>
                <h5>Archived Property List</h5>
              </Col>
              <Col md={6} />
              <Col md={12}>
                <Table striped bordered condensed hover>
                  <thead>
                    <tr>
                      <td>RM Number</td>
                      <td>Address</td>
                      <td>City</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.properties.length > 0 ? (
                      this.state.properties.map((property) => (
                        <tr>
                          <td>
                            <Link
                              to={`/admin/archived/property/${property.id}`}>
                              {property.rmNumber}
                            </Link>
                          </td>
                          <td>{property.street}</td>
                          <td>{property.city}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="center-text" colSpan={3}>
                          No Properties Yet
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Grid>
        </section>

        <section className="section m-top">
          <DocumentTable documents={this.state.documents} />
        </section>
      </div>
    );
  }
}

export default Detail;
