import React, { useState } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import request from '../../../utils/Request';
import StrataUnitEditUserForm from '../forms/StrataUnitEditUser';
import ErrorAlert from '../../utils/ErrorAlert';

export default ({ history }) => {
  const [error, setError] = useState(null);

  const [strataUnitUser, setStrataUnitUser] = useState({
    id: history.location.strataUnitUser.user.id,
    userName: history.location.strataUnitUser.user.userName,
    phoneNumber: history.location.strataUnitUser.user.phoneNumber,
    strataUnitRole: history.location.strataUnitUser.strataUnitRole,
    firstName: history.location.strataUnitUser.firstName,
    lastName: history.location.strataUnitUser.lastName,
    email: history.location.strataUnitUser.user.email
  });

  const [valid, setValid] = useState(false);

  const update = async (event) => {
    event.preventDefault();

    const ASPUser = {
      id: strataUnitUser.id,
      phoneNumber: strataUnitUser.phoneNumber,
      email: strataUnitUser.email,
      userName: strataUnitUser.userName
    };

    const user = {
      userId: strataUnitUser.id,
      strataUnitId: history.location.strataUnitUser.strataUnitId,
      strataUnitRole: strataUnitUser.strataUnitRole,
      firstName: strataUnitUser.firstName,
      lastName: strataUnitUser.lastName
    };

    try {
      await request.put(`/User/${strataUnitUser.id}`, ASPUser);
      const unitUser = await request.put(
        `/strataUnitUser/strataUnit/${user.strataUnitId}/strataUser/${user.userId}`,
        user
      );
      history.push(`/strataAdmin/strataUnit/${unitUser.strataUnitId}`);
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <StrataUnitEditUserForm
            edit
            strataUnitUser={strataUnitUser}
            setStrataUnitUser={setStrataUnitUser}
            setValid={setValid}
          />
          <Grid>
            <Row>
              <Col md={12}>
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  block
                  type="submit"
                  disabled={!valid}
                  onClick={update}>
                  Update Strata User
                </Button>
              </Col>
            </Row>
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
