import React from 'react';
import { useDropzone } from 'react-dropzone';

function Dropzone({ onDrop, accept, open, maxfiles }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop,
    maxfiles
  });

  return (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div {...getRootProps({ className: 'dropzone' })}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center">
          {isDragActive ? (
            <p className="dropzone-content">Release to drop the files here</p>
          ) : (
            <p className="dropzone-content">
              Drag’ n’ drop some files here, or click to select files
            </p>
          )}
          <button type="button" onClick={open} className="btn">
            Click to select files
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dropzone;
