import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      owner: {},
      user: {},
      oldEmail: null,
      infoError: false,
      infoErrorMessage: '',
      emailError: false,
      emailErrorMessage: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.update = this.update.bind(this);
    this.updateEmail = this.updateEmail.bind(this);

    this.notify = () =>
      toast('owner Saved', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: css({
          background: 'black'
        })
      });
  }

  loadOwner() {
    const {
      match: { params }
    } = this.props;
    request.get(`/ownerprofile/${params.id}`).then((owner) => {
      this.setState(
        {
          owner
        },
        () => {
          this.setState({
            user: owner.user
          });
        }
      );
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      owner: { ...prevState.owner, [name]: value }
    }));
  }

  handleEmailChange(event) {
    if (!this.state.oldEmail) {
      this.setState((prevState) => ({
        ...prevState,
        oldEmail: prevState.user.email
      }));
    }
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
        userName: value,
        normalizedEmail: value.normalize(),
        normalizedUserName: value.normalize()
      }
    }));
  }

  componentDidMount() {
    this.loadOwner();
  }

  update(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const {
      match: { params }
    } = this.props;
    const { user } = this.state;
    this.state.owner.user = user;
    const data = this.state.owner;
    request
      .put(`/ownerprofile/${params.id}`, data)
      .then(() => {
        this.setState({ loading: false, error: false });
        this.props.history.push(`/admin/owner/${params.id}`);
        this.notify();
      })
      .catch(() => {
        this.setState({
          loading: false,
          infoError: true,
          infoErrorMessage: 'Unable to update info. Something went wrong'
        });
      });
  }

  updateEmail(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const {
      match: { params }
    } = this.props;
    const { user } = this.state;
    request
      .put(
        `/User/updateEmail?email=${this.state.oldEmail}&emailToUpdate=${user.email}`
      )
      .then(() => {
        this.setState({ loading: false, error: false });
        this.props.history.push(`/admin/owner/${params.id}`);
        this.notify();
      })
      .catch(() => {
        this.setState({
          loading: false,
          emailError: true,
          emailErrorMessage:
            'Unable to update user. The email may be already in use'
        });
      });
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3>Edit Owner</h3>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Col md={12}>
                  <Form>
                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Name</ControlLabel>
                        <FormControl
                          name="name"
                          type="text"
                          placeholder="Strata Plan"
                          value={this.state.owner.name}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Phone</ControlLabel>
                        <FormControl
                          name="phone"
                          type="text"
                          placeholder="Phone #"
                          value={this.state.owner.phone}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <ControlLabel>Address</ControlLabel>
                        <FormControl
                          name="address"
                          type="text"
                          value={this.state.owner.address}
                          onChange={this.handleInputChange}
                          placeholder="Address"
                        />
                        <ControlLabel>Country</ControlLabel>
                        <FormControl
                          componentClass="select"
                          placeholder="select"
                          name="countryId"
                          value={this.state.owner.countryId}
                          onChange={this.handleInputChange}>
                          <option value="1">Canada</option>
                          <option value="2">U.S</option>
                          <option value="3">Other</option>
                        </FormControl>
                        <FormGroup style={{ paddingTop: 20 }}>
                          <ControlLabel>Province / State</ControlLabel>
                          <FormControl
                            name="province"
                            type="text"
                            placeholder="Province / State"
                            value={this.state.owner.province}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>City</ControlLabel>
                          <FormControl
                            name="city"
                            type="text"
                            placeholder="City"
                            value={this.state.owner.city}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>Postal</ControlLabel>
                          <FormControl
                            name="postal"
                            type="text"
                            placeholder="Postal"
                            value={this.state.owner.postal}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      {this.state.loading === false ? (
                        <Button type="submit" onClick={this.update}>
                          Update Info
                        </Button>
                      ) : (
                        <div className="loader" style={{ margin: -10 }} />
                      )}
                    </Col>
                  </Form>
                  <Row>
                    {this.state.infoError ? (
                      <ErrorAlert errorMessage={this.state.infoErrorMessage} />
                    ) : null}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form>
                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Email</ControlLabel>
                        <FormControl
                          name="email"
                          type="text"
                          placeholder="Email"
                          value={this.state.user.email}
                          onChange={this.handleEmailChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      {this.state.loading === false ? (
                        <Button type="submit" onClick={this.updateEmail}>
                          Update Email
                        </Button>
                      ) : (
                        <div className="loader" style={{ margin: -10 }} />
                      )}
                    </Col>
                  </Form>
                  <Row>
                    {this.state.emailError ? (
                      <ErrorAlert errorMessage={this.state.emailErrorMessage} />
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}
export default Edit;
