import { useCallback, useState } from 'react';

export default (validators) => {
  const [functions] = useState(validators);
  const [errors, setErrors] = useState([]);
  const [valid, setValid] = useState(false);

  const validate = useCallback(
    (value) => {
      const errs = functions.reduce(
        (results, func) => [...results, ...func(value)],
        []
      );

      setErrors(errs);
      setValid(!errs.length);
    },
    [functions]
  );

  return [validate, valid, errors];
};
