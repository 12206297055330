import React, { Component } from 'react';
import {
  Col,
  Row,
  Grid,
  Form,
  FormControl,
  FormGroup,
  ControlLabel
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import request from '../../utils/Request';
import Auth from '../../utils/Auth';
import ErrorAlert from '../utils/ErrorAlert';
import Layout from '../layout/public/Layout';

class StrataLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: false,
      errorMessage: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.login = this.login.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  componentDidMount() {
    if (Auth.isAuthenticated()) {
      switch (localStorage.getItem('role')) {
        case 'admin':
          this.props.history.push('/admin/dashboard');
          break;
        case 'owner':
          this.props.history.push('/owner/dashboard');
          break;
        case 'strataAdmin':
          this.props.history.push('/strataAdmin/dashboard');
          break;
        case 'propertyAdmin':
          this.props.history.push('/propertyAdmin/dashboard');
          break;
        case 'strataUnitOwner':
          this.props.history.push('/strataUnitOwner/dashboard');
          break;
        case 'superAdmin':
          this.props.history.push('/strataAdmin/dashboard');
          break;
        default:
      }
    }
  }

  login(e) {
    e.preventDefault();
    request
      .login({
        userName: this.state.email,
        password: this.state.password,
        portal: 'strata'
      })
      .then((res) => {
        localStorage.setItem('portalOption', 'strata');

        if (res.user.role.indexOf('Strata Administrator') > -1) {
          localStorage.setItem('role', 'strataAdmin');
          this.props.history.push('/strataAdmin/dashboard');
        } else if (res.user.role.indexOf('Strata Owner') > -1) {
          localStorage.setItem('role', 'propertyAdmin');
          this.props.history.push('/propertyAdmin/dashboard');
        } else if (res.user.role.indexOf('Strata Unit Owner') > -1) {
          localStorage.setItem('role', 'strataUnitOwner');
          this.props.history.push('/strataUnitOwner/dashboard');
        } else if (res.user.role.indexOf('Super Administrator') > -1) {
          localStorage.setItem('role', 'superAdmin');
          this.props.history.push('/strataAdmin/dashboard');
        } else {
          this.setState({
            error: true,
            errorMessage:
              'Sign in credentials not valid for strata login. Perhaps you meant to use residential login'
          });
        }
      })
      .catch(() => {
        this.setState({
          error: true,
          errorMessage: 'Username or Password Incorrect'
        });
      });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Login | Korecki Real Estate Services</title>
          <meta
            name="description"
            content="Login to our Client Property Account System (CPAS) where we provide the tools to enable owners to access all information pertaining to their property at a touch of a button."
          />
          <meta
            name="keywords"
            content="korecki,Real Estate,Strata Management services, Rent, Properties, Property Management, Strata Property Management, Strata Management, Property Management, Client Property Account System, CPAS, Property Information, Login, Account"
          />
        </Helmet>
        <div className="background-image">
          <Grid>
            <Row>
              <Col md={12}>
                <h1 className="parallax-title">Strata Management Login</h1>
              </Col>
            </Row>
          </Grid>
        </div>
        <div className="contact-section">
          <Grid>
            <Row>
              <Col smOffset={3} md={5}>
                <Form horizontal>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                      Strata Plan #
                    </Col>
                    <Col sm={9}>
                      <FormControl
                        name="email"
                        type="text"
                        placeholder="Strata Plan #"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                      Password
                    </Col>
                    <Col sm={9}>
                      <FormControl
                        name="password"
                        type="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col smOffset={3} sm={4}>
                      <button
                        type="submit"
                        onClick={this.login}
                        className="btn btn-primary">
                        Submit
                      </button>
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row>
              {this.state.error ? (
                <ErrorAlert errorMessage={this.state.errorMessage} />
              ) : null}
            </Row>
          </Grid>
        </div>
        <Layout />
      </div>
    );
  }
}

export default StrataLogin;
