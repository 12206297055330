import decode from 'jwt-decode';

const Auth = {
  isAuthenticated(roles) {
    const tokenValid =
      localStorage.token &&
      decode(localStorage.token).exp > Date.now().valueOf() / 1000;

    if (roles && roles.length) {
      const role = localStorage.getItem('role');
      return tokenValid && roles.includes(role);
    }

    return tokenValid;
  },

  setAuthHeader(headers) {
    if (localStorage.token) {
      return { ...headers, Authorization: `Bearer ${localStorage.token}` };
    }
    return headers;
  },

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('role');
    localStorage.removeItem('user_id');
    localStorage.removeItem('portalOption');
  }
};

export default Auth;
