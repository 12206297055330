import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  InputGroup
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorMessage: '',
      strataProperty: {},
      userArray: [],
      userName: '',
      userChanged: false,
      roleList: ['Strata Owner'],
      propertyAdmin: '',
      propertyAdminList: [],
      loading: false,
      agents: []
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeStrataNumber = this.handleInputChangeStrataNumber.bind(
      this
    );
    this.update = this.update.bind(this);

    this.notify = () =>
      toast('Strata Property Saved', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: css({
          background: 'black'
        })
      });
  }

  componentDidMount() {
    this.loadProperty();
    this.loadPropertyAdminToExclude();
    this.loadUsers();
    this.loadAgents();
  }

  loadPropertyAdminToExclude() {
    request.get(`/StrataProperty/all`).then((property) => {
      this.setState({
        propertyAdminList: property.map((p) => p.propertyAdminId)
      });
    });
  }

  loadProperty() {
    const {
      match: { params }
    } = this.props;
    request.get(`/strataproperty/${params.id}`).then((property) => {
      this.setState({
        strataProperty: property,
        propertyAdmin: property.propertyAdminId
      });
    });
  }

  loadAgents() {
    request
      .get('/strataAgent')
      .then((res) => {
        this.setState({ agents: res });
      })
      .catch(() => {
        toast('Could Not Load Strata Agents.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  loadUsers() {
    const userHolderArray = [];
    let roleQueryString = '';
    this.state.roleList.forEach((r) => {
      roleQueryString += `rolesList=${r.replace(/ /g, '%20')}&`;
    });
    request.get(`/User/userRoleList?${roleQueryString}`).then((users) => {
      users.forEach((user) => {
        if (!this.state.propertyAdminList.includes(user.userID)) {
          const userHolder = {
            id: user.userID,
            name: user.userEmail,
            userName: user.userName
          };
          userHolderArray.push(userHolder);
        }
      });
      this.setState({
        userArray: userHolderArray
      });
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState((prevState) => {
      const strataProperty = { ...prevState.strataProperty };
      strataProperty[name] = value;
      strataProperty.address = `${strataProperty.street}, ${strataProperty.city}, ${strataProperty.province}, ${strataProperty.postal}`;
      return { strataProperty };
    });
  }

  handleInputChangeStrataNumber(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      strataProperty: { ...prevState.strataProperty, [name]: value },
      userName: value,
      userChanged: true
    }));
  }

  update(e) {
    e.preventDefault();
    this.setState({ loading: true });
    if (this.state.userChanged === true) {
      request
        .put(
          `/User/updateUsername?id=${this.state.strataProperty.propertyAdminId.toString()}&userName=${
            this.state.userName
          }`
        )
        .then(() => null);
    }
    const {
      match: { params }
    } = this.props;
    const data = this.state.strataProperty;
    request
      .put(`/strataproperty/${params.id}`, data)
      .then((property) => {
        if (property) {
          this.setState({ loading: false });
          this.props.history.push(`/strataAdmin/property/${property.id}`);
          this.notify();
        }
      })
      .catch(() => {
        this.setState({
          error: true,
          errorMessage:
            'Unable to update user. The email may already be in use.',
          loading: false
        });
      });
  }

  showAgentOptions() {
    return (
      <FormGroup>
        <ControlLabel>Strata Agent</ControlLabel>
        <FormControl
          componentClass="select"
          id="agent"
          placeholder="select"
          name="agentId"
          value={this.state.strataProperty.agentId}
          onChange={this.handleInputChange}>
          <option value="0">Choose one:</option>
          {this.state.agents.map((item) => (
            <option value={item.id}>{item.name}</option>
          ))}
        </FormControl>
      </FormGroup>
    );
  }

  propertyAdmin() {
    const listOfUsers = this.state.userArray.map((item) => (
      <option value={item.id}>{item.userName}</option>
    ));
    this.state.userName = this.state.strataProperty.strataPlanNumber;

    if (this.state.propertyAdmin == null) {
      return (
        <FormGroup>
          <ControlLabel>Property Login</ControlLabel>
          <FormControl
            componentClass="select"
            placeholder="select"
            name="propertyAdminId"
            value={this.state.strataProperty.propertyAdminId}
            onChange={this.handleInputChange}>
            <option value="">Choose one:</option>
            {listOfUsers}
          </FormControl>
        </FormGroup>
      );
    }
    return (
      <FormGroup>
        <ControlLabel>Property Login</ControlLabel>
        <FormControl
          name="propertyAdminId"
          type="text"
          value={this.state.userName}
          style={{ backgroundColor: '#D3D3D3' }}
          readOnly="true"
        />
      </FormGroup>
    );
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Col>
                <h3 style={{ marginBottom: 15 }}>Edit property</h3>
              </Col>
            </Grid>
            <Grid>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Building Name</ControlLabel>
                    <FormControl
                      name="buildingName"
                      type="text"
                      placeholder={this.state.strataProperty.buildingName}
                      value={this.state.strataProperty.buildingName}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Strata Plan #</ControlLabel>
                    <FormControl
                      name="strataPlanNumber"
                      type="text"
                      placeholder={this.state.strataProperty.strataPlanNumber}
                      value={this.state.strataProperty.strataPlanNumber}
                      onChange={this.handleInputChangeStrataNumber}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>{this.propertyAdmin()}</Col>
                <Col md={2}>{this.showAgentOptions()}</Col>
                <Col md={12}>
                  <h3 style={{ marginBottom: 15 }}>Edit Address</h3>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Street</ControlLabel>
                    <FormControl
                      name="street"
                      type="text"
                      placeholder={this.state.strataProperty.street}
                      value={this.state.strataProperty.street}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>City</ControlLabel>
                    <FormControl
                      name="city"
                      type="text"
                      placeholder="City"
                      value={this.state.strataProperty.city}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <ControlLabel>Province</ControlLabel>
                    <FormControl
                      name="province"
                      type="text"
                      placeholder="Province"
                      value={this.state.strataProperty.province}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <ControlLabel>Country</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="countryId"
                      value={this.state.strataProperty.countryId}
                      onChange={this.handleInputChange}>
                      <option value="1">Canada</option>
                      <option value="2">U.S</option>
                      <option value="3">Other</option>
                    </FormControl>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <ControlLabel>Postal Code</ControlLabel>
                    <InputGroup>
                      <FormControl
                        name="postal"
                        type="text"
                        placeholder="Postal Code"
                        value={this.state.strataProperty.postal}
                        onChange={this.handleInputChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <ControlLabel>Address</ControlLabel>
                    <FormControl
                      name="address"
                      type="text"
                      placeholder="test"
                      style={{ backgroundColor: '#D3D3D3' }}
                      value={`${this.state.strataProperty.street}, ${this.state.strataProperty.city}, ${this.state.strataProperty.province}, ${this.state.strataProperty.postal}`}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  {this.state.loading === false ? (
                    <Button type="submit" onClick={this.update}>
                      Update
                    </Button>
                  ) : (
                    <div className="loader" style={{ margin: -10 }} />
                  )}
                </Col>
              </Row>
              <Row>
                {this.state.error ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}
              </Row>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

export default Edit;
