import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Glyphicon,
  Button,
  Modal,
  FormControl,
  Accordion,
  Panel
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

class ArchivedUnitDocuments extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      strataUnit: {},
      property: {},
      documents: [],
      categories: [],
      show: false,
      folder: {
        name: '',
        type: 'unit',
        propertyId: params.id
      },
      error: false,
      errorMessage: ''
    };

    this.handleClose = this.handleClose.bind(this);
    this.addFolder = this.addFolder.bind(this);

    this.compare = (a, b) => {
      const alpha = a.documentDate;
      const beta = b.documentDate;

      let comparison = 0;
      if (alpha < beta) {
        comparison = 1;
      } else if (alpha > beta) {
        comparison = -1;
      }
      return comparison;
    };

    this.icon = (header) => (
      <div>
        {header}{' '}
        <Glyphicon
          glyph="glyphicon glyphicon-plus"
          style={{ float: 'right' }}
        />
      </div>
    );
  }

  componentDidMount() {
    this.loadStrataUnit();
    this.loadCategories();
    this.loadDocuments();
  }

  loadStrataUnit() {
    const {
      match: { params }
    } = this.props;
    request.get(`/strataUnit/${params.id}`).then((unit) => {
      this.setState({
        strataUnit: unit,
        property: unit.strataProperty
      });
    });
  }

  loadCategories() {
    request.get('/strataUnitDocumentCategory').then((categories) => {
      this.setState(
        {
          categories
        },
        () => {}
      );
    });
  }

  loadDocuments() {
    const {
      match: { params }
    } = this.props;
    request
      .get(`/strataUnitDocument/strataUnit/${params.id}?archived=true`)
      .then((documents) => {
        if (documents) {
          this.setState({ documents });
        }
      })
      .catch(() => {
        this.setState({
          error: true,
          errorMessage: 'Error loading documents.'
        });
      });
  }

  deleteDocument(id) {
    const element = document.getElementById(id);
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Are you sure you want to delete this? This CAN NOT be undone.'
      )
    ) {
      request
        .delete(`/strataUnitDocument/${id}`)
        .then(() => {
          element.parentNode.removeChild(element);
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Error deleting documents.'
          });
        });
    }
  }

  restoreDocument(id) {
    const element = document.getElementById(id);
    // eslint-disable-next-line no-alert
    if (window.confirm('The document will be restored')) {
      request
        .put(`/strataUnitDocument/restore/${id}`)
        .then(() => {
          element.parentNode.removeChild(element);
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Error restoring document.'
          });
        });
    }
  }

  showDocument(id) {
    this.props.history.push({
      pathname: `/strataAdmin/document/view/${id}`,
      params: {
        documentFor: 'unit'
      }
    });
  }

  getCategory(id) {
    const c = this.state.categories;
    if (c === undefined || c.length === 0) {
      return id;
    }
    for (let i = 0; i < c.length; i += 1) {
      if (c[i].id === id) {
        return c[i].name;
      }
    }
    return null;
  }

  generatePanels() {
    const { categories } = this.state;
    const { documents } = this.state;
    return categories.map((c) => {
      const catDocs = [];
      documents.forEach((d) => {
        if (c.id === d.strataUnitDocumentCategoryId) {
          if (!d.title) {
            catDocs.push({ ...d, title: 'No File Name' });
          }
          catDocs.push(d);
        }
      });
      catDocs.sort(this.compare);
      if (catDocs.length > 0) {
        return (
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <Accordion>
                    <Panel header={this.icon(c.name)} eventKey="3">
                      {catDocs.map((d) => (
                        <Grid>
                          <Row
                            id={d.id}
                            style={{
                              clear: ' both',
                              overflow: 'hidden',
                              paddingRight: '40px'
                            }}>
                            <Col md={6}>
                              <p style={{ fontSize: '16px' }}>
                                <a
                                  href={`${process.env.REACT_APP_SITE_API}/api/strataUnitDocument/${d.id}/file`}>
                                  {d.title}
                                </a>
                              </p>
                            </Col>
                            <Col md={6}>
                              <Button
                                bsSize="small"
                                bsStyle="danger"
                                style={{
                                  marginRight: 5,
                                  marginLeft: 5,
                                  float: 'right'
                                }}
                                onClick={() => {
                                  this.deleteDocument(d.id);
                                }}>
                                <Glyphicon glyph="trash" />
                              </Button>
                              <Button
                                bsSize="small"
                                bsStyle="warning"
                                style={{
                                  marginRight: 5,
                                  marginLeft: 5,
                                  float: 'right'
                                }}
                                onClick={() => {
                                  this.restoreDocument(d.id);
                                }}>
                                <Glyphicon glyph="ok" />
                              </Button>
                              <Button
                                bsSize="small"
                                bsStyle="info"
                                style={{
                                  marginRight: 5,
                                  marginLeft: 5,
                                  float: 'right'
                                }}
                                onClick={() => {
                                  this.showDocument(d.id);
                                }}>
                                <Glyphicon glyph="eye-open" />
                              </Button>
                              <Button
                                bsSize="small"
                                bsStyle="success"
                                style={{
                                  marginRight: 5,
                                  marginLeft: 5,
                                  float: 'right'
                                }}
                                href={`${process.env.REACT_APP_SITE_API}/api/strataUnitDocument/${d.id}/file`}>
                                <Glyphicon glyph="download-alt" />
                              </Button>
                            </Col>
                          </Row>
                        </Grid>
                      ))}
                    </Panel>
                  </Accordion>
                </Col>
              </Row>
            </Grid>
          </div>
        );
      }
      return null;
    });
  }

  handleClose() {
    this.setState({ show: false });
  }

  addFolder() {
    request.post('/directory/', this.state.folder).then(() => {
      this.handleClose();
      this.loadDirectories();
    });
  }

  render() {
    return (
      <div>
        <section className="section m-none">
          <Grid>
            <Row>
              <Col md={12}>
                <h3 style={{ marginBottom: 15 }}>
                  {this.state.property.buildingName} ||{' '}
                  {this.state.strataUnit.unitLocation}-
                  {this.state.property.address}
                </h3>
              </Col>
            </Row>
            <Row>
              {this.state.error ? (
                <ErrorAlert errorMessage={this.state.errorMessage} />
              ) : null}
            </Row>
          </Grid>

          {this.generatePanels()}
          <div className="row">
            <div className="col-md-12 text-center">
              <Link
                to={`/strataAdmin/strataUnit/${this.state.strataUnit.id}`}
                className="btn btn-outline btn-primary text-uppercase margin-bottom">
                Documents
              </Link>
            </div>
          </div>

          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header>
              <Modal.Title>New Folder</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <FormControl
                name="name"
                type="text"
                placeholder="Folder Name"
                value={this.state.folder.name}
              />
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={this.handleClose}>Close</Button>
              <Button onClick={this.addFolder} bsStyle="primary">
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
      </div>
    );
  }
}

export default ArchivedUnitDocuments;
