import React from 'react';

/* Public Components */
import { Switch } from 'react-router-dom';
import ResidentialLogin from './public/ResidentialLogin';
import StrataLogin from './public/StrataLogin';
import ResetPassword from './public/ResetPassword';
import NewPassword from './public/NewPassword';

/* Admin Components */
import AdminDashboard from './admin/Dashboard';
import AdminDocument from './admin/document/List';
import AdminPropertyDetail from './admin/property/Detail';
import AdminPropertyAdd from './admin/property/Add';
import AdminPropertyEdit from './admin/property/Edit';
import AdminOwnerAdd from './admin/owner/Add';
import AdminOwnerEdit from './admin/owner/Edit';
import AdminOwnerDetail from './admin/owner/Detail';
import AdminListingDetail from './admin/listing/Detail';
import AdminListingAdd from './admin/listing/Add';
import AdminListingEdit from './admin/listing/Edit';
import AdminViewArchived from './admin/archives/Archive';
import AdminArchivedOwnerDetails from './admin/archives/owner/Detail';
import AdminArchivedPropertyDetails from './admin/archives/property/Detail';
import AdminOwnerEditPassword from './admin/owner/resetOwnerPassword';

/* Owner Components */
import OwnerDashboard from './owner/Dashboard';
import OwnerPropertyDetails from './owner/property/Detail';

/* Strata Admin Components */
import StrataPropertyAdd from './strataAdmin/property/Add';
import StrataAdminDashboard from './strataAdmin/Dashboard';
import StrataUserAdd from './strataAdmin/strataUnit/AddUser';
import StrataUserEdit from './strataAdmin/strataUnit/EditUser';
import StrataPropertyDetail from './strataAdmin/property/Detail';
import StrataPropertyEdit from './strataAdmin/property/Edit';
import AddStaff from './strataAdmin/property/Staff';
import StrataAdminArchivedProperties from './strataAdmin/Archived';
import StrataPropertyArchivedDocs from './strataAdmin/property/Archived';
import StrataDocumentUpload from './strataAdmin/document/Upload';
import StrataDocumentEdit from './strataAdmin/document/Edit';
import StrataDocumentViewPdf from './strataAdmin/document/View';
import UserAdd from './strataAdmin/users/Create';
import UserList from './strataAdmin/users/List';
import EditUser from './strataAdmin/users/Edit';
import EditDocument from './utils/EditDocument';
import UserArchive from './strataAdmin/users/Archived';
import AddAgent from './strataAdmin/agent/Create';
import AgentList from './strataAdmin/agent/List';
import AgentArchived from './strataAdmin/agent/Archived';
import AgentEdit from './strataAdmin/agent/Edit';
import StrataUnitDetail from './strataAdmin/strataUnit/Detail';
import StrataUnitAdd from './strataAdmin/strataUnit/Add';
import StrataUnitDocumentEdit from './strataAdmin/document/EditUnit';
import StrataUnitArchived from './strataAdmin/strataUnit/Archived';
import StrataUnitDocumentsArchived from './strataAdmin/strataUnit/ArchivedDocuments';
import StrataAdminImportOwners from './strataAdmin/importExport/importOwners';

/* Strata Property Admin Components */
import PropertyAdminDashboard from './propertyAdmin/Dashboard';
import PropertyAdminStrataDocumentViewPdf from './propertyAdmin/document/View';

/* Strata Unit Owner Compnents */
import AuthorizeUser from './strataUnitOwner/AuthorizeUser';
import StrataUnitOwnerStrataUnitDetails from './strataUnitOwner/strataUnit/Details';
import StrataUnitOwnerDashboard from './strataUnitOwner/Dashboard';
import StrataUnitOwnerStrataPropertyDetails from './strataUnitOwner/strataProperty/Detail';
import ExtraPayments from './strataUnitOwner/forms/ExtraPayments';
import StrataUnitOwnerPADCreate from './strataUnitOwner/strataUnit/PAD/Add';
import StrataUnitMonthlyPaymentAdd from './strataUnitOwner/strataUnit/payments/AddMonthlyPayment';
import StrataUnitMonthlyPaymentReplace from './strataUnitOwner/strataUnit/payments/ReplaceMonthlyPayment';

// Strata Unit Document Forms
import SUAdminBylawComplaintForm from './strataAdmin/strataUnit/documentForms/bylawComplaint';
import SUAdminBylawDisputeForm from './strataAdmin/strataUnit/documentForms/bylawDispute';
import SUAdminBylawCOAForm from './strataAdmin/strataUnit/documentForms/changeOfAddress';
import SUAdminBylawOwnerInfoSheetForm from './strataAdmin/strataUnit/documentForms/ownerInfoSheet';
import SUAdminBylawPADForm from './strataAdmin/strataUnit/documentForms/PAD';

// Routes
import StandardRoute from './routes/StandardRoute';
import ProtectedAdminRoute from './routes/ProtectedAdminRoute';
import ProtectedOwnerRoute from './routes/ProtectedOwnerRoute';
import ProtectedPropertyAdminRoute from './routes/ProtectedPropertyAdminRoute';
import ProtectedStrataAdminRoute from './routes/ProtectedStrataAdminRoute';
import ProtectedStrataUnitOwnerRoute from './routes/ProtectedStrataUnitOwnerRoute';

const Main = () => (
  <Switch>
    <ProtectedAdminRoute
      path="/admin/owner/resetOwnerPassword/:id"
      component={AdminOwnerEditPassword}
    />
    <StandardRoute exact path="/" component={ResidentialLogin} />
    <StandardRoute path="/account/resetpassword" component={ResetPassword} />
    <StandardRoute path="/account/newpassword" component={NewPassword} />
    <StandardRoute path="/login" component={ResidentialLogin} />
    <StandardRoute path="/strataLogin" component={StrataLogin} />
    <StandardRoute path="/authorize" component={AuthorizeUser} />
    <ProtectedAdminRoute path="/admin/dashboard" component={AdminDashboard} />
    <ProtectedAdminRoute path="/admin/document" component={AdminDocument} />
    <ProtectedAdminRoute
      path="/admin/property/add/:id"
      component={AdminPropertyAdd}
    />
    <ProtectedAdminRoute
      path="/admin/property/:id"
      component={AdminPropertyDetail}
    />
    <ProtectedAdminRoute
      path="/admin/edit/property/:id"
      component={AdminPropertyEdit}
    />
    <ProtectedAdminRoute path="/admin/owner/add" component={AdminOwnerAdd} />
    <ProtectedAdminRoute path="/admin/owner/:id" component={AdminOwnerDetail} />
    <ProtectedAdminRoute
      path="/admin/edit/owner/:id"
      component={AdminOwnerEdit}
    />
    <ProtectedAdminRoute
      path="/admin/listing/add/:id"
      component={AdminListingAdd}
    />
    <ProtectedAdminRoute
      path="/utils/editDocument/:id"
      component={EditDocument}
    />
    <ProtectedAdminRoute
      path="/admin/listing/edit/:id"
      component={AdminListingEdit}
    />
    <ProtectedAdminRoute
      path="/admin/listing/:id"
      component={AdminListingDetail}
    />
    <ProtectedAdminRoute path="/admin/archive" component={AdminViewArchived} />
    <ProtectedAdminRoute
      path="/admin/archived/owner/:id"
      component={AdminArchivedOwnerDetails}
    />
    <ProtectedAdminRoute
      path="/admin/archived/property/:id"
      component={AdminArchivedPropertyDetails}
    />
    <ProtectedOwnerRoute path="/owner/dashboard" component={OwnerDashboard} />
    <ProtectedOwnerRoute
      path="/owner/property/:id"
      component={OwnerPropertyDetails}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/import-owners"
      component={StrataAdminImportOwners}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/dashboard"
      component={StrataAdminDashboard}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/archived"
      component={StrataAdminArchivedProperties}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/property/add/"
      component={StrataPropertyAdd}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/property/archived/:id"
      component={StrataPropertyArchivedDocs}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/property/:id"
      component={StrataPropertyDetail}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/strataUnit/:id"
      component={StrataUnitDetail}
    />
    <ProtectedStrataAdminRoute
      path="/strataUnitAdmin/:id/createDocument/bylawComplaint"
      component={SUAdminBylawComplaintForm}
    />
    <ProtectedStrataAdminRoute
      path="/strataUnitAdmin/:id/createDocument/bylawDispute"
      component={SUAdminBylawDisputeForm}
    />
    <ProtectedStrataAdminRoute
      path="/strataUnitAdmin/:id/createDocument/changeOfAddress"
      component={SUAdminBylawCOAForm}
    />
    <ProtectedStrataAdminRoute
      path="/strataUnitAdmin/:id/createDocument/ownerInformationSheet"
      component={SUAdminBylawOwnerInfoSheetForm}
    />
    <ProtectedStrataAdminRoute
      path="/strataUnitAdmin/:id/createDocument/PAD"
      component={SUAdminBylawPADForm}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/strataProperty/:id/strataUnit/add"
      component={StrataUnitAdd}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/user/add/strataUnit/:id/"
      component={StrataUserAdd}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/user/edit/strataUnit/:id/"
      component={StrataUserEdit}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/edit/:id"
      component={StrataPropertyEdit}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/staff/:id"
      component={AddStaff}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/document/upload/:id"
      component={StrataDocumentUpload}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/document/upload"
      component={StrataDocumentUpload}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/document/edit/:id"
      component={StrataDocumentEdit}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/strataUnitDocument/edit/:id"
      component={StrataUnitDocumentEdit}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/document/view/:id"
      component={StrataDocumentViewPdf}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/user/add"
      component={UserAdd}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/user/list"
      component={UserList}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/user/archived"
      component={UserArchive}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/user/edit/:id"
      component={EditUser}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/agent/add"
      component={AddAgent}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/agent/list"
      component={AgentList}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/agent/archived"
      component={AgentArchived}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/agent/edit/:id"
      component={AgentEdit}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/strataUnits/archived/property/:id"
      component={StrataUnitArchived}
    />
    <ProtectedStrataAdminRoute
      path="/strataAdmin/documents/archived/strataUnit/:id"
      component={StrataUnitDocumentsArchived}
    />
    <ProtectedPropertyAdminRoute
      path="/propertyAdmin/dashboard"
      component={PropertyAdminDashboard}
    />
    <ProtectedPropertyAdminRoute
      path="/propertyAdmin/document/view/:id"
      component={PropertyAdminStrataDocumentViewPdf}
    />
    <ProtectedStrataUnitOwnerRoute
      path="/strataUnitOwner/strataUnit/:id"
      component={StrataUnitOwnerStrataUnitDetails}
    />
    <ProtectedStrataUnitOwnerRoute
      path="/strataUnitOwner/dashboard"
      component={StrataUnitOwnerDashboard}
    />
    <ProtectedStrataUnitOwnerRoute
      path="/strataUnitOwner/pad/create/:ownerId"
      component={StrataUnitOwnerPADCreate}
    />
    <ProtectedStrataUnitOwnerRoute
      path="/strataUnitOwner/document/view/:id"
      component={PropertyAdminStrataDocumentViewPdf}
    />
    <ProtectedStrataUnitOwnerRoute
      path="/strataUnitOwner/strataProperty/:id"
      component={StrataUnitOwnerStrataPropertyDetails}
    />
    <ProtectedStrataUnitOwnerRoute
      path="/strataUnitOwner/forms/extraPayments/:id"
      component={ExtraPayments}
    />
    <ProtectedStrataUnitOwnerRoute
      path="/strataUnitOwner/monthlyPayment/strataUnit/:id"
      component={StrataUnitMonthlyPaymentAdd}
    />
    <ProtectedStrataUnitOwnerRoute
      path="/strataUnitOwner/monthlyPayment/replace/strataUnit/:id"
      component={StrataUnitMonthlyPaymentReplace}
    />
  </Switch>
);

export default Main;
