import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Table,
  Glyphicon,
  Button,
  Modal,
  FormControl
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import request from '../../../utils/Request';
import DocumentTable from '../../utils/dataTable/DocumentTable';
import DetailsTable from '../../utils/property/DetailsTable';
import StrataCompanyTable from '../../utils/property/StrataCompanyTable';
import ErrorAlert from '../../utils/ErrorAlert';

class Detail extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      error: false,
      errorMessage: '',
      property: {},
      listings: [],
      documents: [],
      id: params.id,
      show: false,
      folder: {
        name: '',
        type: 'property',
        propertyId: params.id
      },
      filterOptions: {
        documentTypeId: '2',
        month: '1',
        year: '2018'
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
    this.addFolder = this.addFolder.bind(this);
  }

  componentDidMount() {
    this.loadDirectories();
    this.loadProperty();
    this.loadListings();
  }

  handleFilterInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      filterOptions: { ...prevState.filterOptions, [name]: value }
    }));
  }

  getDocumentTypeName(id) {
    let name = '';
    this.state.docTypes.forEach((t) => {
      if (t.id === id) {
        name = t.name;
      }
    });
    return name;
  }

  loadProperty() {
    const {
      match: { params }
    } = this.props;
    request.get(`/property/${params.id}`).then((property) => {
      this.setState({
        property
      });
    });
  }

  loadListings() {
    const {
      match: { params }
    } = this.props;
    request.get(`/propertylisting/list/${params.id}`).then((listings) => {
      this.setState({
        listings
      });
    });
  }

  deleteDocument(id) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Are you sure you want to delete this? This CAN NOT be undone.'
      )
    ) {
      request
        .delete(`/documents/delete/${id}`)
        .then(() => {
          this.loadDirectories();
          this.setState({ error: false });
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Error deleting document.'
          });
        });
    }
  }

  loadDirectories() {
    const {
      match: { params }
    } = this.props;
    request
      .get(`/documents/property/${params.id}/all`)
      .then((documents) => {
        this.setState({
          documents: documents.map((d) => ({
            ...d,
            type: d.documentType.name,
            date: moment(d.documentDate).format('MMMM YYYY'),
            link: (
              <a
                href={`${process.env.REACT_APP_SITE_API}/Uploads/Documents/${d.path}`}
                download={d.Title}>
                Download File
              </a>
            ),
            action_buttons: (
              <div className="text-center">
                <Button
                  bsSize="xsmall"
                  bsStyle="danger"
                  style={{ marginRight: 5, marginLeft: 5 }}
                  onClick={() => {
                    this.deleteDocument(d.id);
                  }}>
                  <Glyphicon glyph="trash" />
                </Button>
                <Button
                  bsSize="xsmall"
                  bsStyle="primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    this.props.history.push(`/utils/editDocument/${d.id}`);
                  }}>
                  <Glyphicon glyph="pencil" />
                </Button>
              </div>
            )
          }))
        });
      })
      .catch(() => {});
  }

  deleteListing(id) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Are you sure you want to delete this? This CANNOT be undone.'
      )
    ) {
      request
        .delete(`/propertylisting/${id}`)
        .then(() => {
          this.loadListings();
          this.setState({ error: false });
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Something Went Wrong. Please try again later.'
          });
        });
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  addFolder() {
    request.post('/directory/', this.state.folder).then(() => {
      this.handleClose();
      this.loadDirectories();
    });
  }

  listingRows() {
    let rows = (
      <tr>
        <td colSpan={4}>No Listings</td>
      </tr>
    );

    if (this.state && this.state.listings && this.state.listings.length > 0) {
      rows = this.state.listings.map((listing) => (
        <tr>
          <td>
            <Link to={`/admin/listing/edit/${listing.id}`}>
              {listing.description}
            </Link>
          </td>
          <td>{`$${listing.price}.00`}</td>
          <td>{listing.available ? 'Yes' : 'No'}</td>
          <td>
            <Button
              bsSize="xsmall"
              bsStyle="danger"
              onClick={() => {
                this.deleteListing(listing.id);
              }}>
              <Glyphicon glyph="trash" />
            </Button>
          </td>
        </tr>
      ));
    }

    return rows;
  }

  render() {
    return (
      <div>
        <DetailsTable property={this.state.property} />

        <StrataCompanyTable property={this.state.property} />

        <section className="section m-top">
          <Grid>
            <Row>
              <Col md={6}>
                <h3>Listings</h3>
              </Col>
              <Col md={6}>
                <Link
                  className="btn btn-primary btn-xs pull-right"
                  to={`/admin/listing/add/${this.state.id}`}>
                  New Listing
                </Link>
              </Col>
              <Col md={12}>
                <Table striped bordered condensed hover>
                  <thead>
                    <tr>
                      <td>Description</td>
                      <td>Price</td>
                      <td>Available</td>
                      <td>Actions</td>
                    </tr>
                  </thead>
                  <tbody>{this.listingRows()}</tbody>
                </Table>
              </Col>
            </Row>
          </Grid>
        </section>
        <section className="section m-top">
          <DocumentTable documents={this.state.documents} />
        </section>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>New Folder</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormControl
              name="name"
              type="text"
              placeholder="Folder Name"
              value={this.state.folder.name}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.handleClose}>Close</Button>
            <Button onClick={this.addFolder} bsStyle="primary">
              Add
            </Button>
          </Modal.Footer>
          <Row>
            {this.state.error ? (
              <ErrorAlert errorMessage={this.state.errorMessage} />
            ) : null}
          </Row>
        </Modal>
      </div>
    );
  }
}

export default Detail;
