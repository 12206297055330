import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col, Glyphicon, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import AgentTable from '../../utils/dataTable/AgentTable';
import ErrorAlert from '../../utils/ErrorAlert';
import request from '../../../utils/Request';

class AgentArchived extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agents: [],
      properties: [],
      error: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.loadAgents();
  }

  loadAgents() {
    const agentHolder = [];
    request
      .get(`/strataagent/archived`)
      .then((agents) => {
        agents.forEach((agent) => {
          this.getAgentProperties(agent.id);
          const holder = {
            strataNumber: this.state.strataNumber,
            email: agent.email,
            name: agent.name,
            phone: agent.phone,
            address: this.state.propertyName,
            button: (
              <div className="text-center">
                <Button
                  bsSize="xsmall"
                  bsStyle="success"
                  style={{ marginLeft: 5 }}
                  onClick={() => this.restoreAgent(agent.id)}>
                  <Glyphicon glyph="ok" />
                </Button>
                <Button
                  bsSize="xsmall"
                  bsStyle="danger"
                  style={{ marginLeft: 5 }}
                  onClick={() => this.deleteAgent(agent.id)}>
                  <Glyphicon glyph="trash" />
                </Button>
              </div>
            )
          };
          agentHolder.push(holder);
        });

        this.setState({
          agents: agentHolder
        });
      })
      .catch(() => {
        toast('Could Not Load Agents.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  getAgentProperties(id) {
    let setAddress = 'No properties attached';
    let setId = 'No strata plans attached';
    const { properties } = this.state;
    properties.forEach((property) => {
      if (property.agentId === id) {
        setAddress = property.street;
        setId = property.strataPlanNumber;
      }
    });
    this.setState({ propertyName: setAddress, strataNumber: setId });
  }

  restoreAgent(id) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to restore this agent?')) {
      request
        .archive(`/strataagent/restore/${id}`)
        .then(() => {
          this.loadAgents();
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Something went Wrong. Please try again Later.'
          });
        });
    }
  }

  deleteAgent(id) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this agent?')) {
      request
        .delete(`/strataagent/delete/${id}`)
        .then(() => {
          this.loadAgents();
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Error creating new user. User may already exist.'
          });
        });
    }
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3>Strata Agents</h3>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Col md={6} />
                <Col md={6}>
                  <Link
                    className="btn btn-primary btn-xs pull-right"
                    to="/strataAdmin/agent/add">
                    Add Agents
                  </Link>
                </Col>
              </Row>
              <Row>
                {this.state.error ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}
              </Row>
            </Grid>
            <AgentTable agents={this.state.agents} />
          </div>
        </section>
        <div className="row">
          <div className="col-md-12 center">
            <Link
              to="/strataAdmin/agent/list"
              className="btn btn-outline btn-primary text-uppercase margin-bottom">
              View Agents
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default AgentArchived;
