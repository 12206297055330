import 'whatwg-fetch';
import Auth from './Auth';

const api = `${process.env.REACT_APP_SITE_API}/api`;
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};

function parseJSON(response) {
  return response.json();
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}
const Request = {
  /**
   * Make GET request and parse data
   * @param endpoint
   * @returns {Promise.<TResult>}
   */
  get(endpoint) {
    return fetch(api + endpoint, {
      method: 'get',
      headers: Auth.setAuthHeader(headers)
    })
      .then(checkStatus)
      .then(parseJSON)
      .catch((err) =>
        // return err
        Promise.reject(Error(`Error: ${err}`))
      );
  },

  /**
   * Post data to api endpoint
   * @param endpoint
   * @param data
   * @returns {Promise.<TResult>}
   */
  post(endpoint, data) {
    return fetch(api + endpoint, {
      method: 'post',
      headers: Auth.setAuthHeader(headers),
      body: JSON.stringify(data)
    })
      .then(checkStatus)
      .then(parseJSON)
      .catch((err) => Promise.reject(Error(`Upload Error: ${err}`)));
  },

  /**
   * Put data to api endpoint
   * @param endpoint
   * @param data
   * @returns {Promise.<TResult>}
   */
  put(endpoint, data) {
    return fetch(api + endpoint, {
      method: 'put',
      headers: Auth.setAuthHeader(headers),
      body: JSON.stringify(data)
    })
      .then(checkStatus)
      .then(parseJSON)
      .catch((err) => Promise.reject(Error(`Error: ${err}`)));
  },

  /**
   * Delete request
   * @param endpoint
   * @param data
   * @returns {Promise.<TResult>}
   */
  delete(endpoint, data) {
    return fetch(api + endpoint, {
      method: 'delete',
      headers: Auth.setAuthHeader(headers),
      body: JSON.stringify(data)
    })
      .then(checkStatus)
      .then(parseJSON)
      .catch((err) => err);
  },

  /**
   * Send Strata Request Form request
   * @param endpoint
   * @param data
   * @returns {Promise<TResult>}
   */
  sendStrataRequest(endpoint, data) {
    return fetch(api + endpoint, {
      method: 'post',
      headers: Auth.setAuthHeader(headers),
      body: JSON.stringify(data)
    })
      .then(checkStatus)
      .then(parseJSON)
      .catch(() => Promise.reject(Error('Email Not Found')));
  },

  /**
   *
   * @param endpoint
   * @param files
   * @returns {Promise<Response>}
   */
  upload(endpoint, file, sortOrder) {
    const formData = new FormData();
    formData.append('file', file);
    if (sortOrder) {
      formData.append('sortOrder', sortOrder);
    }

    return fetch(api + endpoint, {
      method: 'post',
      headers: Auth.setAuthHeader({}),
      body: formData
    })
      .then(checkStatus)
      .then((data) => data)
      .catch((err) => Promise.reject(Error('Request failed', err)));
  },

  /**
   * Second Upload function that does put
   * @param endpoint
   * @param files
   * @returns {Promise<Response>}
   */
  upload2(endpoint, file) {
    const formData = new FormData();
    formData.append('file', file);
    return fetch(api + endpoint, {
      method: 'put',
      body: formData
    })
      .then(checkStatus)
      .then(parseJSON)
      .catch((err) => Promise.reject(Error('Request failed', err)));
  },

  /**
   * Log user in and store token
   * @param data
   * @returns {Promise.<TResult>}
   */
  login(data) {
    return fetch(`${api}/auth`, {
      method: 'post',
      headers: Auth.setAuthHeader(headers),
      body: JSON.stringify({
        username: data.userName.toString(),
        password: data.password.toString(),
        portal: data.portal.toString()
      })
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((result) => {
        if (result.token) {
          localStorage.token = result.token;
          localStorage.user_id = result.user.id;
          localStorage.role = result.user.role;
          localStorage.email = result.user.email;
          localStorage.name = result.user.name;
        }
        return result;
      })
      .catch(() => Promise.reject(Error('Request failed')));
  },

  /**
   * Archive
   * @param endpoint
   * @param data
   * @returns {Promise<TResult>}
   */
  archive(endpoint) {
    return fetch(api + endpoint, {
      method: 'put',
      headers: Auth.setAuthHeader(headers)
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => data)
      .catch(() => Promise.reject(Error('Unable to archive')));
  },

  /**
   * @param endpoint
   * @returns {Promise.<TResult>}
   */
  getIDByAddress(endpoint, data) {
    return fetch(api + endpoint, {
      method: 'post',
      headers: Auth.setAuthHeader(headers),
      body: JSON.stringify(data)
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((res) => res)
      .catch((err) => Promise.reject(Error(`Request failed: ${err}`)));
  },

  /**
   * @param endpoint
   * @returns {Promise.<TResult>}
   */
  getOwnerIDByName(endpoint) {
    return fetch(api + endpoint, {
      method: 'post',
      headers: Auth.setAuthHeader(headers)
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((res) => res)
      .catch((err) => Promise.reject(Error(`Request failed: ${err}`)));
  }
};

export default Request;
