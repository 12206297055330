import React, { useEffect } from 'react';
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  Row
} from 'react-bootstrap';
import useValidation from '../../../validation/useValidation';
import {
  emailValidator,
  passwordValidator,
  phoneValidator,
  requiredValidator
} from '../../../validation/validators';

export default ({ strataUnitUser, setStrataUnitUser, edit, setValid }) => {
  const [validatePassword, passwordValid, passwordErrors] = useValidation([
    requiredValidator,
    passwordValidator
  ]);
  const [validateRole, roleValid, roleErrors] = useValidation([
    requiredValidator
  ]);
  const [validateEmail, emailValid, emailErrors] = useValidation([
    requiredValidator,
    emailValidator
  ]);
  const [validatePhone, phoneValid, phoneErrors] = useValidation([
    requiredValidator,
    phoneValidator
  ]);
  const [validateFirstName, firstNameValid, firstNameErrors] = useValidation([
    requiredValidator
  ]);
  const [validateLastName, lastNameValid, lastNameErrors] = useValidation([
    requiredValidator
  ]);

  // compute validity every time a validation changes
  useEffect(() => {
    const valid =
      roleValid && emailValid && phoneValid && firstNameValid && lastNameValid;
    setValid(!edit ? valid && passwordValid : valid);
  }, [
    setValid,
    roleValid,
    passwordValid,
    edit,
    emailValid,
    phoneValid,
    firstNameValid,
    lastNameValid
  ]);

  const onChange = (event) => {
    const { name, type, value, checked } = event.target;
    setStrataUnitUser((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Authorize</h3>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup validationState={emailErrors.length && 'error'}>
                    <ControlLabel>Email Address</ControlLabel>
                    <FormControl
                      name="email"
                      type="text"
                      placeholder="Email Address"
                      value={strataUnitUser.email}
                      onChange={(event) => {
                        onChange(event);
                        validateEmail(event.target.value);
                      }}
                      onBlur={(event) => validateEmail(event.target.value)}
                    />
                    {emailErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup validationState={phoneErrors.length && 'error'}>
                    <ControlLabel>Phone Number</ControlLabel>
                    <FormControl
                      name="phoneNumber"
                      type="text"
                      placeholder="Phone Number"
                      value={strataUnitUser.phoneNumber}
                      onChange={(event) => {
                        onChange(event);
                        validatePhone(event.target.value);
                      }}
                      onBlur={(event) => validatePhone(event.target.value)}
                    />
                    {phoneErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup
                    validationState={firstNameErrors.length && 'error'}>
                    <ControlLabel>First Name</ControlLabel>
                    <FormControl
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      value={strataUnitUser.firstName}
                      onChange={(event) => {
                        onChange(event);
                        validateFirstName(event.target.value);
                      }}
                      onBlur={(event) => validateFirstName(event.target.value)}
                    />
                    {firstNameErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup validationState={lastNameErrors.length && 'error'}>
                    <ControlLabel>Last Name</ControlLabel>
                    <FormControl
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      value={strataUnitUser.lastName}
                      onChange={(event) => {
                        onChange(event);
                        validateLastName(event.target.value);
                      }}
                      onBlur={(event) => validateLastName(event.target.value)}
                    />
                    {lastNameErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup validationState={roleErrors.length && 'error'}>
                    <ControlLabel>User Role</ControlLabel>
                    <FormControl
                      componentClass="select"
                      name="strataUnitRole"
                      placeholder="Owner Role"
                      value={strataUnitUser.strataUnitRole}
                      onChange={(event) => {
                        onChange(event);
                        validateRole(event.target.value);
                      }}
                      onBlur={(event) => validateRole(event.target.value)}>
                      <option value="">Select One:</option>
                      <option value={1}>Current Secondary Owner</option>
                      <option value={2}>Former Primary/Secondary</option>
                      <option value={3}>Current Tenant</option>
                      <option value={4}>Former Tenant</option>
                      <option value={5}>Defunct</option>
                      {roleErrors.map((error) => (
                        <HelpBlock key={error}>{error}</HelpBlock>
                      ))}
                    </FormControl>
                  </FormGroup>
                </Col>
                {!edit && (
                  <Col md={12}>
                    <FormGroup
                      validationState={passwordErrors.length && 'error'}>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl
                        name="password"
                        type="password"
                        placeholder="Password"
                        value={strataUnitUser.password}
                        onChange={(event) => {
                          onChange(event);
                          validatePassword(event.target.value);
                        }}
                        onBlur={(event) => validatePassword(event.target.value)}
                      />
                      {passwordErrors.map((error) => (
                        <HelpBlock key={error}>{error}</HelpBlock>
                      ))}
                    </FormGroup>
                  </Col>
                )}
              </Row>
            </Form>
          </Grid>
        </div>
      </section>
    </div>
  );
};
