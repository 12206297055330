import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col, Glyphicon, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import AgentTable from '../../utils/dataTable/AgentTable';
import ErrorAlert from '../../utils/ErrorAlert';
import request from '../../../utils/Request';

class AgentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agents: [],
      properties: [],
      error: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.loadAgents();
    this.loadProperties();
  }

  loadAgents() {
    const agentHolder = [];
    request
      .get(`/strataagent`)
      .then((agents) => {
        agents.forEach((agent) => {
          this.getAgentProperties(agent.id);
          const holder = {
            strataNumber: this.state.strataNumber,
            id: agent.id,
            email: agent.email,
            name: agent.name,
            phone: agent.phone,
            address: this.state.propertyName,
            button: (
              <div className="text-center">
                <Button
                  bsSize="xsmall"
                  bsStyle="warning"
                  style={{ marginRight: 5, marginLeft: 5 }}
                  onClick={() => this.archiveAgent(holder)}>
                  <Glyphicon glyph="remove" />
                </Button>
                <Button
                  bsSize="xsmall"
                  bsStyle="primary"
                  style={{ marginRight: 5 }}
                  onClick={() =>
                    this.props.history.push(
                      `/strataAdmin/agent/edit/${agent.id}`
                    )
                  }>
                  <Glyphicon glyph="cog" />
                </Button>
              </div>
            )
          };
          agentHolder.push(holder);
        });

        this.setState({
          agents: agentHolder
        });
      })
      .catch(() => {
        toast('Could Not Load Agents.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  getAgentProperties(id) {
    let setAddress = 'No properties attached';
    let setId = 'No strata plans attached';
    const { properties } = this.state;
    properties.forEach((property) => {
      if (property.agentId === id) {
        setAddress = property.street;
        setId = property.strataPlanNumber;
      }
    });
    this.setState({ propertyName: setAddress, strataNumber: setId });
  }

  loadProperties() {
    request
      .get('/strataproperty')
      .then((properties) => {
        this.setState({ properties });
      })
      .catch(() => {
        toast('Could Not Load Properties.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  archiveAgent(agent) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to disable this agent?')) {
      // remove agent from any properties that are associated with it
      this.state.properties.forEach((property) => {
        if (agent.id === property.agentId) {
          request
            .get(`/strataProperty/${property.id}`)
            .then((strataProperty) => ({ ...strataProperty, agentId: null }))
            .then((updated) => {
              request
                .put(`/strataProperty/${updated.id}`, updated)
                .then(() => {})
                .catch(() =>
                  this.setState({
                    error: true,
                    errorMessage: 'Error removing user from property.'
                  })
                );
            });
        }
      });
      // disable agent
      request
        .archive(`/strataagent/archive/${agent.id}`)
        .then(() => {
          this.loadAgents();
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Error disabling user.'
          });
        });
    }
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3>Strata Agents</h3>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Col md={6} />
                <Col md={6}>
                  <Link
                    className="btn btn-primary btn-xs pull-right"
                    to="/strataAdmin/agent/add">
                    Add Agents
                  </Link>
                </Col>
              </Row>
              <Row>
                {this.state.error ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}
              </Row>
            </Grid>
            <AgentTable agents={this.state.agents} />
          </div>
        </section>
        <div className="row">
          <div className="col-md-12 center">
            <Link
              to="/strataAdmin/agent/archived"
              className="btn btn-outline btn-primary text-uppercase margin-bottom">
              View Archives
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default AgentList;
