import React from 'react';
import ReactModal from 'react-modal';

const StrataDocuments = ({ open, openModal, documentUrl }) => {
  function closeModal(event) {
    event.stopPropagation();
    openModal('');
  }

  return (
    <div>
      <ReactModal
        className="custom-modal"
        isOpen={open}
        onRequestClose={closeModal}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        preventScroll={false}
        style={{
          overlay: {
            position: 'fixed',
            overflow: 'hidden',
            backgroundColor: 'rgba(255,255,255,0.55)'
          }
        }}
        contentLabel="Strata Form">
        <iframe
          className="iframe"
          id="strata-document"
          src={documentUrl}
          width="100%"
          height="93%"
          title="strata-document"
        />
        {/* eslint-disable-next-line react/button-has-type */}
        <button
          onClick={closeModal}
          className="btn btn-primary text-uppercase pull-right modal-button">
          Close
        </button>
      </ReactModal>
    </div>
  );
};

export default StrataDocuments;
