import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col, Button, Glyphicon } from 'react-bootstrap';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import PropertyTable from '../utils/dataTable/PropertyTable';
import OwnerTable from '../utils/dataTable/OwnerTable';
import ErrorAlert from '../utils/ErrorAlert';
import request from '../../utils/Request';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: '',
      properties: [],
      owners: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  componentDidMount() {
    this.loadProperties();
    this.loadOwners();
  }

  loadProperties() {
    request
      .get('/property')
      .then((properties) => {
        this.setState({
          error: false,
          properties: properties.map((p) => ({
            ...p,
            property_link: (
              <Link to={`/admin/property/${p.id}`}>{p.rmNumber}</Link>
            ),
            button: (
              <div className="text-center">
                <Link
                  className="btn btn-primary btn-xs"
                  style={{ marginRight: 5, marginLeft: 5 }}
                  to={`/admin/property/${p.id}`}>
                  <Glyphicon glyph="cog" />
                </Link>
                <Button
                  bsSize="xsmall"
                  bsStyle="danger"
                  style={{ marginRight: 5 }}
                  onClick={() => {
                    this.promptDeleteProperty(p.id);
                  }}>
                  <Glyphicon glyph="folder-open" />
                </Button>
              </div>
            )
          }))
        });
      })
      .catch(() => {
        toast('Could Not Load Properties.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  loadOwners() {
    request
      .get('/ownerprofile')
      .then((owners) => {
        this.setState({
          error: false,
          owners: owners.map((o) => ({
            ...o,
            owner_link: <Link to={`/admin/owner/${o.id}`}>{o.name}</Link>,
            email: o.user.email,
            delete_button: (
              <Button
                bsSize="xsmall"
                bsStyle="danger"
                onClick={() => {
                  this.promptDeleteOwner(o.id);
                }}>
                <Glyphicon glyph="folder-open" />
              </Button>
            )
          }))
        });
      })
      .catch(() => {
        toast('Could Not Load Owners.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  promptDeleteProperty(id) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to archive this?')) {
      request
        .archive(`/property/archive/${id}`)
        .then(() => {
          this.setState({ error: false });
          this.loadProperties();
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Something Went Wrong. Please try again Later.'
          });
        });
    }
  }

  promptDeleteOwner(id) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to archive this?')) {
      request
        .archive(`/ownerProfile/archive/${id}`)
        .then(() => {
          this.setState({ error: false });
          this.loadOwners();
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Something Went Wrong. Please try again Later.'
          });
        });
    }
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3>Administration</h3>
                </Col>
              </Row>
            </Grid>
            <PropertyTable properties={this.state.properties} />
            <Grid>
              <Row>
                <Col md={6} />
                <Col md={6}>
                  <Link
                    to="/admin/owner/add"
                    className="btn btn-primary btn-xs pull-right">
                    New Owner
                  </Link>
                </Col>
              </Row>
            </Grid>
            <OwnerTable owners={this.state.owners} />
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-md-12 center">
              <Link
                to="/admin/archive"
                className="btn btn-outline btn-primary text-uppercase margin-bottom">
                View Archives
              </Link>
            </div>
          </div>
          <Row>
            {this.state.error ? (
              <ErrorAlert errorMessage={this.state.errorMessage} />
            ) : null}
          </Row>
        </section>
      </div>
    );
  }
}

export default Dashboard;
