import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col, Glyphicon, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import ErrorAlert from '../../utils/ErrorAlert';
import UserTable from '../../utils/dataTable/UserTable';
import request from '../../../utils/Request';

class UserArchive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      roleList: ['Strata Administrator', 'Strata Owner'],
      error: false,
      errorMessage: ''
    };

    this.displayRoleName = (role) => {
      if (role === 'Strata Administrator') {
        return 'Administrator';
      }
      return role;
    };
  }

  componentDidMount() {
    this.loadUsers();
  }

  restoreUser(holder) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to restore this user?')) {
      request
        .archive(`/user/restore/${holder.userId}`)
        .then(() => {
          this.loadUsers();
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Something went Wrong. Please try again Later.'
          });
        });
    }
  }

  deleteUser(holder) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this user?')) {
      request
        .delete(`/user/delete/${holder.userId}`)
        .then(() => {
          this.loadUsers();
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Error creating new user. User may already exist.'
          });
        });
    }
  }

  loadUsers() {
    const userHolder = [];
    let roleQueryString = '';
    this.state.roleList.forEach((r) => {
      roleQueryString += `rolesList=${r.replace(/ /g, '%20')}&`;
    });
    request
      .get(`/User/archived/userAndProperty?${roleQueryString}`)
      .then((usersAndProperty) => {
        usersAndProperty.forEach((userAndProperty) => {
          const holder = {
            email: userAndProperty.user.userEmail,
            userName: userAndProperty.user.userName,
            role: this.displayRoleName(userAndProperty.user.roleName),
            userId: userAndProperty.user.userID,
            address: userAndProperty.strataProperty
              ? userAndProperty.strataProperty.address
              : 'no properties attached',
            button: (
              <div className="text-center">
                <Button
                  bsSize="xsmall"
                  bsStyle="success"
                  style={{ marginLeft: 5 }}
                  onClick={() => this.restoreUser(holder)}>
                  <Glyphicon glyph="open" />
                </Button>
                <Button
                  bsSize="xsmall"
                  bsStyle="danger"
                  style={{ marginRight: 5, marginLeft: 5 }}
                  onClick={() => this.deleteUser(holder)}>
                  <Glyphicon glyph="remove" />
                </Button>
                <Button
                  bsSize="xsmall"
                  bsStyle="primary"
                  style={{ marginRight: 5 }}
                  onClick={() =>
                    this.props.history.push(
                      `/strataAdmin/user/edit/${userAndProperty.user.userID}`
                    )
                  }>
                  <Glyphicon glyph="cog" />
                </Button>
              </div>
            )
          };
          userHolder.push(holder);
        });
        this.setState({
          users: userHolder
        });
      })
      .catch(() => {
        toast('Could Not Load Users.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Strata Administration Archived Properties</h3>
              </Col>
            </Row>
          </Grid>
          <UserTable users={this.state.users} />
          <div className="row">
            <div className="col-md-12 center">
              <Link
                to="/strataAdmin/user/list"
                className="btn btn-outline btn-primary text-uppercase margin-bottom">
                Users
              </Link>
            </div>
          </div>
          <div>
            {this.state.error ? (
              <ErrorAlert errorMessage={this.state.errorMessage} />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default UserArchive;
