import React, { Component } from 'react';
import { Button, Col, Glyphicon, Grid, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import request from '../../../utils/Request';
import StrataUnitUserTable from '../../utils/dataTable/StrataUnitUserTable';
import ErrorAlert from '../../utils/ErrorAlert';

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strataUnit: {},
      strataProperty: {},
      strataUsers: [],
      error: false,
      errorMessage: ''
    };

    this.getUnitRole = (id) => {
      switch (id) {
        case 0:
          return 'Current Primary User';
        case 1:
          return 'Current Secondary User';
        case 2:
          return 'Past Owner';
        case 3:
          return 'Current Tenant';
        case 4:
          return 'Past Tenant';
        case 5:
          return 'Defunct';
        default:
          return 'No Role Selected';
      }
    };

    this.compare = (a, b) => {
      const alpha = a.title;
      const beta = b.title;
      let comparison = 0;
      if (alpha < beta) {
        comparison = 1;
      } else if (alpha > beta) {
        comparison = -1;
      }
      return comparison;
    };

    this.icon = (header) => (
      <div>
        {header}{' '}
        <Glyphicon
          glyph="glyphicon glyphicon-plus"
          style={{ float: 'right' }}
        />
      </div>
    );
  }

  componentDidMount() {
    this.loadStrataUnit();
  }

  /*
   *  This takes in the strata unit id from the prop and returns the strata unit object
   * */
  loadStrataUnit() {
    const {
      match: { params }
    } = this.props;
    request.get(`/strataUnit/${params.id}`).then((unit) => {
      const users = this.getPrimaryUser(unit.strataUnitUser);
      this.setState({
        strataUnit: unit,
        strataProperty: unit.strataProperty,
        strataUsers: users
      });
    });
  }

  getPrimaryUser(unitUsers) {
    let returnName = {
      username: 'No Users Selected',
      phone: 'No Phone Number',
      role: 'No Users'
    };
    const returnArray = [];
    unitUsers.forEach((unit) => {
      returnName = {
        username: unit.user.userName,
        phoneNumber: !unit.user.phoneNumber
          ? 'No Phone Number'
          : unit.user.phoneNumber,
        firstName: unit.firstName,
        lastName: unit.lastName,
        email: unit.user.email,
        role: this.getUnitRole(unit.strataUnitRole),
        button: (
          <div className="text-center">
            <Button
              bsSize="xsmall"
              style={{ marginRight: 2.5 }}
              bsStyle="warning"
              onClick={() => {
                this.props.history.push({
                  pathname: `/strataAdmin/user/edit/strataUnit/${unit.user.id}`,
                  strataUnitUser: unit
                });
              }}>
              <Glyphicon glyph="edit" />
            </Button>
            <Button
              bsSize="xsmall"
              style={{ marginLeft: 2.5, marginRight: 2.5 }}
              bsStyle="primary"
              onClick={() => {
                this.props.history.push(
                  `/strataAdmin/user/edit/${unit.user.id}`
                );
              }}>
              <Glyphicon glyph="cog" />
            </Button>
          </div>
        )
      };
      returnArray.push(returnName);
    });
    return returnArray;
  }

  showDocument(id) {
    this.props.history.push({
      pathname: `/strataAdmin/document/view/${id}`,
      params: {
        documentFor: 'unit'
      }
    });
  }

  editDocument(id) {
    this.props.history.push({
      pathname: `/strataAdmin/strataUnitDocument/edit/${id}`
    });
  }

  /*
   * Archive the selected document
   * */
  archiveDocument(id) {
    const element = document.getElementById(id);
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'The document will be archived and moved to a different directory'
      )
    ) {
      request
        .put(`/strataunitdocument/archive/${id}`)
        .then(() => {
          element.parentNode.removeChild(element);
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Error archiving document.'
          });
        });
    }
  }

  removeUser(unit) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'The user will be deleted, change the status if you desire keeping a record of them'
      )
    ) {
      request
        .delete(`/strataUnitUser/delete`, unit)
        .then(() => {
          this.loadStrataUnit();
        })
        .catch(() => {
          this.setState({ error: true, errorMessage: 'Error deleting user.' });
        });
    }
  }

  render() {
    return (
      <div>
        <section className="section m-none">
          <Grid>
            <Row>
              <Col md={12}>
                <Link
                  className="btn btn-primary btn-sm pull-left"
                  style={{ marginRight: 10 }}
                  to={`/strataAdmin/property/${this.state.strataProperty.id}`}>
                  Back To Building
                </Link>
                <Link
                  className="btn btn-primary btn-sm pull-right"
                  style={{ marginRight: 10 }}
                  to={`/strataAdmin/user/add/strataUnit/${this.state.strataUnit.id}`}>
                  Add User
                </Link>
              </Col>
              <Col md={12}>
                <h3 style={{ marginBottom: 15, marginTop: 15 }}>
                  Strata Unit: {this.state.strataUnit.unitNumber} --{' '}
                  {this.state.strataProperty.buildingName}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h3 style={{ marginBottom: 15 }}>
                  Strata Lot Number: {this.state.strataUnit.lotNumber}{' '}
                </h3>
              </Col>
            </Row>
            <Row>
              <StrataUnitUserTable strataUsers={this.state.strataUsers} />
            </Row>
            <Row>
              {this.state.error ? (
                <ErrorAlert errorMessage={this.state.errorMessage} />
              ) : null}
            </Row>
          </Grid>
        </section>
      </div>
    );
  }
}

export default Detail;
