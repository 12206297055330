import React, { useState } from 'react';
import {
  Modal,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  Button
} from 'react-bootstrap';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

export default ({ owner, inputChange, show, closeModal }) => {
  const [error, setError] = useState(null);

  const update = async (event) => {
    event.preventDefault();
    try {
      await request.put(`/strataOwner/${owner.id}`, owner);
      window.location.reload();
      closeModal();
    } catch (e) {
      setError('Error updating the address');
    }
  };

  return (
    <Modal
      dialogClassName="custom-modal"
      size="lg"
      show={show}
      onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title> Change of Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>Address Line One </ControlLabel>
              <FormControl
                name="addressLine1"
                type="text"
                placeholder="Address Line 1"
                value={owner.addressLine1}
                onChange={inputChange}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <ControlLabel>Address Line Two </ControlLabel>
              <FormControl
                name="addressLine2"
                type="text"
                placeholder="Address Line 2"
                value={owner.addressLine2}
                onChange={inputChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>Address Line Three </ControlLabel>
              <FormControl
                name="addressLine3"
                type="text"
                placeholder="Address Line 3"
                value={owner.addressLine3}
                onChange={inputChange}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <ControlLabel>Country </ControlLabel>
              <FormControl
                name="country"
                type="text"
                placeholder="Country"
                value={owner.country}
                onChange={inputChange}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <ControlLabel>State Or Province </ControlLabel>
              <FormControl
                name="stateOrProvince"
                type="text"
                placeholder="State or Province"
                value={owner.stateOrProvince}
                onChange={inputChange}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <ControlLabel>City </ControlLabel>
              <FormControl
                name="city"
                type="text"
                placeholder="City"
                value={owner.city}
                onChange={inputChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>Postal Code </ControlLabel>
              <FormControl
                name="postalCode"
                type="text"
                placeholder="Postal Code"
                value={owner.postalCode}
                onChange={inputChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="primary"
          bsSize="large"
          block
          type="submit"
          onClick={update}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
