import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import Datatable from 'react-bs-datatable';

// Testing out datatables
const header =
  localStorage.getItem('role') === 'strataAdmin'
    ? [
        {
          title: 'Unit',
          prop: 'property_link',
          sortable: true,
          filterable: true
        },
        {
          title: 'Strata Plan #',
          prop: 'strataPlan',
          sortable: true,
          filterable: true
        },
        {
          title: 'Strata Lot #',
          prop: 'lotNumber',
          sortable: true,
          filterable: true
        },
        {
          title: 'Name on Title',
          prop: 'nameOnTitle',
          sortable: true,
          filterable: true
        },
        {
          title: 'Confirmed',
          prop: 'confirmed',
          sortable: true,
          filterable: true
        },
        { title: 'Action', prop: 'button', sortable: false }
      ]
    : [
        {
          title: 'Unit',
          prop: 'property_link',
          sortable: true,
          filterable: true
        },
        {
          title: 'Strata Plan #',
          prop: 'strataPlan',
          sortable: true,
          filterable: true
        },
        {
          title: 'Strata Lot #',
          prop: 'lotNumber',
          sortable: true,
          filterable: true
        },
        {
          title: 'Name on Title',
          prop: 'nameOnTitle',
          sortable: true,
          filterable: true
        }
      ];

const onSortFunction = {
  date(columnValue) {
    // Convert the string date format to UTC timestamp
    // So the table could sort it by number instead of by string
    return new Date(columnValue);
  }
};

const StrataUnitPropertyTable = (props) => (
  <div>
    <Grid>
      <Row>
        <Col md={12}>
          <h5>Strata Units</h5>
        </Col>
      </Row>
    </Grid>
    <Grid>
      <Row>
        <Col md={12}>
          <Datatable
            tableHeader={header}
            tableBody={props.strataUnits}
            keyName="strataUnitTable"
            tableClass="table table-striped table-bordered table-condensed table-hover"
            rowsPerPage={10}
            rowsPerPageOption={[5, 10, 15, 20, 50, 100]}
            initialSort={{ prop: 'date', isAscending: true }}
            onSort={onSortFunction}
          />
        </Col>
      </Row>
    </Grid>
  </div>
);

export default StrataUnitPropertyTable;
