import React, { useState } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import request from '../../../utils/Request';
import StrataPropertyAgentForm from '../forms/StrataAgent';
import ErrorAlert from '../../utils/ErrorAlert';

export default ({ history }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [strataAgent, setStrataAgent] = useState({
    email: '',
    name: '',
    phone: ''
  });
  const [valid, setValid] = useState(false);

  const create = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const result = await request.post(`/strataAgent`, strataAgent);
      if (result) {
        setLoading(false);
        history.push(`/strataAdmin/dashboard`);
      } else {
        throw Error(`Error creating new agent${result}${result.id}`);
      }
    } catch (e) {
      setLoading(false);
      if (e) {
        setError(e.message);
      } else {
        setError('Error creating new agent. Agent may already exist.');
      }
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <StrataPropertyAgentForm
            agent={strataAgent}
            setAgent={setStrataAgent}
            setValid={setValid}
          />
          <Grid>
            <Row>
              <Col md={12}>
                {!loading && (
                  <Button
                    bsStyle="primary"
                    bsSize="large"
                    block
                    type="submit"
                    onClick={create}
                    disabled={!valid}>
                    Create Agent
                  </Button>
                )}
                {loading && <div className="loader" />}
              </Col>
            </Row>
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
