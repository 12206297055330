import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col, Button, Glyphicon } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import StrataPropertyTable from '../utils/dataTable/StrataPropertyTable';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import request from '../../utils/Request';
import ErrorAlert from '../utils/ErrorAlert';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      agents: [],
      error: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.loadAgents();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.agents !== this.state.agents) {
      this.loadProperties();
    }
  }

  deleteProperty(id) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to archive this?')) {
      request
        .archive(`/strataproperty/archive/${id}`)
        .then(() => {
          this.loadProperties();
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Something went Wrong. Please try again Later.'
          });
        });
    }
  }

  loadAgents() {
    request
      .get('/strataAgent')
      .then((res) => {
        this.setState({ agents: res });
      })
      .catch(() => {
        toast('Could Not Load Strata Agents', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  getStrataAgentName(id) {
    const a = this.state.agents;

    if (a === undefined || a.length === 0) {
      return id;
    }
    if (id === undefined || id == null) {
      return 'No Agent';
    }

    for (let i = 0; i < a.length; i += 1) {
      if (a[i].id === id) {
        return a[i].name;
      }
    }

    return 'No Agent';
  }

  getStrataAgentPhone(id) {
    const a = this.state.agents;

    if (a === undefined || a.length === 0) {
      return id;
    }
    if (id === undefined || id == null) {
      return 'No Agent';
    }

    for (let i = 0; i < a.length; i += 1) {
      if (a[i].id === id) {
        return a[i].phone;
      }
    }

    return 'No Agent';
  }

  loadProperties() {
    request
      .get('/strataproperty')
      .then((properties) => {
        this.setState({
          properties: properties.map((p) => ({
            ...p,
            property_link: (
              <Link to={`/strataAdmin/property/${p.id}`}>{p.buildingName}</Link>
            ),
            plan: p.strataPlanNumber,
            agent: this.getStrataAgentName(p.agentId),
            agent_phone: this.getStrataAgentPhone(p.agentId),
            manager: p.buildingManagerName,
            manager_phone: p.buildingManagerPhone,
            button: (
              <div className="text-center">
                <Button
                  bsSize="xsmall"
                  style={{ marginRight: 2.5 }}
                  bsStyle="warning"
                  onClick={() => {
                    this.deleteProperty(p.id);
                  }}>
                  <Glyphicon glyph="remove" />
                </Button>
                <Link
                  className="btn btn-primary btn-xs"
                  style={{ marginLeft: 2.5 }}
                  to={`/strataAdmin/edit/${p.id}`}>
                  <Glyphicon glyph="cog" />
                </Link>
              </div>
            )
          }))
        });
      })
      .catch(() => {
        toast('Could not load Properties.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3>Strata Administration</h3>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Col md={6} />
                <Col md={6}>
                  <Link
                    className="btn btn-primary btn-xs"
                    style={{ float: 'right' }}
                    to="/strataAdmin/property/add">
                    Add Property
                  </Link>
                  <Link
                    className="btn btn-primary btn-xs"
                    style={{ marginRight: 10, float: 'right' }}
                    to="/strataAdmin/agent/add">
                    Add Agent
                  </Link>
                </Col>
              </Row>
              <Row>
                {this.state.error ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}
              </Row>
            </Grid>

            <StrataPropertyTable properties={this.state.properties} />
            <div className="row">
              <div className="col-md-12 center">
                <Link
                  to="/strataAdmin/archived"
                  className="btn btn-outline btn-primary text-uppercase margin-bottom">
                  View Archives
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Dashboard;
