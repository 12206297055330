/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';
import PublicLayout from '../layout/public/PublicLayout';

export default (props) => (
  <PublicLayout>
    <Route {...props} />
  </PublicLayout>
);
