import React, { useState } from 'react';
import { Button, Col, Grid, Row } from 'react-bootstrap';
import request from '../../../utils/Request';
import StrataPropertyStaffForm from '../forms/StrataStaff';
import ErrorAlert from '../../utils/ErrorAlert';

export default ({ history, match }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [strataStaff, setStrataStaff] = useState({
    position: '',
    email: '',
    name: '',
    phone: '',
    strataPropertyId: '',
    startTimeMonday: '',
    endTimeMonday: '',
    startTimeTuesday: '',
    endTimeTuesday: '',
    startTimeWednesday: '',
    endTimeWednesday: '',
    startTimeThursday: '',
    endTimeThursday: '',
    startTimeFriday: '',
    endTimeFriday: '',
    startTimeSaturday: '',
    endTimeSaturday: '',
    startTimeSunday: '',
    endTimeSunday: ''
  });
  const [valid, setValid] = useState(false);

  const create = async (event) => {
    event.preventDefault();
    setLoading(true);

    const monday = strataStaff.startTimeMonday
      ? `${strataStaff.startTimeMonday} - ${strataStaff.endTimeMonday}`
      : 'off';

    const tuesday = strataStaff.startTimeTuesday
      ? `${strataStaff.startTimeTuesday} - ${strataStaff.endTimeTuesday}`
      : 'off';

    const wednesday = strataStaff.startTimeWednesday
      ? `${strataStaff.startTimeWednesday} - ${strataStaff.endTimeWednesday}`
      : 'off';

    const thursday = strataStaff.startTimeThursday
      ? `${strataStaff.startTimeThursday} - ${strataStaff.endTimeThursday}`
      : 'off';

    const friday = strataStaff.startTimeFriday
      ? `${strataStaff.startTimeFriday} - ${strataStaff.endTimeFriday}`
      : 'off';

    const saturday = strataStaff.startTimeSaturday
      ? `${strataStaff.startTimeSaturday} - ${strataStaff.endTimeSaturday}`
      : 'off';

    const sunday = strataStaff.startTimeSunday
      ? `${strataStaff.startTimeSunday} - ${strataStaff.endTimeSunday}`
      : 'off';

    const data = {
      ...strataStaff,
      strataPropertyId: match.params.id,
      hours: [
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday
      ].join(', ')
    };

    try {
      const res = await request.post(`/stratapropertystaff`, data);
      setLoading(false);

      if (res) {
        history.push(`/strataAdmin/property/${match.params.id}`);
      } else {
        setError(`Error creating new user${res}${res.id}`);
      }
    } catch (e) {
      setLoading(false);
      setError('Error creating new user. User may already exist.');
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <StrataPropertyStaffForm
            strataStaff={strataStaff}
            setStrataStaff={setStrataStaff}
            setValid={setValid}
          />
          <Grid>
            <Row>
              <Col md={12}>
                {!loading && (
                  <Button
                    bsStyle="primary"
                    bsSize="large"
                    block
                    type="submit"
                    disabled={!valid}
                    onClick={create}>
                    Add Staff to Property
                  </Button>
                )}
                {loading && <div className="loader" />}
              </Col>
            </Row>
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
