import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

const Detail = () => {
  <div className="wrap">
    <section className="section section-parallax parallax custom-parallax-medium-padding m-none">
      <div>
        <Grid>
          <Row>
            <Col md={12}>
              <h3>Listing Details</h3>
            </Col>
          </Row>
        </Grid>
      </div>
    </section>
  </div>;
};

export default Detail;
