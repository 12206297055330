import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Checkbox,
  Button,
  InputGroup
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import Promise from 'bluebird';
import ReactQuill from 'react-quill';
import GridLayout from 'react-grid-layout';
import cuid from 'cuid';
import Dropzone from '../../utils/Dropzone';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';
import deleteButton from '../../../images/KoreckiImages/close.png';

const api = process.env.REACT_APP_SITE_API;

class Edit extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    this.state = {
      loading: false,
      error: false,
      errorMessage: '',
      listing: {
        description: '',
        text: '',
        price: '0.00',
        deposit: 0.0,
        moveInFee: false,
        moveInDeposit: false,
        moveOutFee: false,
        available: false,
        recentlyRented: false,
        furnished: false,
        propertyId: match.params.id
      },
      images: [],
      newImages: [],
      imagesToDelete: []
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.addToDeleteList = this.addToDeleteList.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.update = this.update.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.uploadImages = this.uploadImages.bind(this);

    this.notify = () => {
      toast('Listing Saved', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: css({
          background: 'black'
        })
      });
    };
  }

  getValidateStates(name) {
    const currency = /^\d+(?:\.\d{0,2})$/;
    const wholenumber = /^\d+$/;
    switch (name) {
      case 'price':
        if (
          currency.test(this.state.listing.price) ||
          wholenumber.test(this.state.listing.price)
        ) {
          return 'success';
        }
        return 'error';
      default:
        return null;
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      listing: { ...prevState.listing, [name]: value }
    }));
  }

  handleTextChange(content) {
    this.setState((prevState) => ({
      listing: { ...prevState.listing, text: content }
    }));
  }

  componentDidMount() {
    this.loadListing()
      .then(() => this.loadImages())
      .catch((err) => {
        this.setState({ error: true, errorMessage: err.message });
      });
  }

  loadListing() {
    const { match } = this.props;
    return request
      .get(`/propertyListing/${match.params.id}`)
      .then((listing) => {
        this.setState({ listing });
      });
  }

  loadImages() {
    const { match } = this.props;
    return request
      .get(`/propertyListingImage/${match.params.id}`)
      .then((images) => {
        images.sort((a, b) => a.sort - b.sort);
        this.setState({ images });
      });
  }

  deleteImages() {
    const { match } = this.props;
    const listingId = match.params.id;

    const deletes = this.state.imagesToDelete.map((id) =>
      request.delete(`/PropertyListingImage/delete/${id}/${listingId}`)
    );

    return Promise.all(deletes);
  }

  update(e) {
    e.preventDefault();
    this.setState({ loading: true, error: false });
    document.getElementById('submit').disabled = true;
    const { match } = this.props;
    const data = this.state.listing;
    request
      .put(`/propertyListing/${match.params.id}`, data)
      .then(() => this.saveImages(match.params.id))
      .then(() => this.setState({ loading: false, error: false }))
      .catch((err) => {
        this.setState({
          loading: false,
          error: true,
          errorMessage: err.message
        });
      });
  }

  saveImages(paramsId) {
    const uploads = this.state.images.map((file, index) => {
      const sortOrder =
        this.state.images.length - this.state.imagesToDelete.length + index;

      return request.upload(
        `/PropertyListingImage/${paramsId}`,
        file.blob,
        sortOrder
      );
    });

    return Promise.all(uploads).then(() => this.updateSortOrder());
  }

  updateSortOrder() {
    const updates = this.state.images.map((image, index) =>
      request.put('/PropertyListingImage/updateSortOrder', {
        ...image,
        sort: index
      })
    );

    return Promise.all(updates)
      .then(() => {
        if (this.state.imagesToDelete.length) {
          this.deleteImages();
        }
      })
      .then(() => this.loadImages())
      .then(() => this.notify())
      .then(() => {
        this.props.history.push(
          `/admin/property/${this.state.listing.propertyId}`
        );
      });
  }

  onDrop(acceptedFiles) {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.setState((prevState) => ({
          // eslint-disable-next-line react/no-access-state-in-setstate
          newImages: [
            ...prevState.newImages,
            { id: cuid(), src: e.target.result, blob: file }
          ]
        }));
      };
      reader.readAsDataURL(file);
      return file;
    });
  }

  onLayoutChange(givenLayouts) {
    // use layout order to change sort order
    const newImageOrder = givenLayouts
      .sort((a, b) => {
        if (a.y === b.y) {
          return a.x - b.x;
        }
        return a.y - b.y;
      })
      .map((item) =>
        this.state.images.find(
          (file) => file.id.toString() === item.i.toString()
        )
      );

    this.setState(() => ({
      images: newImageOrder
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  uploadImages() {
    const { match } = this.props;
    const uploads = this.state.newImages.map((file, index) =>
      request.upload(
        `/PropertyListingImage/${match.params.id}`,
        file.blob,
        index
      )
    );

    this.setState(() => ({
      newImages: []
    }));

    return Promise.all(uploads).then(() => this.loadImages());
  }

  addToDeleteList(id) {
    document.getElementById(id).style.display = 'none';

    this.setState((prevState) => ({
      imagesToDelete: [...prevState.imagesToDelete, id]
    }));
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3>Edit Listing</h3>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Col md={12}>
                  <Form>
                    <FormGroup>
                      <ControlLabel>Description</ControlLabel>
                      <FormControl
                        name="description"
                        type="text"
                        placeholder="Description"
                        value={this.state.listing.description}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                    <ReactQuill
                      name="text"
                      value={this.state.listing.text}
                      onChange={this.handleTextChange}
                    />

                    <FormGroup
                      validationState={this.getValidateStates('price')}>
                      <ControlLabel>Price</ControlLabel>
                      <InputGroup>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <FormControl
                          name="price"
                          type="text"
                          placeholder="Price"
                          value={this.state.listing.price}
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Options</ControlLabel>
                      <Checkbox
                        name="available"
                        value={this.state.listing.available}
                        checked={this.state.listing.available}
                        onChange={this.handleInputChange}>
                        Available
                      </Checkbox>

                      <Checkbox
                        name="furnished"
                        value={this.state.listing.furnished}
                        checked={this.state.listing.furnished}
                        onChange={this.handleInputChange}>
                        Furnished
                      </Checkbox>

                      <Checkbox
                        name="recentlyRented"
                        value={this.state.listing.recentlyRented}
                        checked={this.state.listing.recentlyRented}
                        onChange={this.handleInputChange}>
                        Recently Rented
                      </Checkbox>
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Display</ControlLabel>

                      <Checkbox
                        name="moveInFee"
                        value={this.state.listing.moveInFee}
                        checked={this.state.listing.moveInFee}
                        onChange={this.handleInputChange}>
                        Move In Fee
                      </Checkbox>

                      <Checkbox
                        name="moveInDeposit"
                        value={this.state.listing.moveInDeposit}
                        checked={this.state.listing.moveInDeposit}
                        onChange={this.handleInputChange}>
                        Move In Deposit
                      </Checkbox>

                      <Checkbox
                        name="moveOutFee"
                        value={this.state.listing.moveOutFee}
                        checked={this.state.listing.moveOutFee}
                        onChange={this.handleInputChange}>
                        Move Out Fee
                      </Checkbox>
                    </FormGroup>
                    <Row>
                      <GridLayout
                        rowHeight={100}
                        width={1170}
                        onLayoutChange={this.onLayoutChange}
                        isResizable="false"
                        className="react-grid-layout">
                        {this.state.images.map((file, index) => (
                          <div
                            id={file.id}
                            key={file.id}
                            data-grid={{
                              x: (index % 4) * 3,
                              y: Math.floor(index / 4) * 2,
                              w: 3,
                              h: 2
                            }}>
                            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                            <img
                              src={deleteButton}
                              alt=""
                              style={{
                                position: 'absolute',
                                right: 10,
                                width: 20
                              }}
                              onClick={() => this.addToDeleteList(file.id)}
                              onKeyPress={() => this.addToDeleteList(file.id)}
                            />
                            <img
                              width="100%"
                              src={api + file.originalPath}
                              alt=""
                              className="scale-image"
                              draggable={false}
                            />
                          </div>
                        ))}
                      </GridLayout>
                    </Row>
                    <hr className="solid" />
                    <Row>
                      <h3>Upload New Images</h3>
                      <FormGroup>
                        <GridLayout
                          rowHeight={100}
                          width={1170}
                          isDraggable={false}
                          compactType="horizontal"
                          isResizable="false"
                          className="react-grid-layout">
                          {this.state.newImages &&
                            this.state.newImages.map((file, index) => (
                              <div
                                id={file.id}
                                key={file.id}
                                data-grid={{ x: index, y: 0, w: 3, h: 2 }}>
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                                <img
                                  className="scale-image"
                                  src={file.src}
                                  alt=""
                                />
                              </div>
                            ))}
                        </GridLayout>
                        <Dropzone onDrop={this.onDrop} />
                        <div className="margin-5">
                          <Button
                            className="button-align-right"
                            onClick={this.uploadImages}>
                            Upload
                          </Button>
                        </div>
                      </FormGroup>
                    </Row>
                    {this.state.loading === false ? (
                      <Button id="submit" type="submit" onClick={this.update}>
                        Submit
                      </Button>
                    ) : (
                      <div className="loader" />
                    )}
                  </Form>
                  <Row>
                    {this.state.error ? (
                      <ErrorAlert errorMessage={this.state.errorMessage} />
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}
export default Edit;
