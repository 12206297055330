import React, { Component } from 'react';
import { Grid, Row, Col, Glyphicon, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { css } from 'glamor';
import ErrorAlert from '../../utils/ErrorAlert';
import UserTable from '../../utils/dataTable/UserTable';
import request from '../../../utils/Request';

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      roleList: ['Strata Administrator', 'Strata Owner'],
      error: false,
      errorMessage: ''
    };
    this.archiveUser = this.archiveUser.bind(this);

    this.displayRoleName = (role) => {
      if (role === 'Strata Administrator') {
        return 'Administrator';
      }
      return role;
    };
  }

  componentDidMount() {
    this.loadUsers();
  }

  archiveUser(user) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to archive this user?')) {
      if (user.propertyId === 0 || user.propertyId === undefined) {
        request
          .archive(`/user/archive/${user.userId}`)
          .then(() => {
            this.loadUsers();
          })
          .catch(() => {
            this.setState({
              error: true,
              errorMessage: 'Error disabling user.'
            });
          });
      } else {
        let updating;
        request
          .get(`/strataProperty/${user.propertyId}`)
          .then((property) => {
            updating = property;
            updating.propertyAdminId = null;
            return updating;
          })
          .then((updated) => {
            request
              .put(`/strataProperty/${updated.id}`, updated)
              .then(() => {})
              .catch(() =>
                this.setState({
                  error: true,
                  errorMessage: 'Error removing user from property.'
                })
              );
          })
          .then(
            request
              .archive(`/user/archive/${user.userId}`)
              .then(() => {
                this.loadUsers();
              })
              .catch(() => {
                this.setState({
                  error: true,
                  errorMessage: 'Error disabling user.'
                });
              })
          );
      }
    }
  }

  loadUsers() {
    const userHolder = [];
    let roleQueryString = '';
    this.state.roleList.forEach((r) => {
      roleQueryString += `rolesList=${r.replace(/ /g, '%20')}&`;
    });
    request
      .get(`/User/userAndProperty?${roleQueryString}`)
      .then((usersAndProperty) => {
        usersAndProperty.forEach((userAndProperty) => {
          const holder = {
            email: userAndProperty.user.userEmail,
            userName: userAndProperty.user.userName,
            role: this.displayRoleName(userAndProperty.user.roleName),
            userId: userAndProperty.user.userID,
            address: userAndProperty.strataProperty
              ? userAndProperty.strataProperty.address
              : 'no properties attached',
            button: (
              <div className="text-center">
                <Button
                  bsSize="xsmall"
                  bsStyle="warning"
                  style={{ marginRight: 5, marginLeft: 5 }}
                  onClick={() => this.archiveUser(holder)}>
                  <Glyphicon glyph="remove" />
                </Button>
                <Button
                  bsSize="xsmall"
                  bsStyle="primary"
                  style={{ marginRight: 5 }}
                  onClick={() =>
                    this.props.history.push(
                      `/strataAdmin/user/edit/${userAndProperty.user.userID}`
                    )
                  }>
                  <Glyphicon glyph="cog" />
                </Button>
              </div>
            )
          };
          userHolder.push(holder);
        });
        this.setState({
          users: userHolder
        });
      })
      .catch(() => {
        toast('Could Not Load Properties.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3>Strata Users</h3>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Col md={6} />
                <Col md={6}>
                  <Link
                    className="btn btn-primary btn-xs pull-right"
                    to="/strataAdmin/user/add">
                    Add User
                  </Link>
                </Col>
              </Row>
              <Row>
                {this.state.error ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}
              </Row>
            </Grid>
            <UserTable users={this.state.users} />
          </div>
        </section>
        <div className="row">
          <div className="col-md-12 center">
            <Link
              to="/strataAdmin/user/archived"
              className="btn btn-outline btn-primary text-uppercase margin-bottom">
              View Archives
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default UserList;
