/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from '../../utils/Auth';
import StrataUnitOwnerLayout from '../layout/strataUnitOwner/StrataUnitOwnerLayout';

export default (props) => (
  <>
    {Auth.isAuthenticated(['strataUnitOwner']) ? (
      <StrataUnitOwnerLayout>
        <Route {...props} />
      </StrataUnitOwnerLayout>
    ) : (
      <Redirect to="/" />
    )}
  </>
);
