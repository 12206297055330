import React, { Component } from 'react';
import { Grid, Row, Col, Table } from 'react-bootstrap';
import moment from 'moment';
import request from '../../../utils/Request';
import DocumentTable from '../../utils/dataTable/DocumentTable';
import DetailsTable from '../../utils/property/DetailsTable';
import StrataCompanyTable from '../../utils/property/StrataCompanyTable';

class Detail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      property: {},
      listings: [],
      documents: [],
      filterOptions: {
        documentTypeId: '2',
        month: '1',
        year: '2018'
      }
    };

    this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.pdfLocation = (path) => {
      if (path == null) return path;
      // was 76
      return path.substring(85);
    };

    this.available = (listing) => {
      if (listing.available) {
        return 'Yes';
      }
      return 'No';
    };
  }

  componentDidMount() {
    this.loadProperty();
    this.loadDirectories();
    this.loadListings();
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleFilterInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      filterOptions: { ...prevState.filterOptions, [name]: value }
    }));
  }

  loadProperty() {
    const {
      match: { params }
    } = this.props;
    request.get(`/property/${params.id}`).then((property) => {
      this.setState({
        property
      });
    });
  }

  loadDirectories() {
    const {
      match: { params }
    } = this.props;
    request
      .get(`/documents/property/${params.id}/all`)
      .then((documents) => {
        this.setState({
          documents: documents.map((d) => ({
            ...d,
            type: d.documentType.name,
            date: moment(d.documentDate).format('MMMM YYYY'),
            link: (
              <a
                href={`${process.env.REACT_APP_SITE_API}/api/documents/${d.id}/file`}
                download={d.Title}>
                Download File
              </a>
            )
          }))
        });
      })
      .catch(() => {});
  }

  loadListings() {
    const {
      match: { params }
    } = this.props;
    request.get(`/propertylisting/list/${params.id}`).then((listings) => {
      this.setState({
        listings
      });
    });
  }

  render() {
    return (
      <div>
        <DetailsTable property={this.state.property} />

        <StrataCompanyTable property={this.state.property} />

        <section className="section m-top">
          <Grid>
            <Row>
              <Col md={6}>
                <h5>Listings</h5>
              </Col>

              <Col md={12}>
                <Table striped bordered condensed hover>
                  <thead>
                    <tr>
                      <td>Description</td>
                      <td>Price</td>
                      <td>Available</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state &&
                      this.state.listings &&
                      this.state.listings.map((listing) => (
                        <tr>
                          <td>{listing.description}</td>
                          <td>{`$${listing.price}.00`}</td>
                          <td>{this.available(listing)}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Grid>
        </section>

        <section className="section m-top">
          <DocumentTable documents={this.state.documents} hideAction />
        </section>
      </div>
    );
  }
}
export default Detail;
