import React from 'react';
import ReactDOM from 'react-dom';
import './assets/theme/css/theme-elements.css';
import './assets/theme/css/theme-blog.css';
import './assets/theme/css/skins/skin-finance.css';
import './assets/theme/css/demo/demo-finance.css';
import './css/index.css';
import App from './components/App';
import { unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
unregister();
