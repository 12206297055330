import React from 'react';
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Row
} from 'react-bootstrap';

export default ({ PAD, setPAD }) => {
  const onChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setPAD((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h1>Pre-Authorized Debit Agreement for Strata Fees</h1>
              </Col>
            </Row>
            <Row>
              <p>
                Please note that this form must be received by Korecki Real
                Estate Services Inc., no later than the 20th of the month prior
                to the month the PAD is to commence. (i.e. to be on the PAD plan
                for the month of July the form must be received by June 20th)
              </p>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <h3>Pre Authorized Debit Form</h3>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Financial Institution Name</ControlLabel>
                  <FormControl
                    name="financialInstitutionName"
                    type="text"
                    placeholder="Financial Institution Name"
                    value={PAD.financialInstitutionName}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Financial Institution Address</ControlLabel>
                  <FormControl
                    name="financialInstitutionAddress"
                    type="text"
                    placeholder="Financial Institution Address"
                    value={PAD.financialInstitutionAddress}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>City</ControlLabel>
                  <FormControl
                    name="financialInstitutionCity"
                    type="text"
                    placeholder="City"
                    value={PAD.financialInstitutionCity}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Province</ControlLabel>
                  <FormControl
                    name="financialInstitutionProvince"
                    type="text"
                    placeholder="Province"
                    value={PAD.financialInstitutionProvince}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <h4> Bank Account Information</h4>
            </Row>
            <Row>
              <Col md={2}>
                <FormGroup>
                  <ControlLabel>Bank Transit</ControlLabel>
                  <FormControl
                    name="bankTransitNumber"
                    type="text"
                    placeholder="Transit"
                    value={PAD.bankTransitNumber}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <ControlLabel>Institution</ControlLabel>
                  <FormControl
                    name="institutionNumber"
                    type="text"
                    placeholder="Institution"
                    value={PAD.institutionNumber}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md={8}>
                <FormGroup>
                  <ControlLabel>Account Number</ControlLabel>
                  <FormControl
                    name="accountNumber"
                    type="text"
                    placeholder="Account Number"
                    value={PAD.accountNumber}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>Account Holder Name</ControlLabel>
                  <FormControl
                    name="accountHolderFirstAndLastName"
                    type="text"
                    placeholder="Account Holder Name"
                    value={PAD.accountHolderFirstAndLastName}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
