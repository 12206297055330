import React, { useEffect, useState } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import request from '../../../utils/Request';
import StrataPropertyAgentForm from '../forms/StrataAgent';
import ErrorAlert from '../../utils/ErrorAlert';

export default ({ match, history }) => {
  const [error, setError] = useState(null);
  const [strataAgent, setStrataAgent] = useState(null);
  const [valid, setValid] = useState(null);

  // initial load for agent
  useEffect(() => {
    const getAgent = async () => {
      const result = await request.get(`/strataagent/${match.params.id}`);
      if (result) {
        setStrataAgent(result);
      } else {
        setError('Error retrieving agent');
      }
    };

    if (!strataAgent && !error) {
      getAgent();
    }
  }, [error, match.params.id, strataAgent]);

  const update = async (event) => {
    event.preventDefault();

    try {
      const result = await request.put(
        `/strataAgent/${strataAgent.id}`,
        strataAgent
      );

      if (result) {
        history.push(`/strataAdmin/agent/list`);
      } else {
        setError(`Error updating new agent${result}${result.id}`);
      }
    } catch (e) {
      setError('Error updating new agent. Agent may already exist.');
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          {strataAgent && (
            <StrataPropertyAgentForm
              agent={strataAgent}
              setAgent={setStrataAgent}
              setValid={setValid}
              edit
            />
          )}
          <Grid>
            <Row>
              <Col md={12}>
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  block
                  type="submit"
                  disabled={!valid}
                  onClick={update}>
                  Update
                </Button>
              </Col>
            </Row>
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
