import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Button,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Glyphicon,
  Grid,
  Row
} from 'react-bootstrap';
import Dropzone from '../../utils/Dropzone';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

class StrataUnitDocumentEdit extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      error: false,
      errorMessage: '',
      id: params.id,
      form: {
        id: params.id,
        fileName: 'defualt',
        documentDate: moment(),
        path: 'PDF_DOCUMENT',
        strataUnitId: null,
        strataUnitDocumentCategoryId: null
      },
      categoryHolderArray: [],
      file: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.loadCategories();
    this.loadDocument();
  }

  save() {
    const data = this.state.form;
    if (this.state.file) {
      request
        .upload2(`/StrataUnitDocument/upload/${data.id}`, this.state.file)
        .then((res) => {
          // Set the fileName with the new uploaded form, new title already set on file select, onDrop
          this.setState((prevState) => ({
            form: { ...prevState.form, fileName: res.fileName }
          }));
          this.update(data); // update document with any other changed info
        })
        .catch(() => {
          this.setState({ error: true, errorMessage: 'Error uploading file.' });
        });
    } else {
      this.update(data); // If there is no file getting re-uploaded
    }
  }

  update(data) {
    request
      .put(`/StrataUnitDocument/update/${data.id}`, data)
      .then(() => {
        this.props.history.push(`/strataAdmin/strataUnit/${data.strataUnitId}`);
      })
      .catch(() => {
        this.setState({
          error: true,
          errorMessage: `Error updating document: ${data.title}`
        });
      });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'select' ? target.selected : target.value;
    const { name } = target;

    this.setState((prevState) => ({
      form: { ...prevState.form, [name]: parseInt(value, 10) }
    }));
  }

  onDrop(acceptedFiles) {
    const form = { ...this.state.form };
    if (acceptedFiles.length > 1) {
      this.setState({
        error: true,
        errorMessage:
          'Too many files selected, please only choose one file to re-upload'
      });
    } else {
      form.title = acceptedFiles[0].name;
      this.setState({ file: acceptedFiles[0], form });
    }
  }

  handleDateChange(date) {
    this.setState((prevState) => ({
      form: { ...prevState.form, documentDate: date }
    }));
  }

  resetForm() {
    this.loadDocument();
    this.setState({ file: null });
  }

  loadDocument() {
    let form = { ...this.state.form };
    if (this.state.id) {
      request.get(`/StrataUnitDocument/${this.state.id}`).then((document) => {
        form = {
          id: document.id,
          strataUnitId: document.strataUnitId,
          strataUnitDocumentCategoryId: document.strataUnitDocumentCategoryId,
          fileName: document.fileName,
          documentDate: moment(document.documentDate),
          path: document.path,
          title: document.title,
          description: document.description
        };
        this.setState({ form });
      });
    }
  }

  loadCategories() {
    const categoryArray = [];
    request.get('/StrataUnitDocumentCategory').then((categories) => {
      categories.forEach((category) => {
        const cat = {
          id: category.id,
          name: category.name
        };
        categoryArray.push(cat);
      });
      this.setState({
        categoryHolderArray: categoryArray
      });
    });
  }

  showDocTypeOptions() {
    const listOfCategories = this.state.categoryHolderArray.map((item) => (
      <option value={parseInt(item.id, 10)}>{item.name}</option>
    ));
    return (
      <FormGroup>
        <ControlLabel>Type of Document</ControlLabel>
        <FormControl
          componentClass="select"
          id="strataUnitDocumentCategoryId"
          placeholder="select"
          name="strataUnitDocumentCategoryId"
          value={this.state.form.strataUnitDocumentCategoryId}
          onChange={this.handleInputChange}>
          <option value="0">Choose one:</option>
          {listOfCategories}
        </FormControl>
      </FormGroup>
    );
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col md={12}>
            <h1 style={{ fontSize: 20 }}>Update Document</h1>
            <Form>
              {this.showDocTypeOptions()}
              <FormGroup>
                <ControlLabel style={{ marginRight: 10 }}>
                  Document Date
                </ControlLabel>
                <DatePicker
                  selected={this.state.form.documentDate}
                  onChange={this.handleDateChange}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>File Uploaded</ControlLabel>
                <Dropzone
                  onDrop={this.onDrop}
                  className="dropzone"
                  accept="application/pdf"
                  maxfiles={1}>
                  <p>
                    Current file: {this.state.form.title}{' '}
                    <Glyphicon glyph="glyphicon glyphicon-file" />
                  </p>
                  <p>Drag & drop or select, to replace current file.</p>
                </Dropzone>
              </FormGroup>
              <Button
                bsStyle="danger"
                bsSize="large"
                blocktype="submit"
                style={{ marginBottom: 10 }}
                onClick={this.resetForm}>
                Reset
              </Button>
              <Button
                bsStyle="primary"
                bsSize="large"
                blocktype="submit"
                style={{ marginBottom: 10, float: 'right' }}
                onClick={this.save}>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
        <Row>
          {this.state.error ? (
            <ErrorAlert errorMessage={this.state.errorMessage} />
          ) : null}
        </Row>
      </Grid>
    );
  }
}

export default StrataUnitDocumentEdit;
