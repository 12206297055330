import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import moment from 'moment';
import request from '../../utils/Request';
import DocumentTable from '../utils/dataTable/DocumentTable';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      ownerDocs: []
    };
    this.linkPropertyFormatter = this.linkPropertyFormatter.bind(this);
  }

  componentDidMount() {
    this.loadProperties();
    this.loadOwnerDocs();
  }

  loadProperties() {
    request
      .get(`/property/owner/${localStorage.user_id}`)
      .then((properties) => {
        this.setState({
          properties
        });
      })
      .catch(() => {
        toast('Could Not Load Properties.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  loadOwnerDocs() {
    request
      .get(`/documents/owner/${localStorage.user_id}/all`)
      .then((documents) => {
        this.setState({
          ownerDocs: documents.map((d) => ({
            ...d,
            type: d.documentType.name,
            date: moment(d.documentDate).format('MMMM YYYY'),
            link: (
              <a
                href={`${process.env.REACT_APP_SITE_API}/api/documents/${d.id}/file`}
                download={d.Title}>
                Download File
              </a>
            )
          }))
        });
      })
      .catch(() => {
        toast('Could Not Load Docs.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  linkPropertyFormatter(cell) {
    const property = this.state.properties.find(
      (p) => parseInt(p.id, 10) === parseInt(cell, 10)
    );
    return (
      <Link to={`/owner/property/${cell}`}>
        {property && property.rmNumber}
      </Link>
    );
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>{localStorage.name}</h3>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Col md={12}>
                <h5>Property List</h5>
              </Col>
              <Col md={12}>
                <div>
                  <BootstrapTable data={this.state.properties} pagination>
                    <TableHeaderColumn
                      dataField="id"
                      dataFormat={this.linkPropertyFormatter}
                      isKey>
                      RM Number
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="street">
                      Address
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="city">City</TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>

        <section className="section m-top">
          <DocumentTable documents={this.state.ownerDocs} hideAction />
        </section>
      </div>
    );
  }
}

export default Dashboard;
