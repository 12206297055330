import React, { useState } from 'react';
import { Button, Col, Grid, Row } from 'react-bootstrap';
import moment from 'moment';
import MonthlyPaymentForm from '../../forms/MonthlyPayment';
import ErrorAlert from '../../../utils/ErrorAlert';
import request from '../../../../utils/Request';

export default ({ location, history }) => {
  const [error, setError] = useState(null);

  const [monthlyPayment, setMonthlyPayment] = useState({
    paymentStartDate: moment()
      .add(2, 'months')
      .startOf('month')
      .format('YYYY-MMM-DD'),
    parkingFees: null,
    strataFees: null,
    otherFees: null,
    totalFees: null,
    archived: false,
    strataUnitId: null
  });

  const replace = async (event) => {
    event.preventDefault();
    const data = {
      ...monthlyPayment,
      strataUnitId: location.state.strataUnit.id,
      archived: false
    };
    try {
      const response = request.post(
        `/strataMonthlyPayment/replace/${location.state.monthlyPayment.id}`,
        data
      );
      if (response) {
        history.push(
          `/strataUnitOwner/strataUnit/${location.state.strataUnit.id}`
        );
      } else {
        setError('Error Replacing Monthly Payment.');
      }
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <MonthlyPaymentForm
            strataUnit={location.state.strataUnit}
            nameOnTitle={location.state.nameOnTitle}
            pad={location.state.pad}
            monthlyPayment={monthlyPayment}
            setMonthlyPayment={setMonthlyPayment}
          />
          <Grid>
            <Row>
              <Col md={12}>
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  blocktype="submit"
                  onClick={replace}>
                  Submit
                </Button>
              </Col>
            </Row>
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
