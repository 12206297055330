import React, { Component } from 'react';
import { Button, Grid, Row, Col } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import request from '../../../utils/Request';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class viewPdf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numPages: null,
      pageNumber: 1,
      document: {}
    };

    this.onDocumentLoadSuccess = ({ numPages }) => {
      this.setState({ numPages });
    };
  }

  componentDidMount() {
    this.loadDocument();
  }

  loadDocument() {
    const {
      match: { params }
    } = this.props;
    request.get(`/stratadocuments/${params.id}`).then((document) => {
      this.setState({
        document
      });
    });
  }

  render() {
    const { pageNumber, numPages } = this.state;
    return (
      <Grid>
        <Row>
          <Col md={12}>
            <div>
              <Document
                file={`${process.env.REACT_APP_SITE_API}/api/strataDocuments/${this.state.document.id}/file`}
                onLoadSuccess={this.onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <Button
                style={{ marginRight: 5, marginBottom: 5, width: 100 }}
                disabled={pageNumber === 1}
                bsStyle="primary"
                onClick={() =>
                  this.setState((prevState) => ({
                    pageNumber: prevState.pageNumber - 1
                  }))
                }>
                Previous
              </Button>
              <Button
                style={{ marginLeft: 5, marginBottom: 5, width: 100 }}
                disabled={pageNumber >= numPages}
                bsStyle="primary"
                onClick={() =>
                  this.setState((prevState) => ({
                    pageNumber: prevState.pageNumber + 1
                  }))
                }>
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default viewPdf;
