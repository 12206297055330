import React, { Component } from 'react';
import { Grid, Row, Col, Table, Button, Glyphicon } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DocumentTable from '../../utils/dataTable/DocumentTable';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

class Detail extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      error: false,
      errorMessage: '',
      owner: {},
      user: {},
      properties: [],
      documents: [],
      id: params.id
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
  }

  handleFilterInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      filterOptions: { ...prevState.filterOptions, [name]: value }
    }));
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  componentDidMount() {
    this.loadOwner();
    this.loadProperties();
    this.loadDirectories();
  }

  loadOwner() {
    const {
      match: { params }
    } = this.props;
    request.get(`/ownerprofile/${params.id}`).then((owner) => {
      this.setState({
        owner
      });
      this.setState({
        user: owner.user
      });
    });
  }

  loadProperties() {
    const {
      match: { params }
    } = this.props;
    request.get(`/property/ownerlist/${params.id}`).then((properties) => {
      this.setState({
        properties: properties.map((p) => ({
          ...p,
          button: (
            <div className="text-center">
              <Button
                bsSize="xsmall"
                style={{ marginRight: 2.5 }}
                bsStyle="danger"
                onClick={() => {
                  this.removeProperty(p.id);
                }}>
                <Glyphicon glyph="remove" />
              </Button>
            </div>
          )
        }))
      });
    });
  }

  removeProperty(id) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Are you sure you want to remove this property from the owner?'
      )
    ) {
      request.put(`/property/remove/owner/${id}`).then(() => {
        this.loadProperties();
      });
    }
  }

  deleteDocument(id) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Are you sure you want to delete this? This CAN NOT be undone.'
      )
    ) {
      request
        .delete(`/documents/delete/${id}`)
        .then(() => {
          this.loadDirectories();
          this.setState({ error: false });
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Error deleting document.'
          });
        });
    }
  }

  loadDirectories() {
    const {
      match: { params }
    } = this.props;
    request
      .get(`/documents/owner/${params.id}/all`)
      .then((documents) => {
        this.setState({
          documents: documents.map((d) => ({
            ...d,
            type: d.documentType.name,
            date: moment(d.documentDate).format('MMMM YYYY'),
            link: (
              <a
                href={`${process.env.REACT_APP_SITE_API}/api/documents/${d.id}/file`}
                download={d.Title}>
                Download File
              </a>
            ),
            action_buttons: (
              <div className="text-center">
                <Button
                  bsSize="xsmall"
                  bsStyle="danger"
                  style={{ marginRight: 5, marginLeft: 5 }}
                  onClick={() => {
                    this.deleteDocument(d.id);
                  }}>
                  <Glyphicon glyph="trash" />
                </Button>
                <Button
                  bsSize="xsmall"
                  bsStyle="primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    this.props.history.push(`/utils/editDocument/${d.id}`);
                  }}>
                  <Glyphicon glyph="pencil" />
                </Button>
              </div>
            )
          }))
        });
      })
      .catch(() => {});
  }

  linkDocumentFormatter(cell) {
    let name = '';
    this.state.documents.forEach((d) => {
      if (
        d.id === cell &&
        d.documentTypeId === this.state.filterOptions.documentTypeId &&
        d.month === this.state.filterOptions.month &&
        d.year === this.state.filterOptions.year
      ) {
        name = d.fileName;
      }
    });
    return <Link to={`/admin/owner/${cell}`}>{name}</Link>;
  }

  render() {
    return (
      <div>
        <section className="m-none">
          <Grid>
            <Row style={{ paddingTop: 20 }}>
              <Col md={6}>
                <h3>Owner Details</h3>
              </Col>
              <Col md={6}>
                <Link
                  style={{ marginLeft: 5 }}
                  className="btn btn-primary btn-xs pull-right"
                  to={`/admin/owner/resetOwnerPassword/${this.state.user.id}`}>
                  Change Password
                </Link>
                <Link
                  className="btn btn-primary btn-xs pull-right"
                  to={`/admin/edit/owner/${this.state.owner.id}`}>
                  Edit Owner
                </Link>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Col md={2}>Name</Col>
              <Col md={10}>{this.state.owner.name}</Col>
            </Row>
            <Row>
              <Col md={2}>Address</Col>
              <Col md={10}>
                {this.state.owner.address}, {this.state.owner.city}{' '}
                {this.state.owner.postal}
              </Col>
            </Row>
            <Row>
              <Col md={2}>Phone</Col>
              <Col md={10}>{this.state.owner.phone}</Col>
            </Row>
            <Row>
              <Col md={2}>Email</Col>
              <Col md={10}>{this.state.user.email}</Col>
            </Row>
          </Grid>
        </section>

        <section className="m-top">
          <Grid>
            <Row>
              <Col md={6}>
                <h5>Property List</h5>
              </Col>
              <Col md={6}>
                <Link
                  className="btn btn-primary btn-xs pull-right"
                  to={`/admin/property/add/${this.state.id}`}>
                  New Property
                </Link>
              </Col>
              <Col md={12}>
                <Table striped bordered condensed hover>
                  <thead>
                    <tr>
                      <td>RM Number</td>
                      <td>Address</td>
                      <td>City</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.properties.length > 0 ? (
                      this.state.properties.map((property) => (
                        <tr>
                          <td>
                            <Link to={`/admin/property/${property.id}`}>
                              {property.rmNumber}
                            </Link>
                          </td>
                          <td>{property.street}</td>
                          <td>{property.city}</td>
                          <td>{property.button}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="center-text" colSpan={3}>
                          No Properties Yet
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Grid>
        </section>

        <section className="section m-top">
          <DocumentTable documents={this.state.documents} />
        </section>
        <Row>
          {this.state.error ? (
            <ErrorAlert errorMessage={this.state.errorMessage} />
          ) : null}
        </Row>
      </div>
    );
  }
}

export default Detail;
