import React from 'react';
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Panel,
  Row
} from 'react-bootstrap';

export default ({
  monthlyPayment,
  setMonthlyPayment,
  strataUnit,
  nameOnTitle,
  pad
}) => {
  const getTotalFees = (payment) => {
    const other = parseFloat(payment.otherFees);
    const strata = parseFloat(payment.strataFees);
    const parking = parseFloat(payment.parkingFees);
    let total = 0.0;
    if (!Number.isNaN(other)) {
      total += other;
    }
    if (!Number.isNaN(strata)) {
      total += strata;
    }
    if (!Number.isNaN(parking)) {
      total += parking;
    }
    return total.toFixed(2);
  };

  const onChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setMonthlyPayment((prev) => {
      const newPayment = { ...prev, [name]: value };
      const totalFees = getTotalFees(newPayment);
      return { ...newPayment, totalFees };
    });
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Strata Monthly Payment</h3>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Panel header="Unit Details">
                  <p style={{ marginBottom: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>Unit Number: </span>
                    {strataUnit.unitNumber}
                  </p>
                  <p style={{ marginBottom: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>Lot Number : </span>
                    {strataUnit.lotNumber}
                  </p>
                  <p style={{ marginBottom: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>Primary Owner : </span>
                    {nameOnTitle}
                  </p>
                </Panel>
              </Col>
              <Col md={6}>
                <Panel header="Bank Information">
                  <p style={{ marginBottom: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>
                      Institution Name :{' '}
                    </span>
                    {pad.financialInstitutionName}
                  </p>
                  <p style={{ marginBottom: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>
                      Institution Address :{' '}
                    </span>
                    {pad.financialInstitutionAddress}
                  </p>
                  <p style={{ marginBottom: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>
                      Account Holder :{' '}
                    </span>
                    {pad.accountHolderFirstAndLastName}
                  </p>
                </Panel>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Strata Fees</ControlLabel>
                  <FormControl
                    name="strataFees"
                    type="text"
                    placeholder="Parking Fees"
                    value={monthlyPayment.strataFees}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Parking Fees</ControlLabel>
                  <FormControl
                    name="parkingFees"
                    type="text"
                    placeholder="Parking Fees"
                    value={monthlyPayment.parkingFees}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>Other Fees</ControlLabel>
                  <FormControl
                    name="otherFees"
                    type="text"
                    placeholder="Other Fees"
                    value={monthlyPayment.otherFees}
                    onChange={onChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <ControlLabel>Total Fees</ControlLabel>
                <FormControl
                  name="totalFees"
                  type="text"
                  placeholder="Other Fees"
                  value={monthlyPayment.totalFees}
                  readOnly
                />
              </Col>
              <Col md={6}>
                <ControlLabel>Payment Start Date</ControlLabel>
                <FormControl
                  name="paymentStartDate"
                  type="date"
                  placeholder="Payment Start Date"
                  value={monthlyPayment.paymentStartDate}
                  readOnly
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
