import React, { useEffect } from 'react';
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  Row
} from 'react-bootstrap';
import useValidation from '../../../validation/useValidation';
import {
  emailValidator,
  passwordValidator,
  requiredValidator
} from '../../../validation/validators';

export default ({ registerForm, setRegisterForm, setValid }) => {
  const [validateEmail, emailValid, emailErrors] = useValidation([
    requiredValidator,
    emailValidator
  ]);
  const [validatePassword, passwordValid, passwordErrors] = useValidation([
    requiredValidator,
    passwordValidator
  ]);

  useEffect(() => {
    setValid(emailValid && passwordValid);
  }, [emailValid, passwordValid, setValid]);

  const onChange = (event) => {
    const { name, type, value, checked } = event.target;
    setRegisterForm((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <p>
                  If you have an existing email with Korecki please enter it
                  with the password and the registration will happen for you
                  automatically. If you are a new user please enter the desired
                  email you would like to login with and a password. The account
                  will be created and you will be logged in.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h3>Authorize</h3>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup validationState={emailErrors.length && 'error'}>
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                      name="email"
                      type="text"
                      placeholder="Email"
                      value={registerForm.email}
                      onChange={(event) => {
                        onChange(event);
                        validateEmail(event.target.value);
                      }}
                      onBlur={(event) => validateEmail(event.target.value)}
                    />
                    {emailErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup validationState={passwordErrors.length && 'error'}>
                    <ControlLabel>Password</ControlLabel>
                    <FormControl
                      name="password"
                      type="password"
                      placeholder="Password"
                      value={registerForm.password}
                      onChange={(event) => {
                        onChange(event);
                        validatePassword(event.target.value);
                      }}
                      onBlur={(event) => validatePassword(event.target.value)}
                    />
                    {passwordErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Grid>
        </div>
      </section>
    </div>
  );
};
