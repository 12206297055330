import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Checkbox,
  Button,
  InputGroup,
  HelpBlock
} from 'react-bootstrap';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';
import { Typeahead } from 'react-bootstrap-typeahead';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

function findAddressComponent(address, component) {
  const addressComp = address[0].address_components.filter(
    (a) => a.types[0] === component
  );
  if (!addressComp[0]) {
    return null;
  }
  return addressComp[0].long_name;
}

class PropertyAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: '',
      loading: false,
      searchAddress: '',
      RMCount: 0,
      CMCount: 0,
      LUCount: 0,
      RBCount: 0,
      ownerHolderArray: [],
      showStrataDetailsBool: false,
      property: {
        address: '',
        street: '',
        city: '',
        postal: '',
        province: '',
        suite: '',
        countryId: 1,
        areaId: 1,
        sqft: 0.0,
        bedroom: '',
        den: false,
        bathroom: '0',
        halfWashroom: false,
        moveInFee: '0.0',
        moveOutFee: '0.0',
        moveInDeposit: '0.0',
        taxPaidBy: null,
        strataPaidBy: null,
        insurancePaidBy: null,
        strataPlan: null,
        strataCompany: null,
        strataAgent: null,
        strataPhone: null,
        strataFax: null,
        strataEmail: null,
        strataAddress: null,
        locker: null,
        intercom: null,
        pool: false,
        hottub: false,
        gym: false,
        entertainmentRoom: false,
        laundry: false,
        centralHeating: false,
        view: false,
        rmNumber: null,
        longitude: 0,
        latitude: 0,
        airConditioning: false,
        homeTheater: false,
        balcony: false,
        storage: false,
        garage: false,
        yard: false,
        electricWaterHeater: false,
        deck: false,
        oceanView: false,
        parkingAvailable: false,
        numberOfParkingSpots: 0,
        secondaryOwnerId: 0,
        parking: ''
      }
    };

    this.handleSearch = (searchAddress) => this.setState({ searchAddress });
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleStrataChoice = this.handleStrataChoice.bind(this);
    this.onOwnerSelect = this.onOwnerSelect.bind(this);
    this.searchSelect = this.searchSelect.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.create = this.create.bind(this);
  }

  componentDidMount() {
    this.getRMCount();
    this.getCMCount();
    this.getLUCount();
    this.getRBCount();
    this.loadOwners();
  }

  getRMCount() {
    const {
      match: { params }
    } = this.props;
    request.get(`/property/owner/${params.id}/${'RM'}`).then((count) => {
      this.setState({ RMCount: count });
    });
  }

  getCMCount() {
    const {
      match: { params }
    } = this.props;
    request.get(`/property/owner/${params.id}/${'CM'}`).then((count) => {
      this.setState({ CMCount: count });
    });
  }

  getLUCount() {
    const {
      match: { params }
    } = this.props;
    request.get(`/property/owner/${params.id}/${'LU'}`).then((count) => {
      this.setState({ LUCount: count });
    });
  }

  getRBCount() {
    const {
      match: { params }
    } = this.props;
    request.get(`/property/owner/${params.id}/${'RB'}`).then((count) => {
      this.setState({ RBCount: count });
    });
  }

  loadOwners() {
    request.get('/ownerprofile').then((owners) => {
      const holderArray = owners.map((owner) => ({
        id: owner.id,
        name: owner.name
      }));
      this.setState({ ownerHolderArray: holderArray });
    });
  }

  getValidStates(name) {
    const currency = /^\d+(?:\.\d{0,2})$/;
    switch (name) {
      case 'street':
        if (/^[a-zA-Z0-9\s,.'-]{3,}$/.test(this.state.property.street)) {
          return 'success';
        }
        return 'error';

      case 'city':
        if (
          /.*/.test(this.state.property.city) &&
          this.state.property.city.length > 0
        ) {
          return 'success';
        }
        return 'error';

      case 'postal':
        if (
          this.state.property.postal.match(
            /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
          )
        ) {
          return 'success';
        }
        return 'error';

      case 'province':
        if (
          /.*/.test(this.state.property.province) &&
          this.state.property.province.length > 0
        ) {
          return 'success';
        }
        return 'error';

      case 'bathroom':
        if (/^\d+$/.test(this.state.property.bathroom)) {
          return 'success';
        }
        return 'error';

      case 'bedroom':
        if (/^\d+$/.test(this.state.property.bedroom)) {
          return 'success';
        }
        return 'error';

      case 'moveInFee':
        if (
          currency.test(this.state.property.moveInFee) ||
          /^\d+$/.test(this.state.property.moveInFee)
        ) {
          return 'success';
        }
        return 'error';

      case 'moveOutFee':
        if (
          currency.test(this.state.property.moveOutFee) ||
          /^\d+$/.test(this.state.property.moveOutFee)
        ) {
          return 'success';
        }
        return 'error';

      case 'moveInDeposit':
        if (
          currency.test(this.state.property.moveInDeposit) ||
          /^\d+$/.test(this.state.property.moveInDeposit)
        ) {
          return 'success';
        }
        return 'error';

      case 'address':
        if (this.state.property.address.length > 0) {
          return 'success';
        }
        return 'error';

      default:
        return null;
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    const property = { ...this.state.property };
    property[name] = value;
    this.setState((prevState) => ({
      property: { ...prevState.property, [name]: value }
    }));
  }

  handleStrataChoice(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({ showStrataDetailsBool: value });
  }

  onOwnerSelect(selected) {
    this.setState((prevState) => ({
      property: { ...prevState.property, secondaryOwnerId: selected[0].id }
    }));
  }

  create(e) {
    e.preventDefault();
    this.setState({ loading: true, error: false });
    const {
      match: { params }
    } = this.props;
    const data = this.state.property;
    data.ownerId = params.id;
    request
      .post('/property', data)
      .then((res) => {
        if (res) {
          this.props.history.push(`/admin/property/${res.id}`);
          this.setState({ loading: false, error: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false, error: true, errorMessage: err });
      });
  }

  async searchSelect(address) {
    const searchAddress = address;
    this.setState({ searchAddress });
    const results = await geocodeByAddress(address);

    const postal = findAddressComponent(results, 'postal_code');
    const streetNumber = findAddressComponent(results, 'street_number');
    const route = findAddressComponent(results, 'route');
    const city = findAddressComponent(results, 'locality');
    const province = findAddressComponent(
      results,
      'administrative_area_level_1'
    );

    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        address,
        street: route,
        city,
        province,
        postal
      }
    }));
    if (streetNumber) {
      this.state.property.street = streetNumber.concat(' ', route);
    }
  }

  updateAddress(searchAddress) {
    this.setState({ searchAddress });
  }

  setPlaceholder() {
    const {
      match: { params }
    } = this.props;
    const { id } = params;
    const { length } = id;
    const type = this.state.property.contractType;
    const propertyCount = this.getPropertyCount(type) + 1;

    switch (length) {
      case 1:
        return `${type}-00${id}-${propertyCount}`;
      case 2:
        return `${type}-0${id}-${propertyCount}`;
      default:
        return `${type}-${id}-${propertyCount}`;
    }
  }

  getPropertyCount(type) {
    switch (type) {
      case 'RM':
        return this.state.RMCount;
      case 'CM':
        return this.state.CMCount;
      case 'LU':
        return this.state.LUCount;
      case 'RB':
        return this.state.RBCount;
      default:
        return null;
    }
  }

  showStrataDetails() {
    if (this.state.showStrataDetailsBool === 'true') {
      return (
        <div>
          <Col md={4}>
            <FormGroup>
              <ControlLabel>Strata Company</ControlLabel>
              <FormControl
                name="strataCompany"
                type="text"
                placeholder="Strata Company"
                value={this.state.property.strataCompany}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <ControlLabel>Strata Address</ControlLabel>
              <FormControl
                name="strataAddress"
                type="text"
                placeholder="Strata Address"
                value={this.state.property.strataAddress}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <ControlLabel>Strata Email</ControlLabel>
              <FormControl
                name="strataEmail"
                type="text"
                placeholder="Strata Email"
                value={this.state.property.strataEmail}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <ControlLabel>Strata Phone</ControlLabel>
              <FormControl
                name="strataPhone"
                type="text"
                placeholder="Strata Phone"
                value={this.state.property.strataPhone}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <ControlLabel>Strata Agent</ControlLabel>
              <FormControl
                name="strataAgent"
                type="text"
                placeholder="Strata Agent"
                value={this.state.property.strataAgent}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <ControlLabel>Strata Plan</ControlLabel>
              <FormControl
                name="strataPlan"
                type="text"
                placeholder="Strata Plan"
                value={this.state.property.strataPlan}
                onChange={this.handleInputChange}
              />
            </FormGroup>
          </Col>
        </div>
      );
    }
    return null;
  }

  ifParkingAvailable() {
    if (this.state.property.parkingAvailable) {
      return (
        <div>
          <Col md={4}>
            <ControlLabel># Of parking stalls</ControlLabel>
            <InputGroup>
              <FormControl
                name="numberOfParkingSpots"
                type="number"
                placeholder="# of Parking Spots"
                value={this.state.property.numberOfParkingSpots}
                onChange={this.handleInputChange}
              />
            </InputGroup>
          </Col>
          <Col md={4}>
            <ControlLabel>Parking Stall # (Seperated By Commas)</ControlLabel>
            <InputGroup>
              <FormControl
                name="parking"
                type="string"
                placeholder="Parking Stall Numbers"
                value={this.state.property.parking}
                onChange={this.handleInputChange}
              />
            </InputGroup>
          </Col>
        </div>
      );
    }
    return null;
  }

  render() {
    const inputProps = {
      value: this.state.searchAddress,
      onChange: this.updateAddress,
      placeholder: 'Search Address'
    };
    const cssClasses = {
      root: 'form-group',
      input: 'form-control',
      autocompleteContainer: 'my-autocomplete-container'
    };

    const renderSuggestion = ({ suggestion }) => (
      <div>
        <i className="fa fa-map-marker" />
        {suggestion}
      </div>
    );

    let hbstreet = <HelpBlock />;
    if (this.state.property.street.length <= 0) {
      hbstreet = <HelpBlock>This field is required</HelpBlock>;
    } else if (this.getValidStates('street') === 'success') {
      hbstreet = <HelpBlock />;
    } else {
      hbstreet = <HelpBlock>Invalid street address</HelpBlock>;
    }

    let hbcity = <HelpBlock />;
    if (this.state.property.city.length <= 0) {
      hbcity = <HelpBlock>This field is required</HelpBlock>;
    } else if (this.getValidStates('city') === 'success') {
      hbcity = <HelpBlock />;
    } else {
      hbcity = <HelpBlock>Invalid city name</HelpBlock>;
    }

    let hbpostal = <HelpBlock />;
    if (this.state.property.postal.length <= 0) {
      hbpostal = <HelpBlock>This field is required</HelpBlock>;
    } else if (this.getValidStates('postal') === 'success') {
      hbpostal = <HelpBlock />;
    } else {
      hbpostal = <HelpBlock>Invalid postal code</HelpBlock>;
    }

    let hbprovince = <HelpBlock />;
    if (this.state.property.province.length <= 0) {
      hbprovince = <HelpBlock>This field is required</HelpBlock>;
    } else if (this.getValidStates('province') === 'success') {
      hbprovince = <HelpBlock />;
    } else {
      hbprovince = <HelpBlock>Invalid province name</HelpBlock>;
    }

    return (
      <div>
        <section className="section section-parallax parallax custom-parallax-medium-padding m-none">
          <div>
            <Grid>
              <Form>
                <Row>
                  <Col md={12}>
                    <h1>Add New Property</h1>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <h4>Full Address (Required for Map Functionality)</h4>
                  </Col>
                  <Col md={12}>
                    <FormGroup validationState={this.getValidStates('address')}>
                      <PlacesAutocomplete
                        inputProps={inputProps}
                        classNames={cssClasses}
                        renderSuggestion={renderSuggestion}
                        onChange={this.updateAddress}
                        onSelect={this.searchSelect}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup validationState={this.getValidStates('street')}>
                      <ControlLabel>Street Address</ControlLabel>
                      <FormControl
                        name="street"
                        type="text"
                        placeholder="Street Address"
                        value={this.state.property.street}
                        onChange={this.handleInputChange}
                      />
                      {hbstreet}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup validationState={this.getValidStates('city')}>
                      <ControlLabel>City</ControlLabel>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        name="city"
                        value={this.state.property.city}
                        onChange={this.handleInputChange}>
                        <option value="">Choose one:</option>
                        <option value="Surrey">Surrey</option>
                        <option value="Burnaby">Burnaby</option>
                        <option value="Vancouver">Vancouver</option>
                        <option value="Richmond">Richmond</option>
                        <option value="New-Westminister">
                          New Westminister
                        </option>
                        <option value="North-Vancouver">North Vancouver</option>
                      </FormControl>
                      {hbcity}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup validationState={this.getValidStates('postal')}>
                      <ControlLabel>Postal Code</ControlLabel>
                      <FormControl
                        name="postal"
                        type="text"
                        placeholder="Postal Code"
                        value={this.state.property.postal}
                        onChange={this.handleInputChange}
                      />
                      {hbpostal}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup
                      validationState={this.getValidStates('province')}>
                      <ControlLabel>Province</ControlLabel>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        name="province"
                        value={this.state.property.province}
                        onChange={this.handleInputChange}>
                        <option value="">Choose one:</option>
                        <option value="AB">AB</option>
                        <option value="BC">BC</option>
                        <option value="MB">MB</option>
                        <option value="NB">NB</option>
                        <option value="NL">NL</option>
                        <option value="NT">NT</option>
                        <option value="NS">NS</option>
                        <option value="NU">NU</option>
                        <option value="ON">ON</option>
                        <option value="PE">PE</option>
                        <option value="QC">QC</option>
                        <option value="SK">SK</option>
                        <option value="YT">YT</option>
                      </FormControl>
                      {hbprovince}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <ControlLabel>Suite</ControlLabel>
                    <FormControl
                      name="suite"
                      type="text"
                      placeholder="Suite"
                      value={this.state.property.suite}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <ControlLabel>Second Owner / Co-Owner </ControlLabel>
                      <Typeahead
                        labelKey="name"
                        name="secondaryOwnerId"
                        onChange={this.onOwnerSelect}
                        options={this.state.ownerHolderArray}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12} className="form-heading">
                    <h4>Details</h4>
                  </Col>
                  <Col md={12}>
                    <h4>Contract Type:</h4>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="contractType"
                      value={this.state.property.contractType}
                      onChange={this.handleInputChange}>
                      <option value="">Choose one:</option>
                      <option value="RM">Rental Management</option>
                      <option value="CM">Commercial Management</option>
                      <option value="LU">Lease-UP</option>
                      <option value="RB">Rental Building</option>
                    </FormControl>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <ControlLabel>RM Number</ControlLabel>
                      <FormControl
                        disabled={!this.state.property.contractType}
                        name="rmNumber"
                        type="text"
                        placeholder={this.setPlaceholder()}
                        value={this.state.property.rmNumber}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <ControlLabel>Sqft</ControlLabel>
                      <FormControl
                        name="sqft"
                        type="number"
                        placeholder="Sqft"
                        value={this.state.property.sqft}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup validationState={this.getValidStates('bedroom')}>
                      <ControlLabel>Bedroom</ControlLabel>
                      <FormControl
                        componentClass="select"
                        name="bedroom"
                        placeholder="select"
                        value={this.state.property.bedroom}
                        onChange={this.handleInputChange}>
                        <option value="">Choose one:</option>
                        <option value="0">Studio</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                      </FormControl>
                      <Checkbox
                        name="den"
                        value={this.state.property.den}
                        onChange={this.handleInputChange}>
                        + Den
                      </Checkbox>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup
                      validationState={this.getValidStates('bathroom')}>
                      <ControlLabel>Bathroom</ControlLabel>
                      <FormControl
                        name="bathroom"
                        type="number"
                        placeholder="Bathroom"
                        value={this.state.property.bathroom}
                        onChange={this.handleInputChange}
                      />
                      <Checkbox
                        name="halfWashroom"
                        value={this.state.property.halfWashroom}
                        onChange={this.handleInputChange}>
                        + 1/2 Washroom
                      </Checkbox>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup
                      validationState={this.getValidStates('moveInFee')}>
                      <ControlLabel>Move In Fee</ControlLabel>
                      <InputGroup>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <FormControl
                          name="moveInFee"
                          type="number"
                          placeholder="Move In Fee"
                          value={this.state.property.moveInFee}
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup
                      validationState={this.getValidStates('moveOutFee')}>
                      <ControlLabel>Move Out Fee</ControlLabel>
                      <InputGroup>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <FormControl
                          name="moveOutFee"
                          type="number"
                          placeholder="Move Out Fee"
                          value={this.state.property.moveOutFee}
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup
                      validationState={this.getValidStates('moveInDeposit')}>
                      <ControlLabel>Move In Deposit</ControlLabel>
                      <InputGroup>
                        <InputGroup.Addon>$</InputGroup.Addon>
                        <FormControl
                          name="moveInDeposit"
                          type="number"
                          placeholder="Move In Deposit"
                          value={this.state.property.moveInDeposit}
                          onChange={this.handleInputChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <h4>Strata Details</h4>

                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="showStrataDetails"
                      value={this.state.showStrataDetailsBool}
                      onChange={this.handleStrataChoice}>
                      <option value={false}>No</option>
                      <option value>Yes</option>
                    </FormControl>
                  </Col>
                  {this.showStrataDetails()}

                  {/*
                    <FormControl componentClass="select" placeholder="select" name="showStrataDetails" value={ this.state.showStrataDetails } onChange={this.handleStrataChoice}>
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </FormControl>

                  </Col>
                    {this.showStrataDetails()}
*/}

                  <Col md={12} style={{ paddingTop: 10 }}>
                    <FormGroup className="form-heading">
                      <ControlLabel>Amenities</ControlLabel>
                      <Checkbox
                        name="pool"
                        value={this.state.property.pool}
                        onChange={this.handleInputChange}>
                        Pool
                      </Checkbox>
                      <Checkbox
                        name="hottub"
                        value={this.state.property.hottub}
                        onChange={this.handleInputChange}>
                        Hot Tub
                      </Checkbox>
                      <Checkbox
                        name="gym"
                        value={this.state.property.gym}
                        onChange={this.handleInputChange}>
                        Gym
                      </Checkbox>
                      <Checkbox
                        name="laundry"
                        value={this.state.property.laundry}
                        onChange={this.handleInputChange}>
                        Laundry
                      </Checkbox>
                      <Checkbox
                        name="view"
                        value={this.state.property.view}
                        onChange={this.handleInputChange}>
                        View
                      </Checkbox>
                      <Checkbox
                        name="airConditioning"
                        value={this.state.property.airConditioning}
                        onChange={this.handleInputChange}>
                        Air Conditioning
                      </Checkbox>
                      <Checkbox
                        name="homeTheater"
                        value={this.state.property.homeTheater}
                        onChange={this.handleInputChange}>
                        Home Theater
                      </Checkbox>
                      <Checkbox
                        name="centralHeating"
                        value={this.state.property.centralHeating}
                        onChange={this.handleInputChange}>
                        Central Heating
                      </Checkbox>
                      <Checkbox
                        name="balcony"
                        value={this.state.property.balcony}
                        onChange={this.handleInputChange}>
                        Balcony
                      </Checkbox>
                      <Checkbox
                        name="storage"
                        value={this.state.property.storage}
                        onChange={this.handleInputChange}>
                        Storage
                      </Checkbox>
                      <Checkbox
                        name="garage"
                        value={this.state.property.garage}
                        onChange={this.handleInputChange}>
                        Garage
                      </Checkbox>
                      <Checkbox
                        name="yard"
                        value={this.state.property.yard}
                        onChange={this.handleInputChange}>
                        Yard
                      </Checkbox>
                      <Checkbox
                        name="electricWaterHeater"
                        value={this.state.property.electricWaterHeater}
                        onChange={this.handleInputChange}>
                        Electric Water Heater
                      </Checkbox>
                      <Checkbox
                        name="deck"
                        value={this.state.property.deck}
                        onChange={this.handleInputChange}>
                        Deck
                      </Checkbox>
                      <Checkbox
                        name="oceanView"
                        value={this.state.property.oceanView}
                        onChange={this.handleInputChange}>
                        Ocean View
                      </Checkbox>
                      <Checkbox
                        name="parkingAvailable"
                        value={this.state.property.parkingAvailable}
                        onChange={this.handleInputChange}>
                        Parking Available
                      </Checkbox>
                    </FormGroup>
                  </Col>
                  {this.ifParkingAvailable()}
                </Row>
              </Form>
            </Grid>
            <Grid>
              <Row>
                <Col md={6} style={{ paddingTop: 10 }}>
                  {this.state.loading === false ? (
                    <Button
                      bsStyle="primary"
                      block
                      type="submit"
                      onClick={this.create}
                      disabled={!this.state.searchAddress}>
                      Create Property
                    </Button>
                  ) : (
                    <div className="loader" />
                  )}
                </Col>
              </Row>
              <Row>
                {this.state.error ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}
              </Row>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

export default PropertyAdd;
