import React, { useState } from 'react';
import {
  Modal,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';
import StrataUnitOwnerForm from '../forms/StrataUnitOwner';

export default ({
  loadStrataUnits,
  propertyId,
  property,
  closeModal,
  show
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [strataOwner, setStrataOwner] = useState({
    owner: {
      nameOnTitle: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      isCurrent: true,
      city: '',
      stateOrProvince: '',
      country: '',
      postalCode: ''
    },
    email: '',
    strataRole: 0
  });
  const [strataUnit, setStrataUnit] = useState({
    unitNumber: '',
    lotNumber: ''
  });
  const [newStrataUnit, setNewStrataUnit] = useState(null);
  const [unitNumberValidation, setUnitNumberVaidation] = useState({
    valid: false,
    error: null
  });
  const [lotNumberValidation, setLotNumberValidation] = useState({
    valid: false,
    error: null
  });
  const [formValid, setFormValid] = useState(false);

  const validate = (field, value) => {
    switch (field) {
      case 'unitNumber': {
        let err = null;
        if (!value) {
          err = 'Unit number cannot be blank';
        }
        setUnitNumberVaidation({ valid: !err, err });
        break;
      }
      case 'lotNumber': {
        let err = null;
        if (!value) {
          err = 'Lot number cannot be blank';
        }
        setLotNumberValidation({ valid: !err, err });
        break;
      }
      default:
        break;
    }
  };

  const onStrataUnitChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setStrataUnit((prev) => ({ ...prev, [name]: value }));
    validate(name, value);
  };

  const create = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!newStrataUnit) {
      const newUnit = {
        unitNumber: strataUnit.unitNumber,
        lotNumber: strataUnit.lotNumber,
        strataPropertyId: propertyId
      };
      try {
        const unit = await request.post('/strataUnit', newUnit);
        setNewStrataUnit(unit);
        setError(null);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError('No duplicate Lot Number or Unit Numbers are allowed.');
      }
    } else {
      try {
        const data = {
          ...strataOwner.owner,
          email: strataOwner.email,
          strataRole: strataOwner.strataRole,
          strataUnitId: newStrataUnit.id,
          isCurrent: false
        };

        const owner = await request.post(`/strataOwner`, data);

        const strataUnitInvitation = {
          email: data.email,
          strataUnitId: newStrataUnit.id,
          strataRole: data.strataRole,
          strataOwnerId: owner.id
        };
        const invitation = await request.post(
          '/strataUnitUserInvitation',
          strataUnitInvitation
        );

        await request.post('/strataUnitUserInvitation/invite', invitation);

        loadStrataUnits();
        closeModal();
      } catch (e) {
        setError(e.message);
      }
    }
  };

  return (
    <Modal
      dialogClassName="custom-modal"
      size="lg"
      show={show}
      onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Strata Unit</Modal.Title>
      </Modal.Header>
      {newStrataUnit && (
        <Modal.Body>
          <Row>
            <Col md={12}>
              <p>
                Please Enter the email you would like send the registration form
                to as well as the role this user will have.
              </p>
            </Col>
            <StrataUnitOwnerForm
              strataOwner={strataOwner}
              setStrataOwner={setStrataOwner}
              setValid={setFormValid}
              property={property}
              unit={strataUnit.unitNumber}
            />
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Row>
        </Modal.Body>
      )}
      {!newStrataUnit && (
        <Modal.Body>
          <Row>
            <Col md={6}>
              <FormGroup
                validationState={unitNumberValidation.error && 'error'}>
                <ControlLabel>Unit Number </ControlLabel>
                <FormControl
                  name="unitNumber"
                  type="text"
                  placeholder="Unit Number"
                  value={strataUnit.unitNumber}
                  onChange={onStrataUnitChange}
                />
                {unitNumberValidation.error && (
                  <HelpBlock>{unitNumberValidation.error}</HelpBlock>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup validationState={lotNumberValidation.error && 'error'}>
                <ControlLabel>Strata Lot # </ControlLabel>
                <FormControl
                  name="lotNumber"
                  type="text"
                  placeholder="Strata Lot Number"
                  value={strataUnit.lotNumber}
                  onChange={onStrataUnitChange}
                />
                {lotNumberValidation.error && (
                  <HelpBlock>{lotNumberValidation.error}</HelpBlock>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
        </Modal.Body>
      )}
      <Modal.Footer>
        {!loading && (
          <Button
            bsStyle="primary"
            bsSize="large"
            block
            type="submit"
            disabled={
              newStrataUnit
                ? !formValid
                : !unitNumberValidation.valid || !lotNumberValidation.valid
            }
            onClick={create}>
            Submit
          </Button>
        )}
        {loading && <div className="loader" />}
      </Modal.Footer>
    </Modal>
  );
};
