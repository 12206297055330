import React, { useEffect } from 'react';
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import useValidation from '../../../validation/useValidation';
import {
  emailValidator,
  passwordValidator,
  requiredValidator
} from '../../../validation/validators';

const roles = [
  {
    display: 'Strata Owner',
    value: 'Strata Owner'
  },
  {
    display: 'Administrator',
    value: 'Strata Administrator'
  }
];

export default ({ user, setUser, setValid }) => {
  const [validateUserName, userNameValid, userNameErrors] = useValidation([
    requiredValidator
  ]);
  const [validateEmail, emailValid, emailErrors] = useValidation([
    requiredValidator,
    emailValidator
  ]);
  const [validatePassword, passwordValid, passwordErrors] = useValidation([
    requiredValidator,
    passwordValidator
  ]);
  const [validateRole, roleValid, roleErrors] = useValidation([
    requiredValidator
  ]);

  useEffect(() => {
    if (user.role === 'Strata Administrator') {
      setValid(emailValid && passwordValid && userNameValid && roleValid);
    } else if (user.role === 'Strata Owner') {
      setValid(passwordValid && userNameValid && roleValid);
    } else {
      setValid(false);
    }
  }, [
    setValid,
    emailValid,
    passwordValid,
    userNameValid,
    roleValid,
    user.role
  ]);

  const onChange = (event) => {
    const { name, type, value, checked } = event.target;
    setUser((prev) => {
      const temp = {
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      };
      if (name === 'role' && value === 'Strata Administrator') {
        temp.email = null;
      }

      return temp;
    });
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Property Login</h3>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Col md={12}>
                <h4>Create New User</h4>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Col md={12}>
                <Form>
                  <FormGroup validationState={userNameErrors.length && 'error'}>
                    <ControlLabel>Strata Plan / Username</ControlLabel>
                    <FormControl
                      name="username"
                      type="text"
                      placeholder="Strata Plan / Username"
                      value={user.username}
                      onChange={(event) => {
                        onChange(event);
                        validateUserName(event.target.value);
                      }}
                      onBlur={(event) => validateUserName(event.target.value)}
                    />
                    {userNameErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                  <FormGroup validationState={passwordErrors.length && 'error'}>
                    <ControlLabel>Password</ControlLabel>
                    <FormControl
                      name="password"
                      type="password"
                      placeholder="Password"
                      value={user.password}
                      onChange={(event) => {
                        onChange(event);
                        validatePassword(event.target.value);
                      }}
                      onBlur={(event) => validatePassword(event.target.value)}
                    />
                    {passwordErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                  <FormGroup validationState={roleErrors.length && 'error'}>
                    <ControlLabel>Role</ControlLabel>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      name="role"
                      value={user.role}
                      onChange={(event) => {
                        onChange(event);
                        validateRole(event.target.value);
                      }}
                      onBlur={(event) => validateRole(event.target.value)}>
                      <option value="">Choose one:</option>
                      {roles.map((item) => (
                        <option value={item.value}>{item.display}</option>
                      ))}
                    </FormControl>
                    {roleErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Form>
                {user.role === roles[1].value && (
                  <Form>
                    <FormGroup validationState={emailErrors.length && 'error'}>
                      <ControlLabel>Email</ControlLabel>
                      <FormControl
                        name="email"
                        type="text"
                        placeholder="Email"
                        value={user.email}
                        onChange={(event) => {
                          onChange(event);
                          validateEmail(event.target.value);
                        }}
                        onBlur={(event) => validateEmail(event.target.value)}
                      />
                      {emailErrors.map((error) => (
                        <HelpBlock key={error}>{error}</HelpBlock>
                      ))}
                    </FormGroup>
                  </Form>
                )}
              </Col>
            </Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
