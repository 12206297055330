import React, { Component } from 'react';
import { Button, Grid, Col, Row } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import request from '../../../utils/Request';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class viewPdf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numPages: null,
      pageNumber: 1,
      document: {
        id: null
      }
    };

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages });
  }

  componentDidMount() {
    this.loadDocument();
  }

  loadDocument() {
    const {
      match: { params }
    } = this.props;
    if (this.props.location.params.documentFor === 'unit') {
      request.get(`/StrataUnitDocument/${params.id}`).then((document) => {
        this.setState({ document });
      });
    } else {
      request.get(`/stratadocuments/${params.id}`).then((document) => {
        this.setState({ document });
      });
    }
  }

  render() {
    const { pageNumber, numPages } = this.state;
    return (
      <Grid>
        <Row>
          <Col md={12}>
            <div>
              <Document
                file={
                  this.props.location.params.documentFor !== 'unit'
                    ? `${process.env.REACT_APP_SITE_API}/api/strataDocuments/${this.state.document.id}/file`
                    : `${process.env.REACT_APP_SITE_API}/api/strataUnitDocument/${this.state.document.id}/file`
                }
                onLoadSuccess={this.onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <Button
                style={{ marginRight: 5, marginBottom: 5, width: 100 }}
                disabled={pageNumber === 1}
                bsStyle="primary"
                onClick={() =>
                  this.setState((prevState) => ({
                    pageNumber: prevState.pageNumber - 1
                  }))
                }>
                Previous
              </Button>
              <Button
                style={{ marginLeft: 5, marginBottom: 5, width: 100 }}
                disabled={pageNumber >= numPages}
                bsStyle="primary"
                onClick={() =>
                  this.setState((prevState) => ({
                    pageNumber: prevState.pageNumber + 1
                  }))
                }>
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default viewPdf;
