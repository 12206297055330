import React, { useState } from 'react';
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Alert
} from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import ErrorAlert from '../../utils/ErrorAlert';
import request from '../../../utils/Request';

export default () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [result, setResult] = useState(null);

  const onDrop = (files) => {
    setError(null);
    setResult(null);
    if (files.length > 1) {
      setError('Only one file can be uploaded at a time');
      return;
    }
    setFile(files[0]);
  };

  const upload = async () => {
    setError(null);
    setResult(null);
    setLoading(true);
    try {
      const res = await request.upload('/importOwners', file);
      if (res) {
        setLoading(false);
        setFile(null);
        const parsed = await res.json();
        setResult(parsed);
      } else {
        throw Error();
      }
    } catch (e) {
      setLoading(false);
      setError('Error importing owners');
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Import/Export</h3>
                <h5>Import owners from YARDI CSV</h5>
                <Form>
                  <FormGroup>
                    <Dropzone
                      onDrop={onDrop}
                      className="dropzone"
                      accept="text/csv">
                      {!file && 'Drop file here or click to select a file.'}
                      {file && file.name}
                    </Dropzone>
                  </FormGroup>
                  {!loading && (
                    <Button
                      bsStyle="primary"
                      bsSize="large"
                      block
                      type="submit"
                      onClick={upload}
                      disabled={!file}>
                      Upload
                    </Button>
                  )}
                  {loading && (
                    <div className="loader" style={{ margin: -10 }} />
                  )}
                </Form>
              </Col>
            </Row>
          </Grid>
        </div>
      </section>
      <section>
        <Grid>
          <Row>
            <Col md={12}>
              {error && <ErrorAlert errorMessage={error} />}
              {result && !!result.saved && (
                <Alert bsStyle="success">Imported {result.saved} owners</Alert>
              )}
              {result && !!result.created && (
                <Alert bsStyle="warning">Created {result.created} units</Alert>
              )}
            </Col>
          </Row>
        </Grid>
      </section>
    </div>
  );
};
