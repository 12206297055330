import React, { useEffect } from 'react';
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  Row
} from 'react-bootstrap';
import useValidation from '../../../validation/useValidation';
import {
  numberValidator,
  requiredValidator
} from '../../../validation/validators';

export default ({ validationForm, setValidationForm, setValid }) => {
  const [
    validateUnitNumber,
    unitNumberValid,
    unitNumberErrors
  ] = useValidation([requiredValidator, numberValidator]);
  const [
    validatePlanNumber,
    planNumberValid,
    planNumberErrors
  ] = useValidation([requiredValidator, numberValidator]);
  const [validateName, nameValid, nameErrors] = useValidation([
    requiredValidator
  ]);

  useEffect(() => {
    setValid(nameValid && planNumberValid && unitNumberValid);
  }, [nameValid, planNumberValid, unitNumberValid, setValid]);

  const onChange = (event) => {
    const { name, type, value, checked } = event.target;
    setValidationForm((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Authorize</h3>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col md={4}>
                  <FormGroup
                    validationState={unitNumberErrors.length && 'error'}>
                    <ControlLabel>Unit Number</ControlLabel>
                    <FormControl
                      name="unitNumber"
                      type="text"
                      placeholder="Unit Number"
                      value={validationForm.unitNumber}
                      onChange={(event) => {
                        onChange(event);
                        validateUnitNumber(event.target.value);
                      }}
                      onBlur={(event) => validateUnitNumber(event.target.value)}
                    />
                    {unitNumberErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup
                    validationState={planNumberErrors.length && 'error'}>
                    <ControlLabel>Strata Plan Number</ControlLabel>
                    <FormControl
                      name="strataPlanNumber"
                      type="text"
                      placeholder="Strata Plan Number"
                      value={validationForm.strataPlanNumber}
                      onChange={(event) => {
                        onChange(event);
                        validatePlanNumber(event.target.value);
                      }}
                      onBlur={(event) => validatePlanNumber(event.target.value)}
                    />
                    {planNumberErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup validationState={nameErrors.length && 'error'}>
                    <ControlLabel>Name On Title</ControlLabel>
                    <FormControl
                      name="nameOnTitle"
                      type="text"
                      placeholder="Name On Title"
                      value={validationForm.nameOnTitle}
                      onChange={(event) => {
                        onChange(event);
                        validateName(event.target.value);
                      }}
                      onBlur={(event) => validateName(event.target.value)}
                    />
                    {nameErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Grid>
        </div>
      </section>
    </div>
  );
};
