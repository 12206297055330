import React from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory } from 'react-router-dom';
import logo from '../../../images/logoV2.png';
import Auth from '../../../utils/Auth';

export default () => {
  const history = useHistory();

  const logout = (event) => {
    event.preventDefault();
    Auth.logout();
    history.push('/login');
  };

  return (
    <Navbar collapseOnSelect>
      <Navbar.Header>
        <Navbar.Brand>
          <img src={logo} className="logo" alt="Korecki Real Estate Services" />
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav pullRight>
          <LinkContainer to="/strataAdmin/dashboard">
            <NavItem eventKey={1}>Properties</NavItem>
          </LinkContainer>
          <LinkContainer to="/strataAdmin/document/upload">
            <NavItem eventKey={1}>Documents</NavItem>
          </LinkContainer>
          <LinkContainer to="/strataAdmin/user/list">
            <NavItem eventKey={1}>Building / User Log Ins</NavItem>
          </LinkContainer>
          <LinkContainer to="/strataAdmin/agent/list">
            <NavItem eventKey={1}>Strata Agents</NavItem>
          </LinkContainer>
          <LinkContainer to="/strataAdmin/import-export">
            <NavItem eventKey={1}>Import/Export</NavItem>
          </LinkContainer>
          <NavItem eventKey={2} onClick={logout}>
            Log Out
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
