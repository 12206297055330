import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Panel,
  Row
} from 'react-bootstrap';
import moment from 'moment';
import request from '../../../utils/Request';
import PADForm from './PAD';
import ErrorAlert from '../../utils/ErrorAlert';

export default ({ history, location, match }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [strataExtraPayments, setStrataExtraPayments] = useState({
    strataUnitId: null,
    paymentStartDate: moment()
      .add(2, 'months')
      .startOf('month')
      .format('YYYY-MMM-DD'),
    preAuthorizedDebitId: null,
    oneTimePayment: null,
    oneTimePaymentWithdrawDate: moment()
      .add(2, 'months')
      .startOf('month')
      .format('YYYY-MMM-DD'),
    monthlyPayment1: null,
    monthlyPayment1WithdrawDate: moment()
      .add(2, 'months')
      .startOf('month')
      .format('YYYY-MMM-DD'),
    monthlyPayment2: null,
    monthlyPayment2WithdrawDate: moment()
      .add(2, 'months')
      .startOf('month')
      .format('YYYY-MMM-DD')
  });

  const [PAD, setPAD] = useState({
    postalCode: null,
    address: null,
    financialInstitutionName: null,
    financialInstitutionAddress: null,
    financialInstitutionCity: null,
    financialInstitutionProvince: null,
    bankTransitNumber: null,
    institutionNumber: null,
    accountHolderFirstAndLastName: null,
    accountNumber: null,
    padStatus: '',
    strataUnitId: null
  });

  const [openPADForm, setOpenPADForm] = useState(false);
  const [useOldPAD, setUseOldPAD] = useState(false);

  const getUnitPad = (strataOwners) => {
    let pad = {};
    strataOwners.forEach((owner) => {
      if (owner.isCurrent) {
        pad = owner.preAuthorizedDebit;
      }
    });
    return pad;
  };

  const createPad = async (event) => {
    event.preventDefault();
    const data = {
      ...PAD,
      padStatus: 'Temporary',
      strataUnitId: match.params.strataUnitId
    };
    try {
      const response = await request.post(`/preAuthorizedDebit`, data);
      if (response) {
        history.push(`/strataAdmin/strataUnitOwner/${location.state.id}`);
      } else {
        setError(
          `Error Creating Pre Authorized Debit${response}${response.id}`
        );
      }
    } catch (e) {
      setError(e.message);
    }
  };

  const handleCheckboxInputValue = () => {
    setUseOldPAD(!useOldPAD);
  };

  useEffect(() => {
    const loadStrataUnit = async () => {
      try {
        const response = await request.get(`/StrataUnit/${match.params.id}`);
        if (response) {
          const strataUnit = getUnitPad(response);
          setPAD(strataUnit.strataOwners);
          setOpenPADForm(true);
        }
      } catch (e) {
        setError(e.message);
      }
    };
    if (!PAD && !error) {
      loadStrataUnit();
    }
  });

  const create = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (useOldPAD) {
      try {
        await createPad(event);
      } catch (e) {
        setError(e.message);
        setLoading(false);
        return;
      }
    } else {
      setOpenPADForm(false);
    }

    const data = {
      ...strataExtraPayments,
      strataUnitId: parseInt(match.params.id, 10),
      oneTimePayment: parseFloat(strataExtraPayments.oneTimePayment),
      monthlyPayment1: parseFloat(strataExtraPayments.monthlyPayment1),
      monthlyPayment2: parseFloat(strataExtraPayments.monthlyPayment2)
    };
    try {
      const response = await request.post('/StrataExtraPayments', data);
      if (response) {
        history.push(`/strataUnitOwner/strataUnit/${match.params.id}`);
        setLoading(false);
        window.location.reload();
      }
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setStrataExtraPayments((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h1>Extra Payment Form</h1>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>One Time Payment</ControlLabel>
                  <FormControl
                    name="oneTimePayment"
                    type="text"
                    placeholder="$"
                    value={strataExtraPayments.oneTimePayment}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <ControlLabel>One Time Payment Withdraw Date</ControlLabel>
                  <FormControl
                    name="oneTimePaymentWithdrawDate"
                    type="date"
                    placeholder="One Time Payment Withdraw Date"
                    value={strataExtraPayments.oneTimePaymentWithdrawDate}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Monthly Payment One</ControlLabel>
                  <FormControl
                    name="monthlyPayment1"
                    type="text"
                    placeholder="$"
                    value={strataExtraPayments.monthlyPayment1}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Monthly Payment One Withdraw Date</ControlLabel>
                  <FormControl
                    name="monthlyPayment1WithdrawDate"
                    type="date"
                    placeholder="Monthly Payment One Withdraw Date"
                    value={strataExtraPayments.monthlyPayment1WithdrawDate}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Monthly Payment Two</ControlLabel>
                  <FormControl
                    name="monthlyPayment2"
                    type="text"
                    placeholder="$"
                    value={strataExtraPayments.monthlyPayment2}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <ControlLabel>Monthly Payment Two Withdraw Date</ControlLabel>
                  <FormControl
                    name="monthlyPayment2WithdrawDate"
                    type="date"
                    placeholder="Monthly Payment Two Withdraw Date"
                    value={strataExtraPayments.monthlyPayment2WithdrawDate}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Grid>
              <Row>
                {openPADForm && (
                  <div>
                    <Col
                      md={6}
                      style={{ paddingLeft: 'unset', paddingRight: '30px' }}>
                      <FormGroup>
                        <Panel header="Pre Authorized Debit">
                          <Col xs={8}>
                            <p style={{ marginBottom: 10 }}>
                              <span style={{ fontWeight: 'bold' }}>
                                Institution Name :{' '}
                              </span>
                              {PAD.financialInstitutionName || ''}
                            </p>
                            <p style={{ marginBottom: 10 }}>
                              <span style={{ fontWeight: 'bold' }}>
                                Institution Address :{' '}
                              </span>
                              {`${PAD.financialInstitutionAddress},${PAD.financialInstitutionCity},${PAD.financialInstitutionProvince}` ||
                                ''}
                            </p>
                            <p style={{ marginBottom: 10 }}>
                              <span style={{ fontWeight: 'bold' }}>
                                Account Holder Name :{' '}
                              </span>
                              {PAD.accountHolderFirstAndLastName || ''}
                            </p>
                          </Col>
                        </Panel>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <ControlLabel style={{ top: 50 }}>
                          Do you want to use this banking information?
                        </ControlLabel>
                        <div className="radio">
                          <label htmlFor="yes">
                            <input
                              type="radio"
                              value
                              name="radio"
                              checked={useOldPAD}
                              onChange={handleCheckboxInputValue}
                              id="yes"
                            />
                            Yes
                          </label>
                        </div>
                        <div className="radio">
                          <label htmlFor="no">
                            <input
                              type="radio"
                              value={false}
                              name="radio"
                              checked={!useOldPAD}
                              onChange={handleCheckboxInputValue}
                              id="no"
                            />
                            No
                          </label>
                        </div>
                      </FormGroup>
                    </Col>
                    {useOldPAD && (
                      <Col md={12}>
                        <PADForm PAD={PAD} setPAD={PAD} />
                      </Col>
                    )}
                  </div>
                )}
                {!openPADForm && (
                  <Col md={12}>
                    <PADForm PAD={PAD} setPAD={setPAD} />
                  </Col>
                )}
              </Row>
            </Grid>
            <Row>
              <Col md={12}>
                {!loading && (
                  <Button
                    bsStyle="primary"
                    bsSize="large"
                    blocktype="submit"
                    onClick={create}>
                    Submit Extra Payment
                  </Button>
                )}
                {loading && <div className="loader" style={{ margin: -10 }} />}
              </Col>
            </Row>
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
