import { Grid, Table, Col } from 'react-bootstrap';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import request from '../../../utils/Request';

class DetailsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'Loading...',
      notLoaded: true,
      ownerId: null
    };
  }

  componentDidMount() {
    this.getOwnerName();
  }

  getOwnerName() {
    if (this.state.notLoaded && this.props.property.ownerId) {
      request
        .get(`/ownerprofile/${this.props.property.ownerId}`)
        .then((owner) => {
          this.setState({
            name: owner.name,
            ownerId: owner.id,
            notLoaded: false
          });
        })
        .catch(() => {
          // do nothing
        });
    }
  }

  displayButtonIfAdmin() {
    const role = localStorage.getItem('role');
    if (role === 'admin' || role === 'superAdmin') {
      return (
        <Link
          className="btn btn-primary btn-xs pull-right"
          to={`/admin/edit/property/${this.props.property.id}`}>
          Edit Property
        </Link>
      );
    }
    return null;
  }

  render() {
    this.getOwnerName();
    return (
      <section className="section m-none">
        <Grid>
          <Col md={6}>
            <h3>Property Details</h3>
          </Col>
          <Col md={6}>{this.displayButtonIfAdmin()}</Col>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>Owner Name</td>
                <td>
                  {' '}
                  {localStorage.getItem('role') === 'admin' ||
                  localStorage.getItem('role') === 'superAdmin' ? (
                    <Link to={`/admin/owner/${this.state.ownerId}`}>
                      {' '}
                      {this.state.name}
                    </Link>
                  ) : (
                    this.state.name
                  )}
                </td>
              </tr>
              <tr>
                <td>Address</td>
                <td>
                  {this.props.property.street},{this.props.property.city}{' '}
                  {this.props.property.postal}
                </td>
              </tr>
              <tr>
                <td>Strata Plan</td>
                <td>
                  {this.props.property.strataPlan
                    ? this.props.property.strataPlan
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Square Footage</td>
                <td>{this.props.property.sqft}</td>
              </tr>
              <tr>
                <td>Bedrooms</td>
                <td>
                  {this.props.property.bedroom === 0
                    ? 'Studio'
                    : this.props.property.bedroom}
                </td>
              </tr>
              <tr>
                <td>Bathrooms</td>
                <td>
                  {this.props.property.bathroom}{' '}
                  {this.props.property.halfWashroom ? '+ Half Washroom' : ''}
                </td>
              </tr>
              <tr>
                <td>Den</td>
                <td>{this.props.property.den ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>Locker Number</td>
                <td>
                  {this.props.property.locker
                    ? this.props.property.locker
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Parking Stall Number(s)</td>
                <td>
                  {this.props.property.parking
                    ? this.props.property.parking
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Intercom</td>
                <td>{this.props.property.intercom ? 'Yes' : 'N/A'}</td>
              </tr>
              <tr>
                <td>Pool</td>
                <td>{this.props.property.pool ? 'Yes' : 'N/A'}</td>
              </tr>
              <tr>
                <td>Hot Tub</td>
                <td>{this.props.property.hottub ? 'Yes' : 'N/A'}</td>
              </tr>
              <tr>
                <td>Gym</td>
                <td>{this.props.property.gym ? 'Yes' : 'N/A'}</td>
              </tr>
              <tr>
                <td>Entertainment Room</td>
                <td>{this.props.property.entertainmentRoom ? 'Yes' : 'N/A'}</td>
              </tr>
              <tr>
                <td>Move in Fee</td>
                <td>
                  {this.props.property.moveInFee
                    ? this.props.property.moveInFee
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Move in Deposit</td>
                <td>
                  {this.props.property.moveInDeposit
                    ? this.props.property.moveInDeposit
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Move Out Fee</td>
                <td>
                  {this.props.property.moveOutFee
                    ? this.props.property.moveOutFee
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Property Taxes Paid By</td>
                <td>
                  {this.props.property.taxPaidBy
                    ? this.props.property.taxPaidBy
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Strata Fees Paid By</td>
                <td>
                  {this.props.property.strataPaidBy
                    ? this.props.property.strataPaidBy
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td>Insurance Paid By</td>
                <td>
                  {this.props.property.insurancePaidBy
                    ? this.props.property.insurancePaidBy
                    : 'N/A'}
                </td>
              </tr>
            </tbody>
          </Table>
        </Grid>
      </section>
    );
  }
}

export default DetailsTable;
