import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

class EditUser extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      password: '',
      newPassword: '',
      id: params.id,
      token: null,
      confirm: '',
      disabled: false,
      role: '',
      email: '',
      userId: null,
      error: false,
      errorMessage: '',
      roleList: ['Strata Owner', 'Strata Administrator']
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputPasswordChange = this.handleInputPasswordChange.bind(this);
    this.handleInputChangeRole = this.handleInputChangeRole.bind(this);

    this.notify = (text) =>
      toast(text, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser() {
    const {
      match: { params }
    } = this.props;
    let roleQueryString = '';
    this.state.roleList.forEach((r) => {
      roleQueryString += `rolesList=${r.replace(/ /g, '%20')}&`;
    });
    request.get(`/User/userRoleList?${roleQueryString}`).then((users) => {
      users.forEach((user) => {
        if (user.userID === params.id) {
          this.setState({
            role: user.roleName,
            email: user.userEmail,
            userId: user.userID
          });
        }
      });
    });
  }

  send(e) {
    e.preventDefault();
    this.setState({ disabled: true });
    request
      .post('/account/reset', {
        password: this.state.newPassword,
        id: this.state.id,
        token: this.state.token
      })
      .then(() => {
        this.notify('Password Reset Successfully');

        setTimeout(() => {
          // Start the timer
          this.props.history.push('/strataAdmin/user/list');
        }, 3000);
      })
      .catch((err) => {
        this.setState({
          error: true,
          errorMessage: err.message,
          disabled: false
        });
      });
  }

  getEmailValidationState() {
    if (this.state.userId !== 0) {
      return null;
    }
    if (this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      return 'success';
    }
    return 'error';
  }

  getPasswordValidationState() {
    const password = this.state.newPassword;
    const number = /\d/;
    const lowercase = /[a-z]/;
    const uppercase = /[A-Z]/;
    /* eslint-disable-next-line no-useless-escape */
    const specialchar = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    if (this.state.userId === 0) {
      return null;
    }
    if (
      number.test(password) &&
      lowercase.test(password) &&
      uppercase.test(password) &&
      specialchar.test(password)
    ) {
      return 'success';
    }
    if (this.state.newPassword === '' && this.state.confirm === '') {
      return 'warning';
    }
    return 'error';
  }

  getRoleValidationState() {
    if (
      this.props.userId !== 0 &&
      this.state.roleList.includes(this.props.role)
    ) {
      return 'success';
    }
    return 'error';
  }

  getUserValidationState() {
    if (
      this.getRoleValidationState() === 'success' &&
      this.getEmailValidationState() === 'success' &&
      this.getPasswordValidationState() === 'success'
    ) {
      return null;
    }
    if (this.state.userId !== 0) {
      return 'success';
    }
    return 'error';
  }

  showRoleOptions() {
    let helpblockr = <HelpBlock> </HelpBlock>;
    if (this.state.userId !== 0) {
      <HelpBlock> </HelpBlock>;
    } else if (this.state.role === '') {
      helpblockr = <HelpBlock>role is required</HelpBlock>;
    } else if (this.getRoleValidationState() !== 'success') {
      helpblockr = <HelpBlock>choose a role</HelpBlock>;
    }

    const listOfRoles = this.state.roleList.map((item) => (
      <option value={item}>{item}</option>
    ));
    return (
      <FormGroup>
        <ControlLabel>Role</ControlLabel>
        <FormControl
          componentClass="select"
          placeholder="select"
          name="role"
          value={this.state.roleName}
          onChange={this.handleInputChangeRole}>
          {listOfRoles}
        </FormControl>
        {helpblockr}
      </FormGroup>
    );
  }

  handleInputChangeUserID(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.state.userId = value;
    this.setState({
      userId: value,
      role: ''
    });
  }

  handleInputPasswordChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.state.email = value;
    this.setState({ email: value });
  }

  handleInputChangeRole(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.state.roleName = value;
    this.setState({ role: value });
  }

  render() {
    const password = this.state.newPassword;
    const { confirm } = this.state;
    let helpblockpw = <HelpBlock />;
    let helpblockpw1 = <HelpBlock />;
    let helpblockpw2 = <HelpBlock />;
    let helpblockpw3 = <HelpBlock />;
    let helpblockpw4 = <HelpBlock />;
    let helpblockpw5 = <HelpBlock />;
    let helpblockpw6 = <HelpBlock />;
    let helpblockpw7 = <HelpBlock />;
    let helpblockpw8 = <HelpBlock />;
    let helpblockpw9 = <HelpBlock />;
    let helpblockpw10 = <HelpBlock />;
    let helpblockpw11 = <HelpBlock />;

    const number = /\d/;
    const lowercase = /[a-z]/;
    const uppercase = /[A-Z]/;
    /* eslint-disable-next-line no-useless-escape */
    const specialchar = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    if (password.length <= 0 && this.getPasswordValidationState() === 'error') {
      helpblockpw = (
        <HelpBlock>password is required to create a user</HelpBlock>
      );
    } else {
      if (password.length < 8) {
        helpblockpw1 = (
          <HelpBlock>password should be at least 8 characters</HelpBlock>
        );
      }
      if (!number.test(password)) {
        helpblockpw2 = <HelpBlock>requires number</HelpBlock>;
      }
      if (!lowercase.test(password)) {
        helpblockpw3 = <HelpBlock>requires lowercase</HelpBlock>;
      }
      if (!uppercase.test(password)) {
        helpblockpw4 = <HelpBlock>requires uppercase</HelpBlock>;
      }
      if (!specialchar.test(password)) {
        helpblockpw5 = <HelpBlock>requires special character</HelpBlock>;
      }
      if (confirm !== password) {
        helpblockpw6 = <HelpBlock>passwords must match</HelpBlock>;
      }
    }
    if (confirm.length <= 0 && this.getPasswordValidationState() === 'error') {
      helpblockpw = (
        <HelpBlock>password is required to create a user</HelpBlock>
      );
    } else {
      if (confirm.length < 6) {
        helpblockpw7 = (
          <HelpBlock>password should be at least 6 characters</HelpBlock>
        );
      }
      if (!number.test(confirm)) {
        helpblockpw8 = <HelpBlock>requires number</HelpBlock>;
      }
      if (!lowercase.test(confirm)) {
        helpblockpw9 = <HelpBlock>requires lowercase</HelpBlock>;
      }
      if (!uppercase.test(confirm)) {
        helpblockpw10 = <HelpBlock>requires uppercase</HelpBlock>;
      }
      if (!specialchar.test(confirm)) {
        helpblockpw11 = <HelpBlock>requires special character</HelpBlock>;
      }
      if (confirm !== password) {
        helpblockpw6 = <HelpBlock>passwords must match</HelpBlock>;
      }
    }
    return (
      <div>
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3>Update {this.state.email}</h3>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Col md={12} style={{ paddingTop: 20 }}>
                  <h4>Update User Password</h4>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Col md={12}>
                  <Form>
                    <FormGroup style={{ display: 'none' }}>
                      <ControlLabel>New</ControlLabel>
                      <FormControl
                        name="password"
                        type="password"
                        placeholder="New Password"
                        value={this.state.password}
                        onChange={this.handleInputPasswordChange}
                      />
                    </FormGroup>
                    <FormGroup
                      validationState={this.getPasswordValidationState()}>
                      <ControlLabel>New</ControlLabel>
                      <FormControl
                        name="newPassword"
                        type="password"
                        placeholder="New Password"
                        value={this.state.newPassword}
                        onChange={this.handleInputPasswordChange}
                      />
                      {helpblockpw}
                      {helpblockpw1}
                      {helpblockpw2}
                      {helpblockpw3}
                      {helpblockpw4}
                      {helpblockpw5}
                    </FormGroup>
                    <FormGroup
                      validationState={this.getPasswordValidationState()}>
                      <ControlLabel>Confirm</ControlLabel>
                      <FormControl
                        name="confirm"
                        type="text"
                        placeholder="Confirm Password"
                        value={this.state.confirm}
                        onChange={this.handleInputPasswordChange}
                      />
                      {helpblockpw6}
                      {helpblockpw7}
                      {helpblockpw8}
                      {helpblockpw9}
                      {helpblockpw10}
                      {helpblockpw11}
                    </FormGroup>
                    <button
                      disabled={this.state.disabled}
                      type="submit"
                      onClick={this.send.bind(this)}
                      className="btn btn-primary">
                      Submit
                    </button>
                  </Form>
                </Col>
              </Row>
              <Row>
                {this.state.error ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}
              </Row>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}
export default EditUser;
