import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col, Button, Glyphicon } from 'react-bootstrap';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import request from '../../../utils/Request';
import StrataUnitTable from '../../utils/dataTable/StrataUnitTable';
import ErrorAlert from '../../utils/ErrorAlert';

class ArchivedStrataUnits extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      strataUnits: [],
      id: params.id,
      error: false,
      errorMessage: ''
    };

    this.getPrimaryUsername = (unitUsers) => {
      let returnName = 'No Primary User';
      unitUsers.forEach((unit) => {
        if (unit.strataUnitRole === 0) {
          returnName = unit.user.userName;
        }
      });
      return returnName;
    };

    this.getSecondaryUsername = (unitUsers) => {
      const returnArray = [];
      unitUsers.forEach((unit) => {
        if (unit.strataUnitRole !== 0) {
          returnArray.push(unit.user.userName);
        }
      });
      return returnArray.length > 0
        ? returnArray.toString()
        : 'No Secondary Users';
    };
  }

  componentDidMount() {
    this.loadStrataUnits();
  }

  restoreUnit(id) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to restore this property?')) {
      request
        .archive(`/StrataUnit/restore/${id}`)
        .then(() => {
          this.loadStrataUnits();
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Something went wrong, please try again later.'
          });
        });
    }
  }

  deleteDocumentsAndUnit(id) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Are you sure you want to delete this unit? All connected files will also be deleted. This CANNOT be undone.'
      )
    ) {
      const strataUnitInvitationData = {
        strataUnitId: id
      };

      request // this request needs to be completed first, otherwise there is a conflict with foreign key in the second request.
        .delete(
          `/strataUnitUserInvitation/delete/${id}`,
          strataUnitInvitationData
        )
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Error deleting associated strata invitation.'
          });
        });

      request
        .delete(`/StrataUnit/${id}`)
        .then(() => {
          this.loadStrataUnits();
        })
        .catch(() => {
          this.setState({ error: true, errorMessage: 'Error deleting unit.' });
        });
    }
  }

  loadStrataUnits() {
    request
      .get(`/strataUnit/strataProperty/${this.state.id}?archived=true`)
      .then((units) => {
        this.setState({
          strataUnits: units.map((u) => ({
            ...u,
            property_link: (
              <Link to={`/strataAdmin/strataUnit/${u.id}`}>
                {u.unitLocation}
              </Link>
            ),
            strataPlan: u.strataProperty.strataPlanNumber,
            primaryUsername: this.getPrimaryUsername(u.strataUnitUser),
            secondaryUsernames: this.getSecondaryUsername(u.strataUnitUser),
            button: (
              <div className="text-center">
                <Button
                  bsSize="xsmall"
                  style={{ marginRight: 2.5 }}
                  bsStyle="success"
                  onClick={() => {
                    this.restoreUnit(u.id);
                  }}>
                  <Glyphicon glyph="ok" />
                </Button>
                <Button
                  bsSize="xsmall"
                  style={{ marginRight: 2.5 }}
                  bsStyle="danger"
                  onClick={() => {
                    this.deleteDocumentsAndUnit(u.id);
                  }}>
                  <Glyphicon glyph="remove" />
                </Button>
              </div>
            )
          }))
        });
      });
  }

  render() {
    return (
      <div>
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Archived Strata Units</h3>
              </Col>
            </Row>
            <Row>
              {this.state.error ? (
                <ErrorAlert errorMessage={this.state.errorMessage} />
              ) : null}
            </Row>
          </Grid>

          <StrataUnitTable strataUnits={this.state.strataUnits} />
          <div className="row">
            <div className="col-md-12 center">
              <Link
                to={`/strataAdmin/property/${this.state.id}`}
                className="btn btn-outline btn-primary text-uppercase margin-bottom">
                Property
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArchivedStrataUnits;
