import React from 'react';
import ScrollToTop from '../../utils/ScrollToTop';
import SuperAdminHeader from './SuperAdminHeader';
import AdminHeader from './AdminHeader';

export default ({ children }) => {
  const role = localStorage.getItem('role');

  return (
    <div>
      {role === 'superAdmin' && <SuperAdminHeader />}
      {role === 'admin' && <AdminHeader />}
      <ScrollToTop>
        <main>{children}</main>
      </ScrollToTop>
    </div>
  );
};
