import React, { useState } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import request from '../../../utils/Request';
import StrataUnitUserForm from '../forms/StrataUnitUser';
import ErrorAlert from '../../utils/ErrorAlert';

export default ({ match, history }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [strataUnitUser, setStrataUnitUser] = useState({
    username: '',
    password: '',
    strataUnitRole: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: ''
  });
  const [strataUnitUserValid, setStrataUnitUserValid] = useState(false);
  const create = async (event) => {
    event.preventDefault();
    setLoading(true);

    const credentials = {
      userName: strataUnitUser.username,
      password: strataUnitUser.password,
      email: strataUnitUser.email
    };

    let user;
    let newUser;

    try {
      user = await request.get(`/user/${credentials.email}`);
    } catch (e) {
      setLoading(false);
      setError('Error retrieving user');
      return;
    }

    if (!user || user === 'No User') {
      try {
        newUser = await request.post(
          `/user?role=Strata Unit Owner&userName=${credentials.email}`,
          credentials
        );
      } catch (e) {
        setLoading(false);
        setError('Cannot leave blank fields');
        return;
      }
    }

    const updateUser = {
      id: user.id || newUser.user.id,
      strataUnitRole: strataUnitUser.strataUnitRole,
      username: strataUnitUser.username,
      phoneNumber: strataUnitUser.phoneNumber
    };

    const suUser = {
      userId: user.id || newUser.user.id,
      strataUnitRole: strataUnitUser.strataUnitRole,
      firstName: strataUnitUser.firstName,
      lastName: strataUnitUser.lastName,
      strataUnitId: match.params.id
    };

    try {
      const res = await request.put(`/user/${user.id}`, updateUser);
      if (res) {
        try {
          const unitUser = await request.post('/strataUnitUser/', suUser);
          setLoading(false);
          history.push(`/strataAdmin/strataUnit/${unitUser.strataUnitId}`);
        } catch (e) {
          setLoading(false);
          setError('Unit and User are already linked');
        }
      }
    } catch (e) {
      setLoading(false);
      setError(
        'Cannot leave email blank and password must follow the rules provided'
      );
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <StrataUnitUserForm
            edit={false}
            strataUnitUser={strataUnitUser}
            setStrataUnitUser={setStrataUnitUser}
            setValid={setStrataUnitUserValid}
          />
          <Grid>
            <Row>
              <Col md={12}>
                {!loading && (
                  <Button
                    bsStyle="primary"
                    bsSize="large"
                    block
                    disabled={!strataUnitUserValid}
                    type="submit"
                    onClick={create}>
                    Add Strata User
                  </Button>
                )}
                {loading && <div className="loader" />}
              </Col>
            </Row>
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
