import React, { Component } from 'react';
import {
  Modal,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  Button
} from 'react-bootstrap';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

class ReplaceOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: '',
      owner: {
        nameOnTitle: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        isCurrent: true,
        city: '',
        stateOrProvince: '',
        country: '',
        postalCode: ''
      },
      email: ''
    };
    this.handleUserInputChange = this.handleUserInputChange.bind(this);
    this.handleEmailInputChange = this.handleEmailInputChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.replace = this.replace.bind(this);
  }

  handleUserInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      owner: { ...prevState.owner, [name]: value }
    }));
  }

  handleEmailInputChange(event) {
    this.setState({ email: event.target.value });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      strataUnit: { ...prevState.strataUnit, [name]: value }
    }));
  }

  replace(e) {
    e.preventDefault();
    const strataUnitInvitation = {
      email: this.state.email,
      strataUnitId: this.props.strataUnitId,
      strataRole: 0
    };
    if (strataUnitInvitation.email === '') {
      this.setState({
        error: true,
        errorMessage: "Email can't be blank"
      });
      return;
    }
    if (this.state.owner.nameOnTitle === '') {
      this.setState({
        error: true,
        errorMessage: "Name on title can't be blank"
      });
      return;
    }

    request
      .post(`/strataOwner/replace/${this.props.strataUnitId}`, this.state.owner)
      .then((strataOwner) => {
        if (strataOwner) {
          strataUnitInvitation.strataOwnerId = strataOwner.id;
          request
            .post('/strataUnitUserInvitation', strataUnitInvitation)
            .then((invitation) => {
              request
                .post('/strataUnitUserInvitation/invite', invitation)
                .then(() => {
                  this.props.loadStrataUnits();
                  this.setState({
                    error: false,
                    errorMessage: ''
                  });
                  this.props.closeModal();
                });
            });
        }
      })
      .catch(() => {
        this.setState({
          error: true,
          errorMessage: 'Error updating strata unit'
        });
      });
  }

  render() {
    return (
      <Modal
        dialogClassName="custom-modal"
        size="lg"
        show={this.props.show}
        onHide={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Replace Current Registered Owner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <h3>Current Registered Owner</h3>
            </Col>
            {!this.props.strataOwner.nameOnTitle && (
              <Col md={12}>
                <p style={{ color: 'red' }}>
                  There is currently no Current Owner for this unit
                </p>
              </Col>
            )}
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Name On Title</ControlLabel>
                <FormControl
                  name="nameOnTitle"
                  placeholder="Name On Title"
                  value={this.props.strataOwner.nameOnTitle}
                  readOnly
                  onChange={this.handleRoleChange}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Strata Unit User Role</ControlLabel>
                <FormControl
                  name="strataRole"
                  placeholder="Strata Unit User Role"
                  value="2"
                  readOnly
                  onChange={this.handleRoleChange}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Address</ControlLabel>
                <FormControl
                  name="address"
                  type="text"
                  placeholder="Address"
                  readOnly
                  value="3"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mt-lg">
            <Col md={12}>
              <h3>Owner To Replace</h3>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Email</ControlLabel>
                <FormControl
                  name="email"
                  type="text"
                  placeholder="Email"
                  onChange={this.handleEmailInputChange}
                  value={this.state.email}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Name On Title</ControlLabel>
                <FormControl
                  name="nameOnTitle"
                  type="text"
                  placeholder="Name On Title"
                  value={this.state.owner.nameOnTitle}
                  onChange={this.handleUserInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Address Line 1</ControlLabel>
                <FormControl
                  name="addressLine1"
                  type="text"
                  placeholder="Address Line 1"
                  value={this.state.owner.addressLine1}
                  onChange={this.handleUserInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Address Line 2</ControlLabel>
                <FormControl
                  name="addressLine2"
                  type="text"
                  placeholder="Address Line 2"
                  value={this.state.owner.addressLine2}
                  onChange={this.handleUserInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Address Line 3</ControlLabel>
                <FormControl
                  name="addressLine3"
                  type="text"
                  placeholder="Address Line 3"
                  value={this.state.owner.addressLine3}
                  onChange={this.handleUserInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>City</ControlLabel>
                <FormControl
                  name="city"
                  type="text"
                  placeholder="City"
                  value={this.state.owner.city}
                  onChange={this.handleUserInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>State/Province</ControlLabel>
                <FormControl
                  name="stateOrProvince"
                  type="text"
                  placeholder="State/Province"
                  value={this.state.owner.stateOrProvince}
                  onChange={this.handleUserInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Country</ControlLabel>
                <FormControl
                  name="country"
                  type="text"
                  placeholder="Country"
                  value={this.state.owner.country}
                  onChange={this.handleUserInputChange}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Postal Code</ControlLabel>
                <FormControl
                  name="postalCode"
                  type="text"
                  placeholder="Postal Code"
                  value={this.state.owner.postalCode}
                  onChange={this.handleUserInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {this.state.error ? (
              <ErrorAlert errorMessage={this.state.errorMessage} />
            ) : null}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            bsSize="large"
            block
            type="submit"
            onClick={this.replace}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default ReplaceOwner;
