import React, { useEffect } from 'react';
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Row,
  HelpBlock,
  Checkbox
} from 'react-bootstrap';
import useValidation from '../../../validation/useValidation';
import {
  emailValidator,
  requiredValidator
} from '../../../validation/validators';

export default ({ strataOwner, setStrataOwner, setValid, property, unit }) => {
  const [validateName, nameValid, nameErrors] = useValidation([
    requiredValidator
  ]);
  const [validateEmail, emailValid, emailErrors] = useValidation([
    requiredValidator,
    emailValidator
  ]);
  const [validateAddress, addressValid, addressErrors] = useValidation([
    requiredValidator
  ]);
  const [validateCity, cityValid, cityErrors] = useValidation([
    requiredValidator
  ]);
  const [
    validateStateOrProvince,
    stateOrProvinceValid,
    stateOrProvinceErrors
  ] = useValidation([requiredValidator]);
  const [validateCountry, countryValid, countryErrors] = useValidation([
    requiredValidator
  ]);
  const [
    validatePostalCode,
    postalCodeValid,
    postalCodeErrors
  ] = useValidation([requiredValidator]);

  const propertyAddr = property.split(',');
  const propertyLine1 = unit.concat('-', propertyAddr[0]);
  const propertyCity = propertyAddr[1].trim();
  const propertyProv = propertyAddr[2].trim();
  const propertyPC = propertyAddr[3].trim();

  useEffect(() => {
    setValid(
      nameValid &&
        emailValid &&
        addressValid &&
        cityValid &&
        stateOrProvinceValid &&
        countryValid &&
        postalCodeValid
    );
  }, [
    emailValid,
    nameValid,
    setValid,
    addressValid,
    cityValid,
    stateOrProvinceValid,
    countryValid,
    postalCodeValid
  ]);

  const onChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setStrataOwner((prev) => ({
      ...prev,
      owner: { ...prev.owner, [name]: value }
    }));
  };

  const onEmailChange = (event) => {
    const { target } = event;
    setStrataOwner((prev) => ({ ...prev, email: target.value }));
  };

  const handleResidentOwner = (event) => {
    const { target } = event;

    if (target.checked) {
      setStrataOwner((prev) => ({
        ...prev,
        owner: {
          ...prev.owner,
          addressLine1: propertyLine1,
          city: propertyCity,
          stateOrProvince: propertyProv,
          postalCode: propertyPC,
          country: 'Canada'
        }
      }));
      validateAddress(propertyLine1);
      validateCity(propertyCity);
      validateStateOrProvince(propertyProv);
      validateCountry('Canada');
      validatePostalCode(propertyPC);
    } else {
      setStrataOwner((prev) => ({
        ...prev,
        owner: {
          ...prev.owner,
          addressLine1: '',
          city: '',
          stateOrProvince: '',
          postalCode: '',
          country: ''
        }
      }));
      validateAddress('');
      validateCity('');
      validateStateOrProvince('');
      validateCountry('');
      validatePostalCode('');
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Add Strata Unit Owner</h3>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col md={12}>
                  <Col md={12}>
                    <Checkbox
                      name="residentOwner"
                      onChange={handleResidentOwner}>
                      Resident Owner
                    </Checkbox>
                  </Col>
                  <Col md={5}>
                    <FormGroup validationState={nameErrors.length && 'error'}>
                      <ControlLabel>Name On Title</ControlLabel>
                      <FormControl
                        name="nameOnTitle"
                        type="text"
                        placeholder="Name On Title"
                        value={strataOwner.owner.nameOnTitle}
                        onChange={(event) => {
                          onChange(event);
                          validateName(event.target.value);
                        }}
                        onBlur={(event) => validateName(event.target.value)}
                      />
                      {nameErrors.map((error) => (
                        <HelpBlock key={error}>{error}</HelpBlock>
                      ))}
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup validationState={emailErrors.length && 'error'}>
                      <ControlLabel>Email</ControlLabel>
                      <FormControl
                        name="email"
                        type="text"
                        placeholder="Email"
                        value={strataOwner.email}
                        onChange={(event) => {
                          onEmailChange(event);
                          validateEmail(event.target.value);
                        }}
                        onBlur={(event) => validateEmail(event.target.value)}
                      />
                      {emailErrors.map((error) => (
                        <HelpBlock key={error}>{error}</HelpBlock>
                      ))}
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <ControlLabel>Strata Unit User Role</ControlLabel>
                      <FormControl
                        name="strataRole"
                        placeholder="Strata Unit User Role"
                        value="Current Owner"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup
                      validationState={addressErrors.length && 'error'}>
                      <ControlLabel>Address Line 1</ControlLabel>
                      <FormControl
                        name="addressLine1"
                        type="text"
                        placeholder="Address Line 1"
                        value={strataOwner.owner.addressLine1}
                        onChange={(event) => {
                          onChange(event);
                          validateAddress(event.target.value);
                        }}
                        onBlur={(event) => validateAddress(event.target.value)}
                      />
                      {addressErrors.map((error) => (
                        <HelpBlock key={error}>{error}</HelpBlock>
                      ))}
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <ControlLabel>Address Line 2</ControlLabel>
                      <FormControl
                        name="addressLine2"
                        type="text"
                        placeholder="Address Line 2"
                        value={strataOwner.owner.addressLine2}
                        onChange={onChange}
                        onBlur={onChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <ControlLabel>Address Line 3</ControlLabel>
                      <FormControl
                        name="addressLine3"
                        type="text"
                        placeholder="Address Line 3"
                        value={strataOwner.owner.addressLine3}
                        onChange={onChange}
                        onBlur={onChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup validationState={cityErrors.length && 'error'}>
                      <ControlLabel>City</ControlLabel>
                      <FormControl
                        name="city"
                        type="text"
                        placeholder="City"
                        value={strataOwner.owner.city}
                        onChange={(event) => {
                          onChange(event);
                          validateCity(event.target.value);
                        }}
                        onBlur={(event) => validateCity(event.target.value)}
                      />
                      {cityErrors.map((error) => (
                        <HelpBlock key={error}>{error}</HelpBlock>
                      ))}
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup
                      validationState={stateOrProvinceErrors.length && 'error'}>
                      <ControlLabel>State/Province</ControlLabel>
                      <FormControl
                        name="stateOrProvince"
                        type="text"
                        placeholder="State/Province"
                        value={strataOwner.owner.stateOrProvince}
                        onChange={(event) => {
                          onChange(event);
                          validateStateOrProvince(event.target.value);
                        }}
                        onBlur={(event) =>
                          validateStateOrProvince(event.target.value)
                        }
                      />
                      {stateOrProvinceErrors.map((error) => (
                        <HelpBlock key={error}>{error}</HelpBlock>
                      ))}
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup
                      validationState={countryErrors.length && 'error'}>
                      <ControlLabel>Country</ControlLabel>
                      <FormControl
                        name="country"
                        type="text"
                        placeholder="Country"
                        value={strataOwner.owner.country}
                        onChange={(event) => {
                          onChange(event);
                          validateCountry(event.target.value);
                        }}
                        onBlur={(event) => validateCountry(event.target.value)}
                      />
                      {countryErrors.map((error) => (
                        <HelpBlock key={error}>{error}</HelpBlock>
                      ))}
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup
                      validationState={postalCodeErrors.length && 'error'}>
                      <ControlLabel>Postal Code</ControlLabel>
                      <FormControl
                        name="postalCode"
                        type="text"
                        placeholder="Postal Code"
                        value={strataOwner.owner.postalCode}
                        onChange={(event) => {
                          onChange(event);
                          validatePostalCode(event.target.value);
                        }}
                        onBlur={(event) =>
                          validatePostalCode(event.target.value)
                        }
                      />
                      {postalCodeErrors.map((error) => (
                        <HelpBlock key={error}>{error}</HelpBlock>
                      ))}
                    </FormGroup>
                  </Col>
                </Col>
              </Row>
            </Form>
          </Grid>
        </div>
      </section>
    </div>
  );
};
