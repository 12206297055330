const NUMBER_REGEX = /\d/;
const LOWERCASE_REGEX = /[a-z]/;
const UPPERCASE_REGEX = /[A-Z]/;
const SPECIAL_CHAR_REGEX = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
const PHONE_NUMBER_REGEX = /^[/+]?[0-9]{3}[-\s/.]?[0-9]{3}[-\s/.]?[0-9]{3,6}$/;
const ADDRESS_REGEX = /^[a-zA-Z0-9\s,.'-]{3,}$/;
const EMAIL_REGEX = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

export const requiredValidator = (value) => {
  const errors = [];

  if (!value) {
    errors.push('this field is required');
  }

  return errors;
};

export const passwordValidator = (value) => {
  const errors = [];

  if (value && value.length < 6) {
    errors.push('password should be at least 6 characters');
  }
  if (!NUMBER_REGEX.test(value)) {
    errors.push('requires number');
  }
  if (!LOWERCASE_REGEX.test(value)) {
    errors.push('requires lowercase');
  }
  if (!UPPERCASE_REGEX.test(value)) {
    errors.push('requires uppercase');
  }
  if (!SPECIAL_CHAR_REGEX.test(value)) {
    errors.push('requires special character');
  }

  return errors;
};

export const emailValidator = (value) => {
  const errors = [];

  if (value && !value.match(EMAIL_REGEX)) {
    errors.push('invalid email');
  }

  return errors;
};

export const addressValidator = (value) => {
  const errors = [];

  if (value && !ADDRESS_REGEX.test(value)) {
    errors.push('invalid address');
  }

  return errors;
};

export const phoneValidator = (value) => {
  const errors = [];

  if (value && !value.match(PHONE_NUMBER_REGEX)) {
    errors.push('invalid phone number');
  }

  return errors;
};

export const numberValidator = (value) => {
  const errors = [];

  if (value && !value.match(NUMBER_REGEX)) {
    errors.push('invalid number');
  }

  return errors;
};

export const provinceValidator = (countryId) => {
  const errors = [];

  if (countryId !== '3') {
    errors.push('this field is required');
  }

  return errors;
};
