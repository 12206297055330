import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import Datatable from 'react-bs-datatable';

// Testing out datatables
const header =
  localStorage.getItem('role') === 'strataAdmin'
    ? [
        {
          title: 'Building Name',
          prop: 'property_link',
          sortable: true,
          filterable: true
        },
        { title: 'Address', prop: 'address', sortable: true, filterable: true },
        {
          title: 'Strata Plan #',
          prop: 'plan',
          sortable: true,
          filterable: true
        },
        {
          title: 'Strata Agent',
          prop: 'agent',
          sortable: true,
          filterable: true
        },
        {
          title: 'Strata Agent Phone',
          prop: 'agent_phone',
          sortable: true,
          filterable: true
        },
        { title: 'Action', prop: 'button', sortable: false }
      ]
    : [
        {
          title: 'Building Name',
          prop: 'property_link',
          sortable: true,
          filterable: true
        },
        { title: 'Address', prop: 'address', sortable: true, filterable: true },
        {
          title: 'Strata Plan #',
          prop: 'plan',
          sortable: true,
          filterable: true
        },
        {
          title: 'Strata Agent',
          prop: 'agent',
          sortable: true,
          filterable: true
        },
        {
          title: 'Strata Agent Phone',
          prop: 'agent_phone',
          sortable: true,
          filterable: true
        }
      ];
const onSortFunction = {
  date(columnValue) {
    // Convert the string date format to UTC timestamp
    // So the table could sort it by number instead of by string
    return new Date(columnValue);
  }
};

const StrataPropertyTable = (props) => (
  <div>
    <Grid>
      <Row>
        <Col md={12}>
          <h5>Properties</h5>
        </Col>
      </Row>
    </Grid>
    <Grid>
      <Row>
        <Col md={12}>
          <Datatable
            tableHeader={header}
            tableBody={props.properties}
            keyName="documentTable"
            tableClass="table table-striped table-bordered table-condensed table-hover"
            rowsPerPage={10}
            rowsPerPageOption={[5, 10, 15, 20, 50, 100]}
            initialSort={{ prop: 'date', isAscending: true }}
            onSort={onSortFunction}
          />
        </Col>
      </Row>
    </Grid>
  </div>
);

export default StrataPropertyTable;
