import { Col, Grid, Row, Table } from 'react-bootstrap';
import React, { Component } from 'react';

class StrataCompanyTable extends Component {
  render() {
    let html = '';

    if (this.props.property.strataCompany) {
      html = (
        <section className="section m-top">
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Strata Management Company</h3>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{this.props.property.strataCompany}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{this.props.property.strataAddress}</td>
                    </tr>
                    <tr>
                      <td>Strata Agent</td>
                      <td>{this.props.property.strataAgent}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{this.props.property.strataPhone}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{this.props.property.strataEmail}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Grid>
        </section>
      );
    }
    return html;
  }
}

export default StrataCompanyTable;
