import React, { Component } from 'react';
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Row
} from 'react-bootstrap';

class OwnerInfoSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        date: '',
        sig1: '',
        sig2: '',
        buildingInfo: {
          buildingName: '',
          buildingAddress: '',
          unitNumber: '',
          strataLotNumber: '',
          strataPlanNumber: ''
        },
        unitInfo: {
          parkingStall1: '',
          parkingStall2: '',
          parkingStall3: '',
          parkingStall4: '',
          locker1: '',
          locker2: '',
          locker3: '',
          locker4: '',
          fob1: '',
          fob2: '',
          fob3: '',
          fob4: ''
        },
        registeredOwnerInfo: {
          firstName: '',
          lastName: '',
          homePhone: '',
          cellPhone: '',
          workPhone: '',
          city: '',
          province: '',
          postalCode: '',
          email: '',
          address: ''
        },
        emergencyContactInfo: {
          firstName: '',
          lastName: '',
          homePhone: '',
          cellPhone: '',
          workPhone: '',
          city: '',
          province: '',
          postalCode: '',
          email: '',
          address: ''
        }
      }
    };
    this.handleEmergencyContactInfoChange = this.handleEmergencyContactInfoChange.bind(
      this
    );
    this.handleRegisteredOwnerInfoChange = this.handleRegisteredOwnerInfoChange.bind(
      this
    );
    this.handleUnitInfoChange = this.handleUnitInfoChange.bind(this);
    this.handleBuildingInfoInputChange = this.handleBuildingInfoInputChange.bind(
      this
    );
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleEmergencyContactInfoChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        emergencyContactInfo: {
          ...prevState.form.emergencyContactInfo,
          [name]: value
        }
      }
    }));
  }

  handleRegisteredOwnerInfoChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        registeredOwnerInfo: {
          ...prevState.form.registeredOwnerInfo,
          [name]: value
        }
      }
    }));
  }

  handleUnitInfoChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        unitInfo: { ...prevState.form.unitInfo, [name]: value }
      }
    }));
  }

  handleBuildingInfoInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        buildingInfo: { ...prevState.form.buildingInfo, [name]: value }
      }
    }));
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      form: { ...prevState.form, [name]: value }
    }));
  }

  render() {
    return (
      <div>
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h1>Information Sheet & Emergency Contact List</h1>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <h3>Building Information</h3>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Building Name</ControlLabel>
                    <FormControl
                      name="buildingName"
                      type="text"
                      placeholder="Building Name"
                      value={this.state.form.buildingInfo.buildingName}
                      onChange={this.handleBuildingInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Building Address</ControlLabel>
                    <FormControl
                      name="buildingAddress"
                      type="text"
                      placeholder="Building Address"
                      value={this.state.form.buildingInfo.buildingAddress}
                      onChange={this.handleBuildingInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Strata Plan Number</ControlLabel>
                    <FormControl
                      name="strataPlanNumber"
                      type="text"
                      placeholder="Strata Plan Number"
                      value={this.state.form.buildingInfo.strataPlanNumber}
                      onChange={this.handleBuildingInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Strata Lot Number</ControlLabel>
                    <FormControl
                      name="strataLotNumber"
                      type="text"
                      placeholder="Strata Lot Number"
                      value={this.state.form.buildingInfo.strataLotNumber}
                      onChange={this.handleBuildingInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Unit Number</ControlLabel>
                    <FormControl
                      name="unitNumber"
                      type="text"
                      placeholder="Unit Number"
                      value={this.state.form.buildingInfo.unitNumber}
                      onChange={this.handleBuildingInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <h3>Unit Information</h3>
              </Row>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Parking Stall #01</ControlLabel>
                    <FormControl
                      name="parkingStall1"
                      type="text"
                      placeholder="Parking Stall #01"
                      value={this.state.form.unitInfo.parkingStall1}
                      onChange={this.handleUnitInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Parking Stall #02</ControlLabel>
                    <FormControl
                      name="parkingStall2"
                      type="text"
                      placeholder="Parking Stall #02"
                      value={this.state.form.unitInfo.parkingStall2}
                      onChange={this.handleUnitInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Parking Stall #03</ControlLabel>
                    <FormControl
                      name="parkingStall3"
                      type="text"
                      placeholder="Parking Stall #03"
                      value={this.state.form.unitInfo.parkingStall3}
                      onChange={this.handleUnitInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Parking Stall #04</ControlLabel>
                    <FormControl
                      name="parkingStall4"
                      type="text"
                      placeholder="Parking Stall #04"
                      value={this.state.form.unitInfo.parkingStall4}
                      onChange={this.handleUnitInfoChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Locker #01</ControlLabel>
                    <FormControl
                      name="locker1"
                      type="text"
                      placeholder="Locker #01"
                      value={this.state.form.unitInfo.locker1}
                      onChange={this.handleUnitInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Locker #02</ControlLabel>
                    <FormControl
                      name="locker2"
                      type="text"
                      placeholder="Locker #02"
                      value={this.state.form.unitInfo.locker2}
                      onChange={this.handleUnitInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Locker #03</ControlLabel>
                    <FormControl
                      name="locker3"
                      type="text"
                      placeholder="Locker #03"
                      value={this.state.form.unitInfo.locker3}
                      onChange={this.handleUnitInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Locker #04</ControlLabel>
                    <FormControl
                      name="locker4"
                      type="text"
                      placeholder="Locker #04"
                      value={this.state.form.unitInfo.locker4}
                      onChange={this.handleUnitInfoChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Fob #01</ControlLabel>
                    <FormControl
                      name="fob1"
                      type="text"
                      placeholder="Fob #01"
                      value={this.state.form.unitInfo.fob1}
                      onChange={this.handleUnitInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Fob #02</ControlLabel>
                    <FormControl
                      name="fob2"
                      type="text"
                      placeholder="Fob #02"
                      value={this.state.form.unitInfo.fob2}
                      onChange={this.handleUnitInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Fob #03</ControlLabel>
                    <FormControl
                      name="fob3"
                      type="text"
                      placeholder="Fob #03"
                      value={this.state.form.unitInfo.fob3}
                      onChange={this.handleUnitInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <ControlLabel>Fob #04</ControlLabel>
                    <FormControl
                      name="fob4"
                      type="text"
                      placeholder="Fob #04"
                      value={this.state.form.unitInfo.fob4}
                      onChange={this.handleUnitInfoChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <h3>Registered Owner(s) Information</h3>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>First Name</ControlLabel>
                    <FormControl
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      value={this.state.form.registeredOwnerInfo.firstName}
                      onChange={this.handleRegisteredOwnerInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Last Name</ControlLabel>
                    <FormControl
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      value={this.state.form.registeredOwnerInfo.lastName}
                      onChange={this.handleRegisteredOwnerInfoChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                      name="email"
                      type="text"
                      placeholder="Email"
                      value={this.state.form.registeredOwnerInfo.email}
                      onChange={this.handleRegisteredOwnerInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Address</ControlLabel>
                    <FormControl
                      name="address"
                      type="text"
                      placeholder="Address"
                      value={this.state.form.registeredOwnerInfo.address}
                      onChange={this.handleRegisteredOwnerInfoChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>City</ControlLabel>
                    <FormControl
                      name="city"
                      type="text"
                      placeholder="City"
                      value={this.state.form.registeredOwnerInfo.city}
                      onChange={this.handleRegisteredOwnerInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Province</ControlLabel>
                    <FormControl
                      name="province"
                      type="text"
                      placeholder="Province"
                      value={this.state.form.registeredOwnerInfo.province}
                      onChange={this.handleRegisteredOwnerInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Postal Code</ControlLabel>
                    <FormControl
                      name="postalCode"
                      type="text"
                      placeholder="Postal Code"
                      value={this.state.form.registeredOwnerInfo.postalCode}
                      onChange={this.handleRegisteredOwnerInfoChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Home Phone</ControlLabel>
                    <FormControl
                      name="homePhone"
                      type="text"
                      placeholder="Home Phone"
                      value={this.state.form.registeredOwnerInfo.homePhone}
                      onChange={this.handleRegisteredOwnerInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Cell Phone</ControlLabel>
                    <FormControl
                      name="cellPhone"
                      type="text"
                      placeholder="Cell Phone"
                      value={this.state.form.registeredOwnerInfo.cellPhone}
                      onChange={this.handleRegisteredOwnerInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Work Phone</ControlLabel>
                    <FormControl
                      name="workPhone"
                      type="text"
                      placeholder="Work Phone"
                      value={this.state.form.registeredOwnerInfo.workPhone}
                      onChange={this.handleRegisteredOwnerInfoChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <h3>Emergency Contact Information</h3>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>First Name</ControlLabel>
                    <FormControl
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      value={this.state.form.emergencyContactInfo.firstName}
                      onChange={this.handleEmergencyContactInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Last Name</ControlLabel>
                    <FormControl
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      value={this.state.form.emergencyContactInfo.lastName}
                      onChange={this.handleEmergencyContactInfoChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                      name="email"
                      type="text"
                      placeholder="Email"
                      value={this.state.form.emergencyContactInfo.email}
                      onChange={this.handleEmergencyContactInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Address</ControlLabel>
                    <FormControl
                      name="address"
                      type="text"
                      placeholder="Address"
                      value={this.state.form.emergencyContactInfo.address}
                      onChange={this.handleEmergencyContactInfoChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>City</ControlLabel>
                    <FormControl
                      name="city"
                      type="text"
                      placeholder="City"
                      value={this.state.form.emergencyContactInfo.city}
                      onChange={this.handleEmergencyContactInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Province</ControlLabel>
                    <FormControl
                      name="province"
                      type="text"
                      placeholder="Province"
                      value={this.state.form.emergencyContactInfo.province}
                      onChange={this.handleEmergencyContactInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Postal Code</ControlLabel>
                    <FormControl
                      name="postalCode"
                      type="text"
                      placeholder="Postal Code"
                      value={this.state.form.emergencyContactInfo.postalCode}
                      onChange={this.handleEmergencyContactInfoChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Home Phone</ControlLabel>
                    <FormControl
                      name="homePhone"
                      type="text"
                      placeholder="Home Phone"
                      value={this.state.form.emergencyContactInfo.homePhone}
                      onChange={this.handleEmergencyContactInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Cell Phone</ControlLabel>
                    <FormControl
                      name="cellPhone"
                      type="text"
                      placeholder="Cell Phone"
                      value={this.state.form.emergencyContactInfo.cellPhone}
                      onChange={this.handleEmergencyContactInfoChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Work Phone</ControlLabel>
                    <FormControl
                      name="workPhone"
                      type="text"
                      placeholder="Work Phone"
                      value={this.state.form.emergencyContactInfo.workPhone}
                      onChange={this.handleEmergencyContactInfoChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <h3>Date and Signature(s)</h3>
              </Row>
              <Row>
                <Col md={4}>
                  <ControlLabel>Date</ControlLabel>
                  <FormControl
                    name="date"
                    type="text"
                    placeholder="Date"
                    value={this.state.form.date}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={4}>
                  <ControlLabel>Signature</ControlLabel>
                  <FormControl
                    name="sig1"
                    type="text"
                    placeholder="Signature"
                    value={this.state.form.sig1}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={4}>
                  <ControlLabel>Signature</ControlLabel>
                  <FormControl
                    name="sig2"
                    type="text"
                    placeholder="Signature"
                    value={this.state.form.sig2}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}
export default OwnerInfoSheet;
