import React, { Component } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import request from '../../../../utils/Request';
import DocumentForm from '../../forms/documents/ownerInfoSheet';
import ErrorAlert from '../../../utils/ErrorAlert';

class SUAdminBylawOwnerInfoSheetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: '',
      property: {}
    };
    this.strataUnitRef = React.createRef();

    this.create = this.create.bind(this);
  }

  componentDidMount() {
    this.loadProperty();
  }

  loadProperty() {
    const {
      match: { params }
    } = this.props;
    request.get(`/strataproperty/${params.id}`).then((property) => {
      this.setState({
        property
      });
    });
  }

  create(e) {
    e.preventDefault();
    const data = this.strataUnitRef.state.strataUnit;
    const strataUnit = {
      unitLocation: data.unitLocation,
      strataLotNumber: data.strataLotNumber,
      strataPropertyId: this.state.property.id
    };
    request
      .post('/strataUnit/', strataUnit)
      .then(() => {
        this.props.history.push(`/strataAdmin/strataUnit/${strataUnit.id}`);
      })
      .catch(() => {
        this.setState({ error: true, errorMessage: 'Error creating property' });
      });
  }

  render() {
    return (
      <div>
        <section className="section m-none">
          <div>
            <DocumentForm
              strataProperty={this.state.property}
              ref={this.strataUnitRef}
            />
            <Grid>
              <Row>
                <Col md={12}>
                  <Button
                    bsStyle="primary"
                    bsSize="large"
                    block
                    type="submit"
                    onClick={this.create}>
                    Create Document
                  </Button>
                </Col>
              </Row>
              <Row>
                {this.state.error ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}
              </Row>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

export default SUAdminBylawOwnerInfoSheetForm;
