import React, { useEffect, useState } from 'react';
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';
import { ToastContainer } from 'react-toastify';
import useValidation from '../../../validation/useValidation';
import {
  passwordValidator,
  requiredValidator
} from '../../../validation/validators';

export default ({
  strataProperty,
  setStrataProperty,
  newUser,
  setNewUser,
  setValid
}) => {
  const [searchAddress, setSearchAddress] = useState('');
  const [
    validateBuildingName,
    buildingNameValid,
    buildingNameErrors
  ] = useValidation([requiredValidator]);
  const [validateCity, cityValid, cityErrors] = useValidation([
    requiredValidator
  ]);
  const [validateProvince, provinceValid, provinceErrors] = useValidation([
    requiredValidator
  ]);
  const [validatePostal, postalValid, postalErrors] = useValidation([
    requiredValidator
  ]);
  const [validateStreet, streetValid, streetErrors] = useValidation([
    requiredValidator
  ]);
  const [
    validateStrataPlanNumber,
    strataPlanNumberValid,
    strataPlanNumberErrors
  ] = useValidation([requiredValidator]);
  const [validatePassword, passwordValid, passwordErrors] = useValidation([
    requiredValidator,
    passwordValidator
  ]);

  // compute validity every time a validation changes
  useEffect(() => {
    setValid(
      buildingNameValid &&
        cityValid &&
        provinceValid &&
        postalValid &&
        streetValid &&
        strataPlanNumberValid &&
        passwordValid
    );
  }, [
    setValid,
    buildingNameValid,
    cityValid,
    passwordValid,
    postalValid,
    provinceValid,
    strataPlanNumberValid,
    streetValid
  ]);

  const onStrataPropertyChange = (event) => {
    const { name, type, value, checked } = event.target;
    setStrataProperty((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));

    if (name === 'strataPlanNumber') {
      const trimmedVal = value.trim();
      setNewUser((prev) => ({ ...prev, email: `${trimmedVal}@korecki.ca` }));
    }
  };

  const onUserChange = (event) => {
    const { name, type, value, checked } = event.target;
    setNewUser((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const inputProps = {
    value: searchAddress,
    onChange: setSearchAddress,
    placeholder: 'Search Address'
  };

  const cssClasses = {
    root: 'form-group',
    input: 'form-control',
    autocompleteContainer: 'my-autocomplete-container'
  };

  const renderSuggestion = ({ suggestion }) => (
    <div>
      <i className="fa fa-map-marker" /> {suggestion}
    </div>
  );

  function findAddressComponent(address, component) {
    const addressComp = address[0].address_components.filter(
      (a) => a.types[0] === component
    );
    if (!addressComp[0]) {
      return null;
    }
    return addressComp[0].long_name;
  }

  const searchSelect = async (address) => {
    setSearchAddress(address);
    const results = await geocodeByAddress(address);
    setStrataProperty((prev) => {
      const postal = findAddressComponent(results, 'postal_code');
      const streetNumber = findAddressComponent(results, 'street_number');
      const route = findAddressComponent(results, 'route');
      const city = findAddressComponent(results, 'locality');
      const province = findAddressComponent(
        results,
        'administrative_area_level_1'
      );
      const country = findAddressComponent(results, 'country');

      const property = {
        ...prev,
        address,
        street: route,
        city,
        province,
        postal
      };

      if (streetNumber) {
        property.street = streetNumber.concat(' ', route);
      }

      if (country === 'USA' || country === 'US') {
        property.countryId = 2;
      } else if (country === 'Canada') {
        property.countryId = 1;
      }

      return property;
    });
  };

  // validations for non-user inputted fields
  useEffect(() => {
    if (searchAddress) {
      validateStreet(strataProperty.street);
      validateCity(strataProperty.city);
      validateProvince(strataProperty.province);
      validatePostal(strataProperty.postal);
    }
  }, [
    searchAddress,
    strataProperty,
    validateStreet,
    validateCity,
    validateProvince,
    validatePostal
  ]);

  return (
    <div>
      <ToastContainer />
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Add Strata Property</h3>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Col md={6}>
                <FormGroup
                  validationState={buildingNameErrors.length && 'error'}>
                  <ControlLabel>Building Name</ControlLabel>
                  <FormControl
                    name="buildingName"
                    type="text"
                    placeholder="Building Name"
                    value={strataProperty.buildingName}
                    onChange={(event) => {
                      onStrataPropertyChange(event);
                      validateBuildingName(event.target.value);
                    }}
                    onBlur={(event) => validateBuildingName(event.target.value)}
                  />
                  {buildingNameErrors.map((error) => (
                    <HelpBlock key={error}>{error}</HelpBlock>
                  ))}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup
                  validationState={strataPlanNumberErrors.length && 'error'}>
                  <ControlLabel>Strata Plan # </ControlLabel>
                  <FormControl
                    name="strataPlanNumber"
                    type="text"
                    placeholder="Strata Plan"
                    value={strataProperty.strataPlanNumber}
                    onChange={(event) => {
                      onStrataPropertyChange(event);
                      validateStrataPlanNumber(event.target.value);
                    }}
                    onBlur={(event) =>
                      validateStrataPlanNumber(event.target.value)
                    }
                  />
                  {strataPlanNumberErrors.map((error) => (
                    <HelpBlock key={error}>{error}</HelpBlock>
                  ))}
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>
                    Full Address (Required for Map Functionality)
                  </ControlLabel>
                  <PlacesAutocomplete
                    inputProps={inputProps}
                    classNames={cssClasses}
                    renderSuggestion={renderSuggestion}
                    onChange={setSearchAddress}
                    onSelect={searchSelect}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup validationState={streetErrors.length && 'error'}>
                  <ControlLabel>Street</ControlLabel>
                  <FormControl
                    name="street"
                    type="text"
                    placeholder="street"
                    value={strataProperty.street}
                    onChange={(event) => {
                      onStrataPropertyChange(event);
                      validateStreet(event.target.value);
                    }}
                    onBlur={(event) => validateStreet(event.target.value)}
                  />
                  {streetErrors.map((error) => (
                    <HelpBlock key={error}>{error}</HelpBlock>
                  ))}
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup validationState={cityErrors.length && 'error'}>
                  <ControlLabel>City</ControlLabel>
                  <FormControl
                    name="city"
                    type="text"
                    placeholder="City"
                    value={strataProperty.city}
                    onChange={(event) => {
                      onStrataPropertyChange(event);
                      validateCity(event.target.value);
                    }}
                    onBlur={(event) => validateCity(event.target.value)}
                  />
                  {cityErrors.map((error) => (
                    <HelpBlock key={error}>{error}</HelpBlock>
                  ))}
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup validationState={provinceErrors.length && 'error'}>
                  <ControlLabel>Province / State</ControlLabel>
                  <FormControl
                    name="province"
                    type="text"
                    placeholder="Province / State"
                    value={strataProperty.province}
                    onChange={(event) => {
                      onStrataPropertyChange(event);
                      validateProvince(event.target.value);
                    }}
                    onBlur={(event) => validateProvince(event.target.value)}
                  />
                  {provinceErrors.map((error) => (
                    <HelpBlock key={error}>{error}</HelpBlock>
                  ))}
                </FormGroup>
              </Col>
              <Col md={2}>
                <ControlLabel>Country</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="select"
                  name="countryId"
                  value={strataProperty.countryId}
                  onChange={onStrataPropertyChange}>
                  <option value="1">Canada</option>
                  <option value="2">U.S</option>
                  <option value="3">Other</option>
                </FormControl>
              </Col>
              <Col md={2}>
                <FormGroup validationState={postalErrors.length && 'error'}>
                  <ControlLabel>Postal</ControlLabel>
                  <FormControl
                    name="postal"
                    type="text"
                    placeholder="Postal"
                    value={strataProperty.postal}
                    onChange={(event) => {
                      onStrataPropertyChange(event);
                      validatePostal(event.target.value);
                    }}
                    onBlur={(event) => validatePostal(event.target.value)}
                  />
                  {postalErrors.map((error) => (
                    <HelpBlock key={error}>{error}</HelpBlock>
                  ))}
                </FormGroup>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Col md={12}>
                <h4>Create Login</h4>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Col md={12}>
                <Form>
                  <Col md={4} style={{ paddingLeft: '0px' }}>
                    <FormGroup
                      validationState={
                        strataPlanNumberErrors.length && 'error'
                      }>
                      <ControlLabel>Strata Plan / Username </ControlLabel>
                      <FormControl
                        name="strataPlanNumber"
                        type="text"
                        placeholder="Strata Plan"
                        value={strataProperty.strataPlanNumber}
                        onChange={(event) => {
                          onStrataPropertyChange(event);
                          validateStrataPlanNumber(event.target.value);
                        }}
                        onBlur={(event) =>
                          validateStrataPlanNumber(event.target.value)
                        }
                      />
                      {strataPlanNumberErrors.map((error) => (
                        <HelpBlock key={error}>{error}</HelpBlock>
                      ))}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup
                      validationState={passwordErrors.length && 'error'}>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl
                        name="password"
                        type="password"
                        placeholder="Password"
                        value={newUser.password}
                        onChange={(event) => {
                          onUserChange(event);
                          validatePassword(event.target.value);
                        }}
                        onBlur={(event) => validatePassword(event.target.value)}
                      />
                      {passwordErrors.map((error) => (
                        <HelpBlock key={error}>{error}</HelpBlock>
                      ))}
                    </FormGroup>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
