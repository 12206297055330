import React, { useState } from 'react';
import { Button, Col, Grid, Row } from 'react-bootstrap';
import request from '../../../utils/Request';
import StrataPropertyForm from '../forms/StrataProperty';
import ErrorAlert from '../../utils/ErrorAlert';

export default ({ history }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [strataProperty, setStrataProperty] = useState({
    address: '',
    street: '',
    city: '',
    postal: '',
    province: '',
    countryId: 1,
    strataPlanNumber: '',
    buildingName: ''
  });
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
    userName: ''
  });
  const [valid, setValid] = useState(false);

  const saveStrataProperty = async (userId) => {
    const data = { ...strataProperty, propertyAdminId: userId };
    try {
      const property = await request.post('/strataproperty/', data);
      if (property) {
        history.push(`/strataAdmin/property/${property.id}`);
      }
    } catch (e) {
      setError('Error creating property');
    }
  };

  const create = async (event) => {
    event.preventDefault();

    if (!strataProperty.strataPlanNumber || !newUser.password) {
      setError('Failed To Save Property, Please enter a valid user.');
      return;
    }

    try {
      setLoading(true);
      const res = await request.post(
        `/user?role=Strata Owner&userName=${strataProperty.strataPlanNumber.trim()}`,
        newUser
      );

      setLoading(false);

      if (res && res.user && res.user.id) {
        saveStrataProperty(res.user.id);
      } else {
        setError(`Error creating new user${res}${res.id}`);
      }
    } catch (e) {
      setLoading(false);
      setError('Error creating new user. User may already exist.');
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <StrataPropertyForm
            strataProperty={strataProperty}
            newUser={newUser}
            setStrataProperty={setStrataProperty}
            setNewUser={setNewUser}
            setValid={setValid}
          />
          <Grid>
            <Row>
              <Col md={12}>
                {!loading && (
                  <Button
                    bsStyle="primary"
                    bsSize="large"
                    block
                    type="submit"
                    onClick={create}
                    disabled={!valid}>
                    Create Strata Property
                  </Button>
                )}
                {loading && <div className="loader" />}
              </Col>
            </Row>
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
