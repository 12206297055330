import React, { Component } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';

import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Checkbox,
  Button,
  InputGroup
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

function findAddressComponent(address, component) {
  const addressComp = address[0].address_components.filter(
    (a) => a.types[0] === component
  );
  if (!addressComp[0]) {
    return null;
  }
  return addressComp[0].long_name;
}

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: '',
      property: {},
      searchAddress: '',
      originalContractType: '',
      loading: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.update = this.update.bind(this);
    this.searchSelect = this.searchSelect.bind(this);
    this.updateAddress = this.updateAddress.bind(this);

    this.notify = () => {
      toast('property Saved', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: css({
          background: 'black'
        })
      });
    };
  }

  loadProperty() {
    const {
      match: { params }
    } = this.props;
    request.get(`/property/${params.id}`).then((property) => {
      this.setState(
        {
          property,
          originalContractType: property.contractType
        },
        () => {
          this.getRMCount();
        }
      );
    });
  }

  async searchSelect(address) {
    const searchAddress = address;
    this.setState({ searchAddress });
    const results = await geocodeByAddress(address);

    const streetNumber = findAddressComponent(results, 'street_number');
    let route = findAddressComponent(results, 'route');
    const city = findAddressComponent(results, 'locality');

    if (streetNumber) {
      route = streetNumber.concat(' ', route);
    }

    this.setState((prevState) => ({
      property: {
        ...prevState.property,
        address,
        street: route,
        city
      }
    }));
  }

  updateAddress(searchAddress) {
    this.setState({ searchAddress });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      property: { ...prevState.property, [name]: value }
    }));
  }

  componentDidMount() {
    this.loadProperty();
  }

  getRMCount() {
    const { ownerId } = this.state.property;
    request.get(`/property/owner/${ownerId}/${'RM'}`).then((count) => {
      this.setState({ RMCount: count }, () => {
        this.getCMCount();
      });
    });
  }

  getCMCount() {
    const { ownerId } = this.state.property;
    request.get(`/property/owner/${ownerId}/${'CM'}`).then((count) => {
      this.setState({ CMCount: count }, () => {
        this.getLUCount();
      });
    });
  }

  getLUCount() {
    const { ownerId } = this.state.property;
    request.get(`/property/owner/${ownerId}/LU`).then((count) => {
      this.setState({ LUCount: count }, () => {
        this.getRBCount();
      });
    });
  }

  getRBCount() {
    const { ownerId } = this.state.property;
    request.get(`/property/owner/${ownerId}/RB`).then((count) => {
      this.setState({ RBCount: count });
    });
  }

  getValidStates(name) {
    const currency = /^\d+(?:\.\d{0,2})$/;
    switch (name) {
      case 'street':
        if (/^[a-zA-Z0-9\s,.'-]{3,}$/.test(this.state.property.street)) {
          return 'success';
        }
        return 'error';

      case 'bathroom':
        if (/^\d+$/.test(this.state.property.bathroom)) {
          return 'success';
        }
        return 'error';

      case 'bedroom':
        if (/^\d+$/.test(this.state.property.bedroom)) {
          return 'success';
        }
        return 'error';

      case 'moveInFee':
        if (
          currency.test(this.state.property.moveInFee) ||
          /^\d+$/.test(this.state.property.moveInFee)
        ) {
          return 'success';
        }
        return 'error';

      case 'moveOutFee':
        if (
          currency.test(this.state.property.moveOutFee) ||
          /^\d+$/.test(this.state.property.moveOutFee)
        ) {
          return 'success';
        }
        return 'error';

      case 'moveInDeposit':
        if (
          currency.test(this.state.property.moveInDeposit) ||
          /^\d+$/.test(this.state.property.moveInDeposit)
        ) {
          return 'success';
        }
        return 'error';

      default:
        return null;
    }
  }

  setPlaceholder() {
    if (this.state.property.ownerId) {
      const id = this.state.property.ownerId;
      const { length } = id.toString();
      const type = this.state.property.contractType;
      const propertyCount = this.getPropertyCount(type);

      switch (length) {
        case 1:
          return `${type}-00${id}-${propertyCount}`;
        case 2:
          return `${type}-0${id}-${propertyCount}`;
        default:
          return `${type}-${id}-${propertyCount}`;
      }
    }
    return null;
  }

  getPropertyCount(type) {
    switch (type) {
      case 'RM':
        if (type === this.state.originalContractType) {
          return this.state.RMCount;
        }
        // we must add 1 to indicate a new type of property.
        return this.state.RMCount + 1;

      case 'CM':
        if (type === this.state.originalContractType) {
          return this.state.CMCount;
        }
        return this.state.CMCount + 1;

      case 'LU':
        if (type === this.state.originalContractType) {
          return this.state.LUCount;
        }
        return this.state.LUCount + 1;

      case 'RB':
        if (type === this.state.originalContractType) {
          return this.state.RBCount;
        }
        return this.state.RBCount + 1;
      default:
        return null;
    }
  }

  ifParkingAvailable() {
    if (this.state.property.parkingAvailable) {
      return (
        <div>
          <Col md={4}>
            <ControlLabel># Of parking stalls</ControlLabel>
            <InputGroup>
              <FormControl
                name="numberOfParkingSpots"
                type="number"
                placeholder="# of Parking Spots"
                value={this.state.property.numberOfParkingSpots}
                onChange={this.handleInputChange}
              />
            </InputGroup>
          </Col>
          <Col md={4}>
            <ControlLabel>Parking Stall # (Seperated By Commas)</ControlLabel>
            <InputGroup>
              <FormControl
                name="parking"
                type="string"
                placeholder="Parking Stall Numbers"
                value={this.state.property.parking}
                onChange={this.handleInputChange}
              />
            </InputGroup>
          </Col>
        </div>
      );
    }
    return null;
  }

  update(e) {
    e.preventDefault();
    this.setState({ loading: true, error: false });
    const {
      match: { params }
    } = this.props;
    const data = this.state.property;
    request
      .put(`/property/${params.id}`, data)
      .then(() => {
        this.setState({ loading: false, error: false });
        this.props.history.push(`/admin/property/${params.id}`);
        this.notify();
      })
      .catch((err) => {
        this.setState({ loading: false, error: true, errorMessage: err });
      });
  }

  render() {
    const inputProps = {
      value: this.state.searchAddress,
      onChange: this.updateAddress,
      placeholder: 'Search Address'
    };
    const cssClasses = {
      root: 'form-group',
      input: 'form-control',
      autocompleteContainer: 'my-autocomplete-container'
    };
    const renderSuggestion = ({ suggestion }) => (
      <div>
        <i className="fa fa-map-marker" />
        {suggestion}
      </div>
    );

    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3>Edit property</h3>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Col md={12} style={{ padding: 20 }}>
                  <Form>
                    <Col md={12} style={{ background: '#EAEAEA', padding: 20 }}>
                      <h4>Update Map For Listings </h4>
                      <p style={{ padding: 0, margin: 0, fontSize: 10 }}>
                        <i>
                          Only update this if listings don&apos;t show correct
                          location on maps.
                        </i>
                      </p>
                    </Col>
                    <Col
                      md={12}
                      style={{
                        background: '#EAEAEA',
                        padding: 20,
                        marginBottom: 30
                      }}>
                      <FormGroup
                        validationState={this.getValidStates('address')}>
                        <PlacesAutocomplete
                          inputProps={inputProps}
                          classNames={cssClasses}
                          renderSuggestion={renderSuggestion}
                          onChange={this.updateAddress}
                          onSelect={this.searchSelect}
                        />
                      </FormGroup>
                      <p>
                        Or enter latitude and longitude manually if autocomplete
                        cannot find your location
                      </p>
                      <Col md={6} style={{ padding: 0 }}>
                        <FormGroup>
                          <ControlLabel>longitude</ControlLabel>
                          <FormControl
                            name="longitude"
                            type="text"
                            placeholder="Long"
                            value={this.state.property.longitude}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6} style={{ padding: 0 }}>
                        <FormGroup>
                          <ControlLabel>latitude</ControlLabel>
                          <FormControl
                            name="latitude"
                            type="text"
                            placeholder="Lat"
                            value={this.state.property.latitude}
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Col>

                    <Col md={12}>
                      <h4>Contract Type:</h4>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        name="contractType"
                        value={this.state.property.contractType}
                        onChange={this.handleInputChange}>
                        <option value="">Choose one:</option>
                        <option value="RM">Rental Management</option>
                        <option value="CM">Commercial Management</option>
                        <option value="LU">Lease-UP</option>
                        <option value="RB">Rental Building</option>
                      </FormControl>
                    </Col>
                    <Col md={12} style={{ paddingBottom: 20 }}>
                      <FormGroup>
                        <ControlLabel>RM Number</ControlLabel>
                        <FormControl
                          disabled={!this.state.property.contractType}
                          name="rmNumber"
                          type="text"
                          placeholder={this.setPlaceholder()}
                          value={this.state.property.rmNumber}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup
                        validationState={this.getValidStates('street')}>
                        <ControlLabel>Street Address</ControlLabel>
                        <FormControl
                          name="street"
                          type="text"
                          placeholder="Street Address"
                          value={this.state.property.street}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup
                        validationState={this.getValidStates('postal')}>
                        <ControlLabel>Postal Code</ControlLabel>
                        <FormControl
                          name="postal"
                          type="text"
                          placeholder="Postal Code"
                          value={this.state.property.postal}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Strata Plan</ControlLabel>
                        <FormControl
                          name="strataPlan"
                          type="text"
                          placeholder="Strata Plan"
                          value={this.state.property.strataPlan}
                          onChange={this.handleInputChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Square Footage</ControlLabel>
                        <FormControl
                          name="sqft"
                          type="text"
                          value={this.state.property.sqft}
                          onChange={this.handleInputChange}
                          placeholder="Square Footage"
                        />
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Bedrooms</ControlLabel>
                        <InputGroup>
                          <FormControl
                            name="bedroom"
                            type="text"
                            placeholder="bedroom"
                            value={this.state.property.bedroom}
                            onChange={this.handleInputChange}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Bathroom</ControlLabel>
                        <InputGroup>
                          <FormControl
                            name="bathroom"
                            type="text"
                            placeholder="bathroom"
                            value={this.state.property.bathroom}
                            onChange={this.handleInputChange}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Move In Fee</ControlLabel>
                        <InputGroup>
                          <FormControl
                            name="moveInFee"
                            type="text"
                            placeholder="moveInFee"
                            value={this.state.property.moveInFee}
                            onChange={this.handleInputChange}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Move Out Fee</ControlLabel>
                        <InputGroup>
                          <FormControl
                            name="moveOutFee"
                            type="text"
                            placeholder="moveOutFee"
                            value={this.state.property.moveOutFee}
                            onChange={this.handleInputChange}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Locker #</ControlLabel>
                        <InputGroup>
                          <FormControl
                            name="locker"
                            type="text"
                            placeholder="Locker #"
                            value={this.state.property.locker}
                            onChange={this.handleInputChange}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Tax Paid By</ControlLabel>
                        <InputGroup>
                          <FormControl
                            name="taxPaidBy"
                            type="text"
                            placeholder="Tax Paid By"
                            value={this.state.property.taxPaidBy}
                            onChange={this.handleInputChange}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Strata Paid By</ControlLabel>
                        <InputGroup>
                          <FormControl
                            name="strataPaidBy"
                            type="text"
                            placeholder="Strata Paid By"
                            value={this.state.property.strataPaidBy}
                            onChange={this.handleInputChange}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>Insurance Paid By</ControlLabel>
                        <InputGroup>
                          <FormControl
                            name="insurancePaidBy"
                            type="text"
                            placeholder="Insurance Paid By"
                            value={this.state.property.insurancePaidBy}
                            onChange={this.handleInputChange}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup>
                        <ControlLabel>Parking Available</ControlLabel>
                        <Checkbox
                          name="parkingAvailable"
                          value={this.state.property.parkingAvailable}
                          checked={this.state.property.parkingAvailable}
                          onChange={this.handleInputChange}>
                          Parking Available
                        </Checkbox>
                      </FormGroup>
                      {this.ifParkingAvailable()}
                    </Col>

                    <Col md={12} style={{ paddingTop: 10 }}>
                      <FormGroup className="form-heading">
                        <ControlLabel>Amenities</ControlLabel>
                        <Checkbox
                          name="pool"
                          value={this.state.property.pool}
                          checked={this.state.property.pool}
                          onChange={this.handleInputChange}>
                          Pool
                        </Checkbox>
                        <Checkbox
                          name="hottub"
                          value={this.state.property.hottub}
                          checked={this.state.property.hottub}
                          onChange={this.handleInputChange}>
                          Hot Tub
                        </Checkbox>
                        <Checkbox
                          name="gym"
                          value={this.state.property.gym}
                          checked={this.state.property.gym}
                          onChange={this.handleInputChange}>
                          Gym
                        </Checkbox>
                        <Checkbox
                          name="laundry"
                          value={this.state.property.laundry}
                          checked={this.state.property.laundry}
                          onChange={this.handleInputChange}>
                          Laundry
                        </Checkbox>
                        <Checkbox
                          name="view"
                          value={this.state.property.view}
                          checked={this.state.property.view}
                          onChange={this.handleInputChange}>
                          View
                        </Checkbox>
                        <Checkbox
                          name="airConditioning"
                          value={this.state.property.airConditioning}
                          checked={this.state.property.airConditioning}
                          onChange={this.handleInputChange}>
                          Air Conditioning
                        </Checkbox>
                        <Checkbox
                          name="homeTheater"
                          value={this.state.property.homeTheater}
                          checked={this.state.property.homeTheater}
                          onChange={this.handleInputChange}>
                          Home Theater
                        </Checkbox>
                        <Checkbox
                          name="centralHeating"
                          value={this.state.property.centralHeating}
                          checked={this.state.property.centralHeating}
                          onChange={this.handleInputChange}>
                          Central Heating
                        </Checkbox>
                        <Checkbox
                          name="balcony"
                          value={this.state.property.balcony}
                          checked={this.state.property.balcony}
                          onChange={this.handleInputChange}>
                          Balcony
                        </Checkbox>
                        <Checkbox
                          name="storage"
                          value={this.state.property.storage}
                          checked={this.state.property.storage}
                          onChange={this.handleInputChange}>
                          Storage
                        </Checkbox>
                        <Checkbox
                          name="garage"
                          value={this.state.property.garage}
                          checked={this.state.property.garage}
                          onChange={this.handleInputChange}>
                          Garage
                        </Checkbox>
                        <Checkbox
                          name="yard"
                          value={this.state.property.yard}
                          checked={this.state.property.yard}
                          onChange={this.handleInputChange}>
                          Yard
                        </Checkbox>
                        <Checkbox
                          name="electricWaterHeater"
                          value={this.state.property.electricWaterHeater}
                          checked={this.state.property.electricWaterHeater}
                          onChange={this.handleInputChange}>
                          Electric Water Heater
                        </Checkbox>
                        <Checkbox
                          name="deck"
                          value={this.state.property.deck}
                          checked={this.state.property.deck}
                          onChange={this.handleInputChange}>
                          Deck
                        </Checkbox>
                        <Checkbox
                          name="oceanView"
                          value={this.state.property.oceanView}
                          checked={this.state.property.oceanView}
                          onChange={this.handleInputChange}>
                          Ocean View
                        </Checkbox>
                        <Checkbox
                          name="halfWashroom"
                          value={this.state.property.halfWashroom}
                          checked={this.state.property.halfWashroom}
                          onChange={this.handleInputChange}>
                          1/2 Washroom
                        </Checkbox>
                        <Checkbox
                          name="den"
                          value={this.state.property.den}
                          checked={this.state.property.den}
                          onChange={this.handleInputChange}>
                          Den
                        </Checkbox>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      {this.state.loading === false ? (
                        <Button type="submit" onClick={this.update}>
                          Submit
                        </Button>
                      ) : (
                        <div className="loader" style={{ margin: -10 }} />
                      )}
                    </Col>
                  </Form>
                  <Row>
                    {this.state.error ? (
                      <ErrorAlert errorMessage={this.state.errorMessage} />
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}
export default Edit;
