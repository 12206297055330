import React, { Component } from 'react';
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Row
} from 'react-bootstrap';

class StrataFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        violationCategory: '',
        violationSubcategory: '',
        sourceBuildingAddress: '',
        sourceUnitNumber: '',
        parkingStall: '',
        additionalInfo: '',
        dateOfLetter: '',
        violationFine: '',
        relationship: '',
        complainantType: '',
        disputeOnBehalfOf: '',
        willAttendMeeting: '',
        contactInfo: {
          firstName: '',
          lastName: '',
          buildingName: '',
          buildingAddress: '',
          unitNumber: '',
          strataLotNumber: '',
          strataPlanNumber: '',
          homePhone: '',
          cellPhone: '',
          workPhone: '',
          sig1: '',
          sig2: ''
        }
      }
    };
    this.handleContactInfoInputChange = this.handleContactInfoInputChange.bind(
      this
    );
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      form: { ...prevState.form, [name]: value }
    }));
  }

  handleContactInfoInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        contactInfo: { ...prevState.form.contactInfo, [name]: value }
      }
    }));
  }

  render() {
    return (
      <div>
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h1>Bylaw Dispute</h1>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <h3>Violation Details</h3>
                <p>
                  Provide details of the received violation letter. The date is
                  located on the top left corner. The strata plan, unit #,
                  address, and violation type are located on the first and
                  second subject lines.
                </p>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Date of Letter</ControlLabel>
                    <FormControl
                      name="dateOfLetter"
                      type="text"
                      placeholder="Date of Letter"
                      value={this.state.form.contactInfo.dateOfLetter}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Strata Plan Number </ControlLabel>
                    <FormControl
                      name="strataPlanNumber"
                      type="text"
                      placeholder="Strata Plan Number"
                      value={this.state.form.contactInfo.strataPlanNumber}
                      onChange={this.handleContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Unit Number</ControlLabel>
                    <FormControl
                      name="unitNumber"
                      type="text"
                      placeholder="Unit Number"
                      value={this.state.form.contactInfo.unitNumber}
                      onChange={this.handleContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Address </ControlLabel>
                    <FormControl
                      name="address"
                      type="text"
                      placeholder="Address"
                      value={this.state.form.contactInfo.address}
                      onChange={this.handleContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>
                      Bylaw Fine/Warning (Nature of violation)
                    </ControlLabel>
                    <FormControl
                      name="violationFine"
                      type="text"
                      placeholder="Nature of Violation"
                      value={this.state.form.contactInfo.violationFine}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <h3>Respondent(s) Information</h3>
                <p>
                  Provide your name and contact details. If you are writing on
                  behalf of the registered owner or tenant, you will need to
                  provide a brief description of your relationship to them. This
                  information will only be used to verify dispute details.
                </p>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>First Name</ControlLabel>
                    <FormControl
                      name="firstName"
                      type="text"
                      value={this.state.form.contactInfo.firstName}
                      onChange={this.handleContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Last Name</ControlLabel>
                    <FormControl
                      name="lastName"
                      type="text"
                      placeholder="Last Phone"
                      value={this.state.form.contactInfo.lastName}
                      onChange={this.handleContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Home Phone</ControlLabel>
                    <FormControl
                      name="homePhone"
                      type="text"
                      placeholder="Home Phone"
                      value={this.state.form.contactInfo.homePhone}
                      onChange={this.handleContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Cell Phone</ControlLabel>
                    <FormControl
                      name="cellPhone"
                      type="text"
                      placeholder="Cell Phone"
                      value={this.state.form.contactInfo.cellPhone}
                      onChange={this.handleContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Work Phone</ControlLabel>
                    <FormControl
                      name="workPhone"
                      type="text"
                      placeholder="Work Phone"
                      value={this.state.form.contactInfo.workPhone}
                      onChange={this.handleContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p>
                    Are you a registered tenant or the owner of the property?{' '}
                  </p>
                </Col>
                <Col md={6}>
                  <ControlLabel>Select:</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    name="complainantType"
                    value={this.state.form.complainantType}
                    onChange={this.handleInputChange}>
                    <option value="1">Owner</option>
                    <option value="2">Tenant</option>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p>
                    Are you a filing this dispute on behalf of the tenant or the
                    owner?{' '}
                  </p>
                </Col>
                <Col md={6}>
                  <ControlLabel>Select:</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    name="disputeOnBehalfOf"
                    value={this.state.form.disputeOnBehalfOf}
                    onChange={this.handleInputChange}>
                    <option value="1">Owner</option>
                    <option value="2">Tenant</option>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p>Provide description of relationship: </p>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Description of Relationship</ControlLabel>
                    <FormControl
                      name="relationship"
                      type="text"
                      placeholder="Relationship with wner"
                      value={this.state.form.contactInfo.relationship}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <h3>Dispute Details</h3>
                <p>
                  Provide information as to why you disagree with the
                  particulars of the infraction. If applicable, additional
                  supporting evidence (pictures, receipts and/or affidavits) can
                  be attached to your email. Do not include additional dispute
                  details in the body of the email.
                </p>
              </Row>
              <Row>
                <Col md={12}>
                  <FormControl
                    name="additionalInfo"
                    type="text"
                    placeholder="Additional Info"
                    value={this.state.form.additionalInfo}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    *200 Word Limit
                    <p />
                    Section 135 of the Strata Property Act provides individuals
                    with the opportunity to answer complaints in person. If
                    requested, you will be advised of the date and time of the
                    next meeting and provide with a 5-10 min time slot to
                    present your case to the Council. Do you wish to attend the
                    next council meeting to present your dispute in person?
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <ControlLabel>Select:</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    name="willAttendMeeting"
                    value={this.state.form.willAttendMeeting}
                    onChange={this.handleInputChange}>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </FormControl>
                </Col>
              </Row>
              <hr />
              <Row>
                <h3>Acknowledgements</h3>
                <p>
                  I/We hereby certify that the above information is correct and
                  has been witnessed by me/us. I/We understand that in
                  accordance with the Strata Property Act copies of complaints
                  may be requested by the person against whom they are made. If
                  requested, the complaint will be release with the complainants
                  identifying factors redacted. I/We further understand that if
                  legal action, Civil Right Tribunal or Human Rights Tribunal
                  claim is initiated by the person against whom the complaints
                  are made this document may need to be released in its
                  entirety.
                </p>
              </Row>
              <Row>
                <Col md={4}>
                  <ControlLabel>Date</ControlLabel>
                  <FormControl
                    name="date"
                    type="text"
                    placeholder="Date"
                    value={this.state.form.date}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={4}>
                  <ControlLabel>Signature</ControlLabel>
                  <FormControl
                    name="sig1"
                    type="text"
                    placeholder="Signature"
                    value={this.state.form.sig1}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={4}>
                  <ControlLabel>Signature</ControlLabel>
                  <FormControl
                    name="sig2"
                    type="text"
                    placeholder="Signature"
                    value={this.state.form.sig2}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <h3>Procedure</h3>
                <p>
                  You will be advised once this form has been received. The form
                  will be verified for content and an infraction letter issued
                  in accordance with the Strata Property Act and your respective
                  strata corporation’s bylaws. In most cases an infraction
                  letter will be issued within five business days of the
                  violation form being received.
                </p>
                <p>
                  For issues such as noise and waste disposal we recommend
                  speaking to the responsible party first, as they might not be
                  aware of the infraction.
                </p>
                <p>
                  Details of the actions taken relating to specific complaints
                  will be reported in the subsequent meeting minutes. The
                  reporting will be done in a format that does not identify the
                  person making the complaint or against who the complaint has
                  been made.
                </p>
              </Row>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}
export default StrataFormComponent;
