import React, { useState } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import request from '../../utils/Request';
import StrataUnitUserAuthorizeForm from './forms/StrataUnitUserAuthorization';
import ErrorAlert from '../utils/ErrorAlert';
import StrataUnitUserRegisterForm from './forms/StrataUnitUserRegister';

export default ({ history, location }) => {
  const [hash] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('hash');
  });
  const [error, setError] = useState(null);
  const [invitation, setInvitation] = useState(null);

  const [validationForm, setValidationForm] = useState({
    unitNumber: '',
    strataPlanNumber: '',
    nameOnTitle: ''
  });
  const [validationFormValid, setValidationFormValid] = useState(false);

  const [registerForm, setRegisterForm] = useState({
    email: '',
    password: ''
  });
  const [regFormValid, setRegFormValid] = useState(false);

  const submit = async (event) => {
    event.preventDefault();

    if (!invitation) {
      try {
        const invite = await request.get(`/strataUnitUserInvitation/${hash}`);
        const response = await request.post(
          `/strataUnitUserInvitation/authorizeForm/${invite.id}`,
          validationForm
        );
        if (response) {
          setInvitation(invite);
          setError(null);
        } else {
          throw Error();
        }
      } catch (e) {
        setError(
          "One or more fields don't match the authorization requirements"
        );
      }
    } else {
      try {
        const response = await request.post(
          `/strataUnitUserInvitation/${invitation.id}/register/`,
          registerForm
        );
        try {
          await request.login({
            userName: response.userName,
            password: registerForm.password,
            portal: 'strata'
          });
          localStorage.setItem('portalOption', 'strata');
          localStorage.setItem('role', 'strataUnitOwner');
          history.push(
            `/strataUnitOwner/strataUnit/${invitation.strataUnitId}`
          );
        } catch (e) {
          setError('email or password were incorrect');
        }
      } catch (e) {
        setError(e.message);
      }
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          {invitation ? (
            <StrataUnitUserRegisterForm
              registerForm={registerForm}
              setRegisterForm={setRegisterForm}
              setValid={setRegFormValid}
            />
          ) : (
            <StrataUnitUserAuthorizeForm
              validationForm={validationForm}
              setValidationForm={setValidationForm}
              setValid={setValidationFormValid}
            />
          )}
          <Grid>
            <Row>
              <Col md={12}>
                <Button
                  bsStyle="primary"
                  bsSize="large"
                  block
                  type="submit"
                  disabled={
                    (invitation && !regFormValid) ||
                    (!invitation && !validationFormValid)
                  }
                  onClick={submit}>
                  Submit
                </Button>
              </Col>
            </Row>
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
