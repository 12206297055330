import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Row
} from 'react-bootstrap';
import Promise from 'bluebird';
import ReactQuill from 'react-quill';
import GridLayout from 'react-grid-layout';
import cuid from 'cuid';
import Dropzone from '../../utils/Dropzone';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';

class Add extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.state = {
      loading: false,
      error: false,
      errorMessage: '',
      property: {},
      listing: {
        description: '',
        text: '',
        price: '0.00',
        deposit: 0.0,
        moveInFee: false,
        moveInDeposit: false,
        moveOutFee: false,
        available: false,
        recentlyRented: false,
        furnished: false,
        propertyId: match.params.id,
        numberOfParkingSpots: 0
      },
      files: [],
      disabled: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.create = this.create.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
  }

  componentDidMount() {
    this.loadProperty();
  }

  loadProperty() {
    const { match } = this.props;

    request.get(`/property/${match.params.id}`).then((property) => {
      this.setState((prevState) => ({
        property,
        listing: {
          ...prevState.listing,
          numberOfParkingSpots: property.numberOfParkingSpots,
          description: property.street
        }
      }));
    });
  }

  getValidateStates(name) {
    const currency = /^\d+(?:\.\d{0,2})$/;
    const wholenumber = /^\d+$/;
    switch (name) {
      case 'price':
        if (
          currency.test(this.state.listing.price) ||
          wholenumber.test(this.state.listing.price)
        ) {
          return 'success';
        }
        return 'error';
      default:
        return null;
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      listing: { ...prevState.listing, [name]: value }
    }));
  }

  handleTextChange(content) {
    this.setState((prevState) => ({
      listing: { ...prevState.listing, text: content }
    }));
  }

  create(e) {
    e.preventDefault();
    this.setState({ loading: true });

    const { match } = this.props;
    const data = this.state.listing;

    request
      .post('/propertyListing', data)
      .then((listing) => this.saveImages(listing))
      .then(() => this.props.history.push(`/admin/property/${match.params.id}`))
      .catch((err) => {
        this.setState({
          loading: false,
          error: true,
          errorMessage: err.message
        });
      });
  }

  saveImages(listing) {
    const uploads = this.state.files.map((file, index) =>
      request.upload(`/PropertyListingImage/${listing.id}`, file.blob, index)
    );

    return Promise.all(uploads);
  }

  onDrop(acceptedFiles) {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.setState((prevState) => ({
          files: [
            ...prevState.files,
            { id: cuid(), src: e.target.result, blob: file }
          ],
          disabled: true
        }));
      };
      reader.readAsDataURL(file);
      return file;
    });
  }

  onLayoutChange(givenLayouts) {
    // use layout order to change sort order
    const newFileOrdered = givenLayouts
      .sort((a, b) => {
        if (a.y === b.y) {
          return a.x - b.x;
        }
        return a.y - b.y;
      })
      .map((item) => this.state.files.find((file) => file.id === item.i));

    this.setState(() => ({
      files: newFileOrdered
    }));
  }

  render() {
    return (
      <div>
        <section className="section section-parallax parallax custom-parallax-medium-padding m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3>New Listing</h3>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Col md={12}>
                  <Form>
                    <FormGroup>
                      <ControlLabel>Description</ControlLabel>
                      <FormControl
                        name="description"
                        type="text"
                        placeholder="Description"
                        value={this.state.property.description}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>

                    <ReactQuill
                      name="text"
                      value={this.state.listing.text}
                      onChange={this.handleTextChange}
                    />

                    <FormGroup
                      validationState={this.getValidateStates('price')}>
                      <ControlLabel>Price</ControlLabel>
                      <FormControl
                        name="price"
                        type="number"
                        placeholder="Price"
                        value={this.state.listing.price}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Options</ControlLabel>
                      <Checkbox
                        name="available"
                        value={this.state.listing.available}
                        onChange={this.handleInputChange}>
                        Available
                      </Checkbox>
                      <Checkbox
                        name="furnished"
                        value={this.state.listing.furnished}
                        onChange={this.handleInputChange}>
                        Furnished
                      </Checkbox>
                      <Checkbox
                        name="recentlyRented"
                        value={this.state.listing.recentlyRented}
                        onChange={this.handleInputChange}>
                        Recently Rented
                      </Checkbox>
                    </FormGroup>

                    <FormGroup>
                      <ControlLabel>Display</ControlLabel>
                      <Checkbox
                        name="moveInFee"
                        value={this.state.listing.moveInFee}
                        onChange={this.handleInputChange}>
                        Move In Fee
                      </Checkbox>
                      <Checkbox
                        name="moveInDeposit"
                        value={this.state.listing.moveInDeposit}
                        onChange={this.handleInputChange}>
                        Move In Deposit
                      </Checkbox>
                      <Checkbox
                        name="moveOutFee"
                        value={this.state.listing.moveOutFee}
                        onChange={this.handleInputChange}>
                        Move Out Fee
                      </Checkbox>
                    </FormGroup>
                    <Row>
                      <GridLayout
                        rowHeight={100}
                        width={1170}
                        onLayoutChange={this.onLayoutChange}
                        compactType="horizontal"
                        isResizable="false"
                        className="react-grid-layout">
                        {this.state.files.map((file, index) => (
                          <div
                            key={file.id}
                            data-grid={{ x: index, y: 0, w: 3, h: 2 }}>
                            <img
                              width="100%"
                              className="scale-image"
                              src={file.src}
                              alt=""
                            />
                          </div>
                        ))}
                      </GridLayout>
                    </Row>
                    <FormGroup>
                      <Dropzone onDrop={this.onDrop} />
                    </FormGroup>
                    {this.state.loading === false ? (
                      <Button
                        type="submit"
                        onClick={this.create}
                        disabled={!this.state.disabled}>
                        Submit
                      </Button>
                    ) : (
                      <div className="loader" style={{ margin: -10 }} />
                    )}
                  </Form>
                  <Row>
                    {this.state.error ? (
                      <ErrorAlert errorMessage={this.state.errorMessage} />
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

export default Add;
