/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from '../../utils/Auth';
import AdminLayout from '../layout/admin/AdminLayout';

export default (props) => (
  <>
    {Auth.isAuthenticated(['admin', 'superAdmin']) ? (
      <AdminLayout>
        <Route {...props} />
      </AdminLayout>
    ) : (
      <Redirect to="/" />
    )}
  </>
);
