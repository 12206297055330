/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import StrataDocuments from './strataDocuments';

const Layout = () => {
  const strataPortalUrl = '/strataLogin';
  const residentialPortalUrl = '/login';
  const [currentForm, setCurrentForm] = useState('');
  const residentialProposalRequest = 'residentialProposalRequest';
  const strataProposalRequest = 'strataProposalRequest';
  const residentialProposalRequestUrl =
    'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/11';
  const strataProposalRequestUrl =
    'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/10';

  return (
    <footer id="footer" className="custom-footer m-none">
      <Grid>
        <Row>
          <Col md={3} mdOffset={1}>
            <h5 className="text-color-light font-weight-bold mb-xs text-decoration-none">
              Strata Management
            </h5>
            <ul>
              <li>
                <a
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/strata-management-services`}
                  className="text-color-light text-decoration-none"
                  title="Strata Management Services">
                  Strata Management Services
                </a>
              </li>
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to="#"
                  onClick={() => setCurrentForm(strataProposalRequest)}
                  className="text-color-light text-decoration-none"
                  title="forms">
                  Strata Management Proposal Request
                  <StrataDocuments
                    open={currentForm === strataProposalRequest}
                    openModal={setCurrentForm}
                    documentUrl={strataProposalRequestUrl}
                  />
                </Link>
              </li>
              <li>
                <a
                  href={strataPortalUrl}
                  className="text-color-light text-decoration-none"
                  title="Login">
                  Strata Management Login
                </a>
              </li>
            </ul>
          </Col>
          <Col md={3} mdOffset={1}>
            <h5 className="text-color-light font-weight-bold mb-xs text-decoration-none">
              Company
            </h5>
            <ul>
              <a
                href={`${process.env.REACT_APP_PUBLIC_SITE}/company`}
                className="text-color-light text-decoration-none">
                About Us
              </a>
              <li>
                <a
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/contact`}
                  className="text-color-light text-decoration-none">
                  Contact
                </a>
              </li>
            </ul>
          </Col>
          <Col md={3} mdOffset={1}>
            <h5 className="text-color-light font-weight-bold mb-xs text-decoration-none">
              Rental Management
            </h5>
            <ul>
              <li>
                <a
                  href={`${process.env.REACT_APP_PUBLIC_SITE}/rental-management-services`}
                  className="text-color-light text-decoration-none"
                  title="Property Management">
                  Rental Management Services
                </a>
              </li>
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to="#"
                  onClick={() => setCurrentForm(residentialProposalRequest)}
                  className="text-color-light text-decoration-none"
                  title="Rental Listings">
                  Rental Management Proposal Request
                  <StrataDocuments
                    open={currentForm === residentialProposalRequest}
                    openModal={setCurrentForm}
                    documentUrl={residentialProposalRequestUrl}
                  />
                </Link>
              </li>
              <li>
                <a
                  href={residentialPortalUrl}
                  className="text-color-light text-decoration-none"
                  title="Login">
                  Rental Management Login
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Grid>
      <div className="footer-copyright m-none pt-md pb-md">
        <Grid>
          <Row>
            <Col md={12} className="pt-md center">
              <p className="custom-text-color-2">
                &copy; Copyright 2022 Korecki Real Estate Services Inc. All
                Rights Reserved.
              </p>
            </Col>
          </Row>
        </Grid>
      </div>
    </footer>
  );
};

export default Layout;
