import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Glyphicon
} from 'react-bootstrap';
import Promise from 'bluebird';
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import request from '../../../utils/Request';
import ErrorAlert from '../../utils/ErrorAlert';
import Dropzone from '../../utils/Dropzone';

class StrataDocumentUpload extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    let documentType = 'property';
    if (this.props.location.search.includes('unit')) {
      documentType = 'unit';
    }
    if (this.props.location.params !== undefined) {
      if (this.props.location.params.documentFor === 'unit') {
        documentType = 'unit';
      }
    }
    this.state = {
      error: false,
      errorMessage: '',
      id: params.id,
      documentFor: documentType,
      form: {
        strataPropertyId: null,
        strataDocumentCategoryId: null,
        fileName: 'default',
        documentDate: moment(),
        path: 'PDF_DOCUMENT',
        strataUnitId: null,
        strataUnitDocumentCategoryId: null
      },
      files: [],
      propertyHolderArray: [],
      strataUnitHolderArray: [],
      categoryHolderArray: [],
      spinner: 'false'
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onPropertySelect = this.onPropertySelect.bind(this);
    this.onUnitSelect = this.onUnitSelect.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.create = this.create.bind(this);

    this.savePDF = (type, form, file) => {
      if (type === 'unit') {
        return request.upload2(`/strataunitdocument/upload/${form.id}`, file);
      }
      return request.upload2(`/stratadocuments/upload/${form.id}`, file);
    };
  }

  componentDidMount() {
    this.prefilledAddress();
    this.loadAddresses();
    if (this.state.documentFor === 'unit') {
      this.loadUnitCategories();
    } else {
      this.loadCategories();
    }
  }

  loadUnitCategories() {
    const categoryArray = [];
    request.get('/StrataUnitDocumentCategory').then((categories) => {
      categories.forEach((category) => {
        const cat = {
          id: category.id,
          name: category.name
        };
        categoryArray.push(cat);
      });
      this.setState({
        categoryHolderArray: categoryArray
      });
    });
  }

  loadCategories() {
    const categoryArray = [];
    request.get('/StrataDocumentCategories/all').then((categories) => {
      categories.forEach((category) => {
        const cat = {
          id: category.id,
          name: category.name
        };
        categoryArray.push(cat);
      });
      this.setState({
        categoryHolderArray: categoryArray
      });
    });
  }

  prefilledAddress() {
    const form = { ...this.state.form };
    if (this.state.documentFor === 'property') {
      if (this.state.id) {
        request.get(`/strataproperty/${this.state.id}`).then(() => {
          form.strataPropertyId = parseInt(this.state.id, 10);
          this.setState({ form });
        });
      }
    } else if (this.state.id) {
      request.get(`/strataunit/${this.state.id}`).then(() => {
        this.setState((prevState) => ({
          form: { ...prevState.form, strataUnitId: parseInt(prevState.id, 10) }
        }));
      });
    }
  }

  create(e) {
    this.setState({ spinner: 'true' });
    e.preventDefault();
    let errorFlag = false;
    const promises = [];
    const data = this.state.form;
    if (this.state.documentFor === 'property') {
      if (
        data.strataPropertyId == null ||
        data.strataDocumentCategoryId == null ||
        data.strataDocumentCategoryId === 0
      ) {
        this.setState({
          error: true,
          errorMessage:
            'Error: Strata Property and Type of Document cannot be blank!',
          spinner: false
        });
        return;
      }
      this.state.files.forEach((file) => {
        const p = request.post('/StrataDocuments', data);
        promises.push(p);
        p.then((form) => {
          promises.push(
            this.savePDF(
              this.state.documentFor,
              { ...form, documentDate: moment(form.documentDate) },
              file
            )
          );
        }).catch(() => {
          this.setState({
            error: true,
            errorMessage: `Error uploading document ${file.name}`
          });
          errorFlag = true;
        });
      });
      Promise.all(promises).then(() => {
        if (errorFlag) {
          this.setState({
            error: true,
            errorMessage: 'Upload failed',
            spinner: false
          });
          return;
        }
        this.setState({ spinner: 'false' });
        this.props.history.push(
          `/strataAdmin/property/${data.strataPropertyId}`
        );
      });
    } else {
      if (
        data.strataUnitId == null ||
        data.strataUnitDocumentCategoryId == null ||
        data.strataUnitDocumentCategoryId === 0
      ) {
        this.setState({
          error: true,
          errorMessage:
            'Error: Strata Property and Type of Document cannot be left blank.',
          spinner: false
        });
        return;
      }
      this.state.files.forEach((file) => {
        const p = request.post('/strataunitdocument', data);
        promises.push(p);
        p.then((form) => {
          promises.push(
            this.savePDF(
              this.state.documentFor,
              { ...form, documentDate: moment(form.documentDate) },
              file
            )
          );
        }).catch(() => {
          this.setState({
            error: true,
            errorMessage: `Error uploading document ${file.name}`
          });
          errorFlag = true;
        });
      });
      Promise.all(promises).then(() => {
        if (errorFlag) {
          this.setState({ spinner: 'false' });
          this.setState({ error: true, errorMessage: 'Upload Failed' });
          return;
        }
        this.setState({ spinner: 'false' });
        this.props.history.push(`/strataAdmin/strataUnit/${data.strataUnitId}`);
      });
    }
  }

  onDrop(acceptedFiles) {
    const files = [...this.state.files];
    acceptedFiles.forEach((file) => {
      files.push(file);
      this.setState({ files });
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'select' ? target.selected : target.value;
    const { name } = target;

    const form = { ...this.state.form };
    if (
      name === 'strataDocumentCategoryId' ||
      name === 'strataUnitDocumentCategoryId'
    ) {
      form[name] = parseInt(value, 10);
      this.setState({ form });
    } else {
      form[name] = value;
      this.setState({ form });
    }
  }

  loadAddresses() {
    const holderArray = [];
    const name = '';

    request.get('/strataproperty').then((properties) => {
      properties.forEach((property) => {
        const holder = {
          id: property.id,
          strataPlanNumber: property.strataPlanNumber
        };
        holderArray.push(holder);
      });
      this.setState({
        propertyHolderArray: holderArray,
        currentProperty: name
      });
    });
  }

  loadUnitsByProperty(id) {
    const holderArray = [];
    const name = '';
    request
      .get(`/strataUnit/strataProperty/${id}?archived=false`)
      .then((units) => {
        units.forEach((unit) => {
          const holder = {
            id: unit.id,
            unitLocation: unit.unitNumber
          };
          holderArray.push(holder);
        });
        this.setState({
          strataUnitHolderArray: holderArray,
          currentStrataUnit: name
        });
      })
      .catch(() => {});
  }

  onUnitSelect(selected) {
    const form = { ...this.state.form };
    try {
      form.strataUnitId = selected[0].id;
      this.setState({ form });
    } catch (error) {
      form.strataUnitId = null;
      this.setState({ form });
    }
  }

  onPropertySelect(selected) {
    const form = { ...this.state.form };
    try {
      form.strataPropertyId = selected[0].id;
      if (this.state.documentFor === 'unit') {
        this.loadUnitsByProperty(selected[0].id);
      }
      this.setState({ form });
    } catch (error) {
      form.strataPropertyId = null;
      this.setState({ form });
    }
  }

  showPropertyOrOwner(value) {
    let params = '';
    if (this.props.location.params) {
      params = this.props.location.params;
    }
    if (value === 'property' && params.strataPlanNumber === undefined) {
      return (
        <FormGroup>
          <ControlLabel>Strata Property</ControlLabel>
          <Typeahead
            id="strataPlanNumber"
            labelKey="strataPlanNumber"
            name="strataPlanNumber"
            value={this.state.currentProperty}
            onChange={this.onPropertySelect}
            options={this.state.propertyHolderArray}
            readOnly="false"
            defaultInputValue={params.strataPlanNumber}
          />
        </FormGroup>
      );
    }
    if (value === 'property' && params.strataPlanNumber !== '') {
      return (
        <FormGroup>
          <ControlLabel>Strata Property</ControlLabel>
          <FormControl
            id="strataPlanNumber"
            labelKey="strataPlanNumber"
            name="strataPlanNumber"
            value={params.strataPlanNumber}
            readOnly="true"
            defaultInputValue={params.strataPlanNumber}
            style={{ backgroundColor: '#D3D3D3' }}
          />
        </FormGroup>
      );
    }
    if (value === 'unit' && params.strataUnit !== undefined) {
      return (
        <FormGroup>
          <ControlLabel>Strata Property</ControlLabel>
          <FormControl
            id="strataPlanNumber"
            labelKey="strataPlanNumber"
            name="strataPlanNumber"
            value={params.strataPlanNumber}
            readOnly="true"
            defaultInputValue={params.strataPlanNumber}
            style={{ backgroundColor: '#D3D3D3' }}
          />
          <ControlLabel>Strata Unit</ControlLabel>
          <FormControl
            id="unitLocation"
            labelKey="unitLocation"
            name="unitLocation"
            value={params.strataUnit.unitLocation}
            readOnly="true"
            defaultInputValue={params.strataUnit.unitLocation}
            style={{ backgroundColor: '#D3D3D3' }}
          />
        </FormGroup>
      );
    }
    return (
      <FormGroup>
        <ControlLabel>Strata Property</ControlLabel>
        <Typeahead
          id="strataPlanNumber"
          labelKey="strataPlanNumber"
          name="strataPlanNumber"
          value={this.state.currentProperty}
          onChange={this.onPropertySelect}
          options={this.state.propertyHolderArray}
          readOnly="false"
          defaultInputValue={params.strataPlanNumber}
        />
        <ControlLabel>Strata Unit</ControlLabel>
        <Typeahead
          id="unitLocation"
          labelKey="unitLocation"
          name="unitLocation"
          value={this.state.currentStrataUnit}
          onChange={this.onUnitSelect}
          options={this.state.strataUnitHolderArray}
          readOnly="false"
          defaultInputValue={params.strataUnit}
        />
      </FormGroup>
    );
  }

  showDocTypeOptions() {
    let catId;
    let catName;
    if (this.state.documentFor === 'unit') {
      catId = this.state.form.strataUnitDocumentCategoryId;
      catName = 'strataUnitDocumentCategoryId';
    } else {
      catId = this.state.form.strataDocumentCategoryId;
      catName = 'strataDocumentCategoryId';
    }
    const listOfCategories = this.state.categoryHolderArray.map((item) => (
      <option value={item.id}>{item.name}</option>
    ));
    return (
      <FormGroup>
        <ControlLabel>Type of Document</ControlLabel>
        <FormControl
          componentClass="select"
          id="document type"
          placeholder="select"
          name={catName}
          value={catId}
          onChange={this.handleInputChange}>
          <option value="0">Choose one:</option>
          {listOfCategories}
        </FormControl>
      </FormGroup>
    );

    // This section can be updated when we add a strata owner as it will allow us to change which document cateogries the admin can add to
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col md={12}>
            <h1 style={{ fontSize: 20 }}>Upload Documents</h1>
            <Form>
              {this.showDocTypeOptions()}
              {this.showPropertyOrOwner(this.state.documentFor)}
              <FormGroup>
                <ControlLabel>Upload PDFs</ControlLabel>
                <Dropzone
                  onDrop={this.onDrop}
                  className="dropzone"
                  accept="application/pdf"
                />
              </FormGroup>
              <Row>
                {this.state &&
                  this.state.files &&
                  this.state.files.map((file) => (
                    <Col md={2} key={file.name}>
                      <p>
                        {file.name}{' '}
                        <Glyphicon glyph="glyphicon glyphicon-file" />
                      </p>
                    </Col>
                  ))}
              </Row>

              <Button
                bsStyle="primary"
                bsSize="large"
                blocktype="submit"
                style={{ marginBottom: 10, float: 'right' }}
                onClick={this.create}>
                Submit
              </Button>
              <Loader
                type="Oval"
                color="#47a447"
                height={46}
                width={100}
                visible={this.state.spinner}
                style={{ float: 'right' }}
              />
            </Form>
          </Col>
        </Row>
        <Row>
          {this.state.error ? (
            <ErrorAlert errorMessage={this.state.errorMessage} />
          ) : null}
        </Row>
      </Grid>
    );
  }
}
export default StrataDocumentUpload;
