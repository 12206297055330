import React, { useEffect } from 'react';
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import useValidation from '../../../../validation/useValidation';
import {
  requiredValidator,
  emailValidator,
  passwordValidator
} from '../../../../validation/validators';

export default ({ user, setUser, setValid }) => {
  const [validatePassword, passwordValid, passwordErrors] = useValidation([
    requiredValidator,
    passwordValidator
  ]);

  const [validateEmail, emailValid, emailErrors] = useValidation([
    requiredValidator,
    emailValidator
  ]);

  useEffect(() => {
    setValid(passwordValid && emailValid);
  }, [emailValid, passwordValid, setValid]);

  const onChange = (event) => {
    const { name, type, value, checked } = event.target;
    setUser((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Account Info</h3>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Col md={12}>
                <Form>
                  <FormGroup validationState={emailErrors.length && 'error'}>
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                      name="email"
                      type="text"
                      placeholder="Email"
                      value={user.email}
                      onChange={(event) => {
                        onChange(event);
                        validateEmail(event.target.value);
                      }}
                      onBlur={(event) => validateEmail(event.target.value)}
                    />
                    {emailErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                  <FormGroup validationState={passwordErrors.length && 'error'}>
                    <ControlLabel>Password</ControlLabel>
                    <FormControl
                      name="password"
                      type="password"
                      placeholder="Password"
                      value={user.password}
                      onChange={(event) => {
                        onChange(event);
                        validatePassword(event.target.value);
                      }}
                      onBlur={(event) => validatePassword(event.target.value)}
                    />
                    {passwordErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
