import React, { useEffect } from 'react';
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import useValidation from '../../../../validation/useValidation';
import {
  addressValidator,
  requiredValidator
} from '../../../../validation/validators';

export default ({ profile, setProfile, setValid }) => {
  const [validateName, nameValid, nameErrors] = useValidation([
    requiredValidator
  ]);
  const [validateAddress, addressValid, addressErrors] = useValidation([
    requiredValidator,
    addressValidator
  ]);
  const [validateCity, cityValid, cityErrors] = useValidation([
    requiredValidator
  ]);
  const [validatePostal, postalValid, postalErrors] = useValidation([
    requiredValidator
  ]);
  const [validatePhone, phoneValid, phoneErrors] = useValidation([
    requiredValidator
  ]);

  useEffect(() => {
    setValid(
      nameValid && addressValid && cityValid && postalValid && phoneValid
    );
  }, [addressValid, cityValid, nameValid, phoneValid, postalValid, setValid]);

  const onChange = (event) => {
    const { name, type, value, checked } = event.target;
    setProfile((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <Grid>
            <Row>
              <Col md={12}>
                <h3>Owner Profile</h3>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Col md={12}>
                <Form>
                  <FormGroup validationState={nameErrors.length && 'error'}>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl
                      name="name"
                      type="text"
                      placeholder="Name"
                      value={profile.name}
                      onChange={(event) => {
                        onChange(event);
                        validateName(event.target.value);
                      }}
                      onBlur={(event) => validateName(event.target.value)}
                    />
                    {nameErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>

                  <FormGroup validationState={addressErrors.length && 'error'}>
                    <ControlLabel>Address</ControlLabel>
                    <FormControl
                      name="address"
                      type="text"
                      placeholder="address"
                      value={profile.address}
                      onChange={(event) => {
                        onChange(event);
                        validateAddress(event.target.value);
                      }}
                      onBlur={(event) => validateAddress(event.target.value)}
                    />
                    {addressErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>

                  <ControlLabel>Country</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    name="countryId"
                    value={profile.countryId}
                    onChange={onChange}>
                    <option value="1">Canada</option>
                    <option value="2">U.S</option>
                    <option value="3">Other</option>
                  </FormControl>

                  <FormGroup style={{ paddingTop: 20 }}>
                    <ControlLabel>Province / State</ControlLabel>
                    <FormControl
                      name="province"
                      type="text"
                      placeholder="Province / State"
                      value={profile.province}
                      onChange={(event) => {
                        onChange(event);
                      }}
                    />
                  </FormGroup>

                  <FormGroup validationState={cityErrors.length && 'error'}>
                    <ControlLabel>City</ControlLabel>
                    <FormControl
                      name="city"
                      type="text"
                      placeholder="City"
                      value={profile.city}
                      onChange={(event) => {
                        onChange(event);
                        validateCity(event.target.value);
                      }}
                      onBlur={(event) => validateCity(event.target.value)}
                    />
                    {cityErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>

                  <FormGroup validationState={postalErrors.length && 'error'}>
                    <ControlLabel>Postal</ControlLabel>
                    <FormControl
                      name="postal"
                      type="text"
                      placeholder="Postal"
                      value={profile.postal}
                      onChange={(event) => {
                        onChange(event);
                        validatePostal(event.target.value);
                      }}
                      onBlur={(event) => validatePostal(event.target.value)}
                    />
                    {postalErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>

                  <FormGroup validationState={phoneErrors.length && 'error'}>
                    <ControlLabel>Phone</ControlLabel>
                    <FormControl
                      name="phone"
                      type="text"
                      placeholder="Phone"
                      value={profile.phone}
                      onChange={(event) => {
                        onChange(event);
                        validatePhone(event.target.value);
                      }}
                    />
                    {phoneErrors.map((error) => (
                      <HelpBlock key={error}>{error}</HelpBlock>
                    ))}
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Mobile</ControlLabel>
                    <FormControl
                      name="mobile"
                      type="text"
                      placeholder="Mobile"
                      value={profile.mobile}
                      onChange={(event) => {
                        onChange(event);
                      }}
                    />
                    <HelpBlock>This is optional</HelpBlock>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
