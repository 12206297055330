import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import request from '../../../utils/Request';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import PropertyTable from '../../utils/dataTable/PropertyTable';
import OwnerTable from '../../utils/dataTable/OwnerTable';
import ErrorAlert from '../../utils/ErrorAlert';

class Archive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property: [],
      owners: [],
      error: false,
      errorMessage: ''
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.loadProperties();
    this.loadOwners();
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  loadProperties() {
    request
      .get('/property/archivedProperties')
      .then((properties) => {
        this.setState({
          error: false,
          property: properties.map((p) => ({
            ...p,
            property_link: (
              <Link to={`/admin/archived/property/${p.id}`}>{p.rmNumber}</Link>
            ),
            delete_button: (
              <Button
                bsSize="xsmall"
                bsStyle="danger"
                onClick={() => {
                  this.restoreProperty(p.id);
                }}>
                Restore
              </Button>
            )
          }))
        });
      })
      .catch(() => {
        toast('Could Not Load Properties.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  loadOwners() {
    request
      .get('/ownerprofile/archivedOwners')
      .then((owners) => {
        this.setState({
          error: false,
          owners: owners.map((o) => ({
            ...o,
            owner_link: (
              <Link to={`/admin/archived/owner/${o.id}`}>{o.name}</Link>
            ),
            email: o.user.email,
            delete_button: (
              <Button
                bsSize="xsmall"
                bsStyle="danger"
                onClick={() => {
                  this.restoreOwner(o.id);
                }}>
                Restore
              </Button>
            )
          }))
        });
      })
      .catch(() => {
        toast('Could Not Load Owners.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  restoreOwner(id) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Are you sure you want to restore this? This will restore all related Properties and listings related to the owner.'
      )
    ) {
      request
        .put(`/ownerProfile/restore/${id}`)
        .then(() => {
          this.loadOwners();
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Something Went Wrong. Please try again later.'
          });
        });
    }
  }

  restoreProperty(id) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Are you sure you want to restore this? This will restore all related listings related to the property.'
      )
    ) {
      request
        .put(`/property/restore/${id}`)
        .then(() => {
          this.loadProperties();
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Something Went Wrong. Please try again later.'
          });
        });
    }
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3>Archives</h3>
                </Col>
              </Row>
            </Grid>

            <Grid>
              <Row>
                <Col md={6} />
                <Col md={6} />
              </Row>
            </Grid>
            <PropertyTable properties={this.state.property} />

            <OwnerTable owners={this.state.owners} />
            <Row>
              {this.state.error ? (
                <ErrorAlert errorMessage={this.state.errorMessage} />
              ) : null}
            </Row>
          </div>
        </section>
      </div>
    );
  }
}

export default Archive;
