import React, { Component } from 'react';
import {
  Checkbox,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Row
} from 'react-bootstrap';

class ChangeOfAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        certification1: false,
        certification2: false,
        certification3: false,
        date: '',
        sig1: '',
        sig2: '',
        buildingInfo: {
          buildingName: '',
          buildingAddress: '',
          unitNumber: '',
          strataLotNumber: '',
          strataPlanNumber: ''
        },
        oldContactInfo: {
          firstName: '',
          lastName: '',
          homePhone: '',
          cellPhone: '',
          workPhone: '',
          city: '',
          province: '',
          postalCode: '',
          email: '',
          address: ''
        },
        newContactInfo: {
          CO: '',
          homePhone: '',
          cellPhone: '',
          workPhone: '',
          city: '',
          province: '',
          postalCode: '',
          email: '',
          address: ''
        }
      }
    };
    this.handleBuildingInfoInputChange = this.handleBuildingInfoInputChange.bind(
      this
    );
    this.handleOldContactInfoInputChange = this.handleOldContactInfoInputChange.bind(
      this
    );
    this.handleNewContactInfoInputChange = this.handleNewContactInfoInputChange.bind(
      this
    );
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      form: { ...prevState.form, [name]: value }
    }));
  }

  handleBuildingInfoInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        buildingInfo: { ...prevState.form.buildingInfo, [name]: value }
      }
    }));
  }

  handleOldContactInfoInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        oldContactInfo: { ...prevState.form.oldContactInfo, [name]: value }
      }
    }));
  }

  handleNewContactInfoInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        newContactInfo: { ...prevState.form.newContactInfo, [name]: value }
      }
    }));
  }

  render() {
    return (
      <div>
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h1>Change of Address & Contact Details</h1>
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <h3>Building Information</h3>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Building Name</ControlLabel>
                    <FormControl
                      name="buildingName"
                      type="text"
                      placeholder="Building Name"
                      value={this.state.form.buildingInfo.buildingName}
                      onChange={this.handleBuildingInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Building Address</ControlLabel>
                    <FormControl
                      name="buildingAddress"
                      type="text"
                      placeholder="Building Address"
                      value={this.state.form.buildingInfo.buildingAddress}
                      onChange={this.handleBuildingInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Strata Plan Number</ControlLabel>
                    <FormControl
                      name="strataPlanNumber"
                      type="text"
                      placeholder="Strata Plan Number"
                      value={this.state.form.buildingInfo.strataPlanNumber}
                      onChange={this.handleBuildingInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Strata Lot Number</ControlLabel>
                    <FormControl
                      name="strataLotNumber"
                      type="text"
                      placeholder="Strata Lot Number"
                      value={this.state.form.buildingInfo.strataLotNumber}
                      onChange={this.handleBuildingInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Unit Number</ControlLabel>
                    <FormControl
                      name="unitNumber"
                      type="text"
                      placeholder="Unit Number"
                      value={this.state.form.buildingInfo.unitNumber}
                      onChange={this.handleBuildingInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <h3>Registered Owner(s) Previous Information</h3>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>First Name</ControlLabel>
                    <FormControl
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      value={this.state.form.oldContactInfo.firstName}
                      onChange={this.handleOldContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Last Name</ControlLabel>
                    <FormControl
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      value={this.state.form.oldContactInfo.lastName}
                      onChange={this.handleOldContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                      name="email"
                      type="text"
                      placeholder="Email"
                      value={this.state.form.oldContactInfo.email}
                      onChange={this.handleOldContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Address</ControlLabel>
                    <FormControl
                      name="address"
                      type="text"
                      placeholder="Address"
                      value={this.state.form.oldContactInfo.address}
                      onChange={this.handleOldContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>City</ControlLabel>
                    <FormControl
                      name="city"
                      type="text"
                      placeholder="City"
                      value={this.state.form.oldContactInfo.city}
                      onChange={this.handleOldContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Province</ControlLabel>
                    <FormControl
                      name="province"
                      type="text"
                      placeholder="Province"
                      value={this.state.form.oldContactInfo.province}
                      onChange={this.handleOldContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Postal Code</ControlLabel>
                    <FormControl
                      name="postalCode"
                      type="text"
                      placeholder="Postal Code"
                      value={this.state.form.oldContactInfo.postalCode}
                      onChange={this.handleOldContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Home Phone</ControlLabel>
                    <FormControl
                      name="homePhone"
                      type="text"
                      placeholder="Home Phone"
                      value={this.state.form.oldContactInfo.homePhone}
                      onChange={this.handleOldContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Cell Phone</ControlLabel>
                    <FormControl
                      name="cellPhone"
                      type="text"
                      placeholder="Cell Phone"
                      value={this.state.form.oldContactInfo.cellPhone}
                      onChange={this.handleOldContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Work Phone</ControlLabel>
                    <FormControl
                      name="workPhone"
                      type="text"
                      placeholder="Work Phone"
                      value={this.state.form.oldContactInfo.workPhone}
                      onChange={this.handleOldContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <h3>Registered Owner(s) New Information</h3>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <ControlLabel>
                      C/O (Use for Changes to Management Company Only):
                    </ControlLabel>
                    <FormControl
                      name="CO"
                      type="text"
                      placeholder="C/O"
                      value={this.state.form.newContactInfo.CO}
                      onChange={this.handleNewContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                      name="email"
                      type="text"
                      placeholder="Email"
                      value={this.state.form.newContactInfo.email}
                      onChange={this.handleNewContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <ControlLabel>Address</ControlLabel>
                    <FormControl
                      name="address"
                      type="text"
                      placeholder="Address"
                      value={this.state.form.newContactInfo.address}
                      onChange={this.handleNewContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>City</ControlLabel>
                    <FormControl
                      name="city"
                      type="text"
                      placeholder="City"
                      value={this.state.form.newContactInfo.city}
                      onChange={this.handleNewContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Province</ControlLabel>
                    <FormControl
                      name="province"
                      type="text"
                      placeholder="Province"
                      value={this.state.form.newContactInfo.province}
                      onChange={this.handleNewContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Postal Code</ControlLabel>
                    <FormControl
                      name="postalCode"
                      type="text"
                      placeholder="Postal Code"
                      value={this.state.form.newContactInfo.postalCode}
                      onChange={this.handleNewContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Home Phone</ControlLabel>
                    <FormControl
                      name="homePhone"
                      type="text"
                      placeholder="Home Phone"
                      value={this.state.form.newContactInfo.homePhone}
                      onChange={this.handleNewContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Cell Phone</ControlLabel>
                    <FormControl
                      name="cellPhone"
                      type="text"
                      placeholder="Cell Phone"
                      value={this.state.form.newContactInfo.cellPhone}
                      onChange={this.handleNewContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Work Phone</ControlLabel>
                    <FormControl
                      name="workPhone"
                      type="text"
                      placeholder="Work Phone"
                      value={this.state.form.newContactInfo.workPhone}
                      onChange={this.handleNewContactInfoInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <h3>Certification</h3>
              </Row>
              <Row>
                <Col md={1}>
                  <Checkbox
                    name="certification1"
                    value={this.state.form.certification1}
                    checked={this.state.form.certification1}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={11}>
                  <p>
                    I/We certified that I/We are the registered owner(s) of the
                    above property and hereby request to have our contact
                    information change as per the above or;
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={1}>
                  <Checkbox
                    name="certification2"
                    value={this.state.form.certification2}
                    checked={this.state.form.certification2}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={11}>
                  <p>
                    I/We certified that we have been appointed as the management
                    company for the above property and hereby request to have
                    our contact information change as per the above. I/We have
                    enclosed with this form a copy of the first and last page of
                    our management contract, outlining our appointment details;
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={1}>
                  <Checkbox
                    name="certification3"
                    value={this.state.form.certification3}
                    checked={this.state.form.certification3}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={11}>
                  <p>
                    OFFICE USE ONLY, return mail has been received for the above
                    unit on three times in a row, as such the mailing address
                    has been changed to the unit address.
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                <h3>Date and Signature(s)</h3>
              </Row>
              <Row>
                <Col md={4}>
                  <ControlLabel>Date</ControlLabel>
                  <FormControl
                    name="date"
                    type="text"
                    placeholder="Date"
                    value={this.state.form.date}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={4}>
                  <ControlLabel>Signature</ControlLabel>
                  <FormControl
                    name="sig1"
                    type="text"
                    placeholder="Signature"
                    value={this.state.form.sig1}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col md={4}>
                  <ControlLabel>Signature</ControlLabel>
                  <FormControl
                    name="sig2"
                    type="text"
                    placeholder="Signature"
                    value={this.state.form.sig2}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}
export default ChangeOfAddress;
