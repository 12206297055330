import React, { Component } from 'react';
import { Grid, Row, Col, Button, Glyphicon, Panel } from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import request from '../../../utils/Request';
import StrataUnitUserTable from '../../utils/dataTable/StrataUnitUserTable';
import EditStrataUnitOwnerAddress from './EditStrataUnitOwnerAddress';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strataUnitUsers: [],
      strataProperty: {},
      strataUnit: {},
      strataOwner: {},
      pad: {},
      monthlyPayment: {},
      isPrimaryOwner: false,
      extraPayments: {},
      showEditAddress: false
    };
    this.toggleEditAddress = this.toggleEditAddress.bind(this);
    this.handleAddressInputChange = this.handleAddressInputChange.bind(this);

    this.getUnitPad = (strataOwners) => {
      let pad = {};
      strataOwners.forEach((owner) => {
        if (owner.isCurrent === true) {
          pad = owner.preAuthorizedDebit;
        }
      });
      return pad;
    };

    this.getExtraPayment = (payments) => {
      let returnValue = null;
      if (payments !== null) {
        if (payments.padStatus === 0) {
          returnValue = payments;
        }
      }
      return returnValue;
    };

    this.getMonthlyPayment = (payments) => {
      let returnValue = null;
      if (payments !== null) {
        payments.forEach((payment) => {
          if (payment.archived === false) {
            returnValue = payment;
          }
        });
      }
      return returnValue;
    };

    this.isPrimaryOwner = (unitId, users) => {
      let returnValue = false;
      if (users.length > 0) {
        users.forEach((user) => {
          if (
            user.userId.toString() === localStorage.getItem('user_id') &&
            user.strataUnitId === unitId &&
            user.strataUnitRole === 0
          ) {
            returnValue = true;
          }
        });
      }
      return returnValue;
    };

    this.getStrataOwner = (strataOwners) => {
      let strataOwner = {};
      if (strataOwners.length > 0) {
        strataOwners.forEach((owner) => {
          if (owner.isCurrent === true) {
            strataOwner = owner;
          }
        });
      }
      return strataOwner;
    };

    this.getUnitRole = (id) => {
      switch (id) {
        case 0:
          return 'Current Primary User';
        case 1:
          return 'Current Secondary User';
        case 2:
          return 'Past Owner';
        case 3:
          return 'Current Tenant';
        case 4:
          return 'Past Tenant';
        case 5:
          return 'Defunct';
        default:
          return 'No Role Selected';
      }
    };

    this.icon = (header) => (
      <div>
        {header}{' '}
        <Glyphicon
          glyph="glyphicon glyphicon-plus"
          style={{ float: 'right' }}
        />
      </div>
    );
  }

  handleAddressInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      strataOwner: { ...prevState.strataOwner, [name]: value }
    }));
  }

  toggleEditAddress() {
    this.setState((prevState) => ({
      showEditAddress: !prevState.showEditAddress
    }));
  }

  componentDidMount() {
    this.loadStrataUnit();
    this.loadExtraPayments();
  }

  getUsers(unitUsers) {
    let returnName = {
      username: 'No Users Selected',
      phone: 'No Phone Number',
      role: 'No Users'
    };
    const returnArray = [];
    unitUsers.forEach((unit) => {
      if (
        unit.strataUnitRole === 0 ||
        unit.strataUnitRole === 1 ||
        unit.strataUnitRole === 3
      ) {
        returnName = {
          username: unit.user.userName,
          phoneNumber: !unit.user.phoneNumber
            ? 'No Phone Number'
            : unit.user.phoneNumber,
          firstName: unit.firstName,
          lastName: unit.lastName,
          email: unit.user.email,
          role: this.getUnitRole(unit.strataUnitRole),
          action: (
            <Button
              bsSize="xsmall"
              style={{ marginLeft: 2.5, marginRight: 2.5 }}
              bsStyle="primary"
              onClick={() => {
                this.props.history.push(
                  `/strataAdmin/user/edit/${unit.user.id}`
                );
              }}>
              <Glyphicon glyph="cog" />
            </Button>
          )
        };
        returnArray.push(returnName);
      }
    });
    return returnArray;
  }

  loadStrataUnit() {
    const { match } = this.props;
    return request
      .get(`/StrataUnit/${match.params.id}`)
      .then((strataUnit) =>
        this.setState({
          strataProperty: strataUnit.strataProperty,
          strataUnit,
          strataOwner: this.getStrataOwner(strataUnit.strataOwners),
          strataUnitUsers: this.getUsers(strataUnit.strataUnitUser),
          isPrimaryOwner: this.isPrimaryOwner(
            strataUnit.id,
            strataUnit.strataUnitUser
          ),
          pad: this.getUnitPad(strataUnit.strataOwners),
          monthlyPayment: this.getMonthlyPayment(
            strataUnit.strataMonthlyPayment
          )
        })
      )
      .catch(() => {
        toast('Could Not Load Strata Unit.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  loadExtraPayments() {
    const { match } = this.props;
    return request
      .get(`/StrataExtraPayments/unit/${match.params.id}`)
      .then((extraPayment) =>
        this.setState({
          extraPayments: this.getExtraPayment(extraPayment)
        })
      )
      .catch(() => {
        toast('Could Not Load Extra Payments.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  loadBankInfo() {
    const s = this.state.pad;
    if (s === undefined || s === null || s === {}) {
      return (
        <p style={{ marginBottom: 10 }}>
          <span style={{ fontWeight: 'bold' }}>
            No Unit Pre Authorized Debit Set Up{' '}
          </span>
        </p>
      );
    }
    return <div />;
  }

  deletePad(id) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Are you sure you want to delete this? This CAN NOT be undone.'
      )
    ) {
      request
        .delete(`/preAuthorizedDebit/${id}`)
        .then(() =>
          this.setState({
            pad: {},
            monthlyPayment: {}
          })
        )
        .then(() => {
          this.loadStrataUnit();
          this.loadExtraPayments();
        })
        .catch(() => {
          toast('Could Not Delete PAD.', {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: css({
              background: 'black'
            })
          });
        });
    }
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <section className="section m-none">
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <h3 style={{ marginBottom: 15 }}>
                    Unit: {this.state.strataUnit.unitNumber} -{' '}
                    {this.state.strataProperty.address} <br /> Strata Plan:{' '}
                    {this.state.strataProperty.strataPlanNumber} -{' '}
                    {this.state.strataProperty.buildingName}
                  </h3>
                </Col>
              </Row>
              <Row>
                {this.state.isPrimaryOwner === true ? (
                  <div>
                    <Col md={6}>
                      <Panel header="Unit Details">
                        <div className="panel-body" style={{ height: 150 }}>
                          <Col xs={8}>
                            <p style={{ marginBottom: 10 }}>
                              <span style={{ fontWeight: 'bold' }}>
                                Unit Number:{' '}
                              </span>
                              {this.state.strataUnit.unitNumber}
                            </p>
                            <p style={{ marginBottom: 10 }}>
                              <span style={{ fontWeight: 'bold' }}>
                                Lot Number :{' '}
                              </span>
                              {this.state.strataUnit.lotNumber}
                            </p>
                            <p style={{ marginBottom: 10 }}>
                              <span style={{ fontWeight: 'bold' }}>
                                Primary Owner :{' '}
                              </span>
                              {this.state.strataOwner.nameOnTitle}
                            </p>
                          </Col>
                        </div>
                      </Panel>
                    </Col>
                    <Col md={6}>
                      <Panel header="Building Details">
                        <div className="panel-body" style={{ height: 150 }}>
                          <Col xs={8}>
                            <p style={{ marginBottom: 10 }}>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '14px'
                                }}>
                                Building Name :{' '}
                              </span>
                              {this.state.strataProperty.buildingName}
                            </p>
                            <p style={{ marginBottom: 10 }}>
                              <span style={{ fontWeight: 'bold' }}>
                                Strata Plan :{' '}
                              </span>
                              {this.state.strataProperty.strataPlanNumber}
                            </p>
                            <p style={{ marginBottom: 10 }}>
                              <span style={{ fontWeight: 'bold' }}>
                                Building Address :{' '}
                              </span>
                              {this.state.strataProperty.address}
                            </p>
                          </Col>
                        </div>
                      </Panel>
                    </Col>
                    {this.state.pad !== null ||
                    this.state.extraPayments !== null ? (
                      <div>
                        <Col xs={6}>
                          <Panel header="Extra Payments">
                            <div className="panel-body" style={{ height: 150 }}>
                              <Col xs={8}>
                                <p style={{ marginBottom: 10 }}>
                                  <span style={{ fontWeight: 'bold' }}>
                                    One Time Payment : $
                                  </span>
                                  {this.state.extraPayments &&
                                  this.state.extraPayments.oneTimePayment
                                    ? this.state.extraPayments.oneTimePayment
                                    : ''}
                                </p>
                                <p style={{ marginBottom: 10 }}>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Monthly Payment : $
                                  </span>
                                  {this.state.extraPayments &&
                                  this.state.extraPayments.monthlyPayment1
                                    ? this.state.extraPayments.monthlyPayment1
                                    : ''}
                                </p>
                                <p style={{ marginBottom: 10 }}>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Additional Monthly Payment : $
                                  </span>
                                  {this.state.extraPayments &&
                                  this.state.extraPayments.monthlyPayment2
                                    ? this.state.extraPayments.monthlyPayment2
                                    : ''}
                                </p>
                              </Col>
                            </div>
                          </Panel>
                        </Col>
                        <Col xs={6}>
                          <Panel header="Monthly Payment">
                            <div className="panel-body" style={{ height: 150 }}>
                              <Col xs={12}>
                                <p style={{ marginBottom: 10 }}>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Total Fees : $
                                  </span>
                                  {this.state.monthlyPayment &&
                                  this.state.monthlyPayment.totalFees
                                    ? this.state.monthlyPayment.totalFees
                                    : ''}
                                </p>
                                <p style={{ marginBottom: 10 }}>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Withdraw Type :{' '}
                                  </span>
                                  {this.state.monthlyPayment ? 'Monthly' : ''}
                                </p>
                                <p style={{ marginBottom: 10 }}>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Payment Start Date :{' '}
                                  </span>
                                  {this.state.monthlyPayment &&
                                  this.state.monthlyPayment.paymentStartDate
                                    ? this.state.monthlyPayment.paymentStartDate.slice(
                                        0,
                                        10
                                      )
                                    : ''}
                                </p>
                              </Col>
                            </div>
                          </Panel>
                        </Col>
                      </div>
                    ) : null}

                    <Col xs={6} />
                  </div>
                ) : null}
              </Row>
              {this.state.isPrimaryOwner === true ? (
                <Row>
                  <Col md={12}>
                    <Button
                      bsStyle="primary pull-right"
                      style={{ marginRight: 10 }}
                      bsSize="xsmall"
                      onClick={() => {
                        this.props.history.push({
                          pathname: `/strataUnitOwner/pad/create/${this.state.strataOwner.id}`,
                          state: { unitId: this.state.strataUnit.id }
                        });
                      }}>
                      {this.state.pad
                        ? 'Update Pre Authorized Debit Form'
                        : 'Fill Out Pre Authorized Debit Form'}
                    </Button>
                    {this.state.monthlyPayment ? (
                      <Button
                        bsStyle="primary pull-right"
                        style={{ marginRight: 10 }}
                        bsSize="xsmall"
                        onClick={() => {
                          this.props.history.push({
                            pathname: `/strataUnitOwner/monthlyPayment/replace/strataUnit/${this.state.strataUnit.id}`,
                            state: {
                              strataUnit: this.state.strataUnit,
                              nameOnTitle: this.state.strataOwner.nameOnTitle,
                              pad: this.state.pad,
                              monthlyPayment: this.state.monthlyPayment
                            }
                          });
                        }}>
                        Replace Monthly Payment
                      </Button>
                    ) : (
                      <Button
                        bsStyle="primary pull-right"
                        style={{ marginRight: 10 }}
                        bsSize="xsmall"
                        onClick={() => {
                          if (this.state.pad) {
                            this.props.history.push({
                              pathname: `/strataUnitOwner/monthlyPayment/strataUnit/${this.state.strataUnit.id}`,
                              state: {
                                strataUnit: this.state.strataUnit,
                                nameOnTitle: this.state.strataOwner.nameOnTitle,
                                pad: this.state.pad
                              }
                            });
                          } else {
                            this.setState({});
                          }
                        }}>
                        Fill Out Monthly Payment
                      </Button>
                    )}

                    <Button
                      bsStyle="primary pull-right"
                      style={{ marginRight: 10 }}
                      bsSize="xsmall"
                      onClick={() => {
                        this.props.history.push({
                          pathname: `/strataUnitOwner/forms/extraPayments/${this.state.strataUnit.id}`,
                          state: {
                            strataUnit: this.state.strataUnit,
                            extraPayments: this.state.extraPayments
                          }
                        });
                      }}>
                      Extra Payments
                    </Button>

                    <Button
                      bsStyle="primary pull-right"
                      style={{ marginRight: 10 }}
                      bsSize="xsmall"
                      onClick={() => {
                        this.toggleEditAddress();
                      }}>
                      Change Of Address
                    </Button>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <StrataUnitUserTable strataUsers={this.state.strataUnitUsers} />
              </Row>
            </Grid>
          </div>
          <EditStrataUnitOwnerAddress
            owner={this.state.strataOwner}
            inputChange={this.handleAddressInputChange}
            show={this.state.showEditAddress}
            closeModal={this.toggleEditAddress}
          />
        </section>
      </div>
    );
  }
}

export default Details;
