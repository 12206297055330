import React from 'react';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Auth from '../../../utils/Auth';
import logo from '../../../images/logoV2.png';

export default () => {
  const history = useHistory();

  const logout = (event) => {
    event.preventDefault();
    Auth.logout();
    history.push('/strataLogin');
  };

  return (
    <Navbar collapseOnSelect>
      <Navbar.Header>
        <Navbar.Brand>
          <img src={logo} className="logo" alt="Korecki Real Estate Services" />
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav pullRight>
          <LinkContainer to="/strataAdmin/dashboard">
            <NavItem eventKey={1}>Properties</NavItem>
          </LinkContainer>
          <NavDropdown eventKey={6} title="Documents" id="login-nav-dropdown">
            <LinkContainer to="/strataAdmin/document/upload?documentFor=property">
              <MenuItem eventKey={6.1}>Strata Property Documents</MenuItem>
            </LinkContainer>
            <LinkContainer to="/strataAdmin/document/upload?documentFor=unit">
              <MenuItem eventKey={6.2}>Strata Unit Documents</MenuItem>
            </LinkContainer>
          </NavDropdown>
          <LinkContainer to="/strataAdmin/user/list">
            <NavItem eventKey={1}>Building / User Log Ins</NavItem>
          </LinkContainer>
          <LinkContainer to="/strataAdmin/agent/list">
            <NavItem eventKey={1}>Strata Agents</NavItem>
          </LinkContainer>
          <NavDropdown
            eventKey={6}
            title="Import/Export"
            id="login-nav-dropdown">
            <LinkContainer to="/strataAdmin/import-owners">
              <NavItem eventKey={1}>Import Owners</NavItem>
            </LinkContainer>
          </NavDropdown>
          <NavItem eventKey={2} onClick={logout}>
            Log Out
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
