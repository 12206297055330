import React from 'react';
import { useHistory } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import Auth from '../../../utils/Auth';
import ScrollToTop from '../../utils/ScrollToTop';
import logo from '../../../images/logoV2.png';

export default ({ children }) => {
  const history = useHistory();

  const logout = (event) => {
    event.preventDefault();
    Auth.logout();
    history.push('/login');
  };

  return (
    <div>
      <Navbar collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <img
              src={logo}
              className="logo"
              alt="Korecki Real Estate Services"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            <LinkContainer to="/owner/dashboard">
              <NavItem eventKey={1}>Dashboard</NavItem>
            </LinkContainer>
            <NavItem eventKey={2} onClick={logout}>
              Log Out
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ScrollToTop>
        <main>{children}</main>
      </ScrollToTop>
    </div>
  );
};
