import React from 'react';
import ScrollToTop from '../../utils/ScrollToTop';
import SuperStrataAdminHeader from './SuperStrataAdminHeader';
import StrataAdminHeader from './StrataAdminHeader';

export default ({ children }) => {
  const role = localStorage.getItem('role');

  return (
    <div>
      {role === 'superAdmin' && <SuperStrataAdminHeader />}
      {role === 'strataAdmin' && <StrataAdminHeader />}
      <ScrollToTop>
        <main>{children}</main>
      </ScrollToTop>
    </div>
  );
};
