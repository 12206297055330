import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Glyphicon,
  Button,
  Modal,
  FormControl,
  Accordion,
  Panel
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import request from '../../../utils/Request';
import StrataUnitTable from '../../utils/dataTable/StrataUnitTable';
import StrataUnitAdd from '../strataUnit/Add';
import EditStrataUnit from '../strataUnit/EditStrataUnit';
import ReplaceOwner from '../strataUnit/ReplaceOwner';
import ErrorAlert from '../../utils/ErrorAlert';

class Detail extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      error: false,
      errorMessage: '',
      selectedStrataUnit: {},
      strataUnits: [],
      property: {},
      documents: [],
      agent: {},
      categories: [],
      staff: [],
      id: params.id,
      show: false,
      showUnit: false,
      showReplaceModal: false,
      strataOwner: {
        nameOnTitle: '',
        strataRole: '',
        address1: ''
      },
      showEditUnit: false,
      folder: {
        name: '',
        type: 'property',
        propertyId: params.id
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.toggleAddUnit = this.toggleAddUnit.bind(this);
    this.toggleEditUnit = this.toggleEditUnit.bind(this);
    this.toggleReplaceOwner = this.toggleReplaceOwner.bind(this);
    this.loadStrataUnits = this.loadStrataUnits.bind(this);
    this.handleStrataUnitInputChange = this.handleStrataUnitInputChange.bind(
      this
    );
    this.addFolder = this.addFolder.bind(this);

    this.getOwnerName = (strataOwners) => {
      let returnName = 'No Registered Owners';
      if (strataOwners.length > 0) {
        strataOwners.forEach((owner) => {
          if (owner && owner.pending === true) {
            returnName = owner.nameOnTitle;
          }
          if (owner && owner.isCurrent === true) {
            returnName = owner.nameOnTitle;
          }
        });
      }
      return returnName;
    };

    this.getPending = (strataOwners) => {
      let returnVal = true;
      if (strataOwners.length > 0) {
        strataOwners.forEach((owner) => {
          if (owner && owner.isCurrent === true) {
            returnVal = owner.pending;
          }
        });
      }
      return returnVal;
    };

    this.compare = (a, b) => {
      const alpha = a.title;
      const beta = b.title;
      let comparison = 0;
      if (alpha < beta) {
        comparison = 1;
      } else if (alpha > beta) {
        comparison = -1;
      }
      return comparison;
    };

    this.icon = (header) => (
      <div>
        {header}{' '}
        <Glyphicon
          glyph="glyphicon glyphicon-plus"
          style={{ float: 'right' }}
        />
      </div>
    );
  }

  componentDidMount() {
    this.loadProperty();
    this.loadCategories();
    this.loadStaff();
    this.loadStrataUnits();
  }

  handleStrataUnitInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState((prevState) => ({
      selectedStrataUnit: { ...prevState.selectedStrataUnit, [name]: value }
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.categories !== this.state.categories) {
      this.loadDocuments();
    }

    if (prevState.property !== this.state.property) this.loadAgent();
  }

  loadCategories() {
    request.get('/StrataDocumentCategories/all').then((categories) => {
      this.setState(
        {
          categories
        },
        () => {}
      );
    });
  }

  loadProperty() {
    const {
      match: { params }
    } = this.props;
    request.get(`/strataproperty/${params.id}`).then((property) => {
      this.setState({
        property
      });
    });
  }

  loadAgent() {
    request
      .get(`/strataAgent/${this.state.property.agentId}`)
      .then((res) => {
        this.setState({ agent: res });
      })
      .catch(() => {});
  }

  loadStaff() {
    const {
      match: { params }
    } = this.props;
    request
      .get(`/stratapropertystaff/property/${params.id}`)
      .then((staff) => {
        if (staff) {
          this.setState({
            staff
          });
        }
      })
      .catch(() => {});
  }

  loadStrataUnits() {
    const {
      match: { params }
    } = this.props;
    request
      .get(`/strataUnit/strataProperty/${params.id}?archived=false`)
      .then((units) => {
        this.setState({
          strataUnits: units.map((u) => ({
            ...u,
            property_link: (
              <Link to={`/strataAdmin/strataUnit/${u.id}`}>{u.unitNumber}</Link>
            ),
            strataPlan: u.strataProperty.strataPlanNumber,
            nameOnTitle: this.getOwnerName(u.strataOwners),
            confirmed: this.getPending(u.strataOwners) === false ? 'Yes' : 'No',
            button: (
              <div className="text-center">
                <Button
                  bsSize="xsmall"
                  style={{ marginRight: 2.5 }}
                  bsStyle="primary"
                  onClick={() => {
                    this.setSelectedUnit(u);
                  }}>
                  <Glyphicon glyph="cog" />
                </Button>
                <Button
                  bsSize="xsmall"
                  style={{ marginRight: 2.5 }}
                  bsStyle="success"
                  onClick={() => {
                    this.replaceStrataOwner(u);
                  }}>
                  <Glyphicon glyph="glyphicon glyphicon-refresh" />
                </Button>
                <Button
                  bsSize="xsmall"
                  style={{ marginRight: 2.5 }}
                  bsStyle="warning"
                  onClick={() => {
                    this.resendEmail(u.id);
                  }}>
                  <Glyphicon glyph="glyphicon glyphicon-repeat" />
                </Button>
                <Button
                  bsSize="xsmall"
                  style={{ marginRight: 2.5 }}
                  bsStyle="danger"
                  onClick={() => {
                    this.archiveStrataUnit(u.id);
                  }}>
                  <Glyphicon glyph="remove" />
                </Button>
              </div>
            )
          }))
        });
      });
  }

  replaceStrataOwner(strataUnit) {
    let registeredOwner = {};
    if (strataUnit.strataOwners.length > 0) {
      strataUnit.strataOwners.forEach((owner) => {
        if (owner && owner.isCurrent === true) {
          registeredOwner = owner;
        }
      });
    }
    this.setState({
      strataOwner: registeredOwner,
      selectedStrataUnit: strataUnit
    });
    this.toggleReplaceOwner();
  }

  toggleReplaceOwner() {
    this.setState((prevState) => ({
      showReplaceModal: !prevState.showReplaceModal
    }));
  }

  setSelectedUnit(u) {
    this.setState({ selectedStrataUnit: u });
    this.toggleEditUnit();
  }

  archiveStrataUnit(id) {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'The unit will be archived and moved to a different directory'
      )
    ) {
      request
        .put(`/strataUnit/archive/${id}`)
        .then(() => {
          this.loadStrataUnits();
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Error archiving document'
          });
        });
    }
  }

  resendEmail(id) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to resend the form?')) {
      request
        .put(`/strataUnitUserInvitation/resend/${id}`)
        .then(() => {})
        .catch(() => {
          this.setState({ error: true, errorMessage: 'Error resending form.' });
        });
    }
  }

  loadDocuments() {
    const {
      match: { params }
    } = this.props;
    request
      .get(`/stratadocuments/property/${params.id}?archived=false`)
      .then((documents) => {
        this.setState({ documents });
      })
      .catch((err) => {
        this.setState({ error: true, errorMessage: err.message });
      });
  }

  deleteStaff(id) {
    const element = document.getElementById(id);
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'Are you sure you want to delete this? This CAN NOT be undone.'
      )
    ) {
      request
        .delete(`/stratapropertystaff/delete/${id}`)
        .then(element.parentNode.removeChild(element))
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Error deleting document'
          });
        });
    }
  }

  archiveDocument(id) {
    const element = document.getElementById(id);
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        'The document will be archived and moved to a different directory'
      )
    ) {
      request
        .put(`/stratadocuments/archive/${id}`)
        .then(() => {
          element.parentNode.removeChild(element);
        })
        .catch(() => {
          this.setState({
            error: true,
            errorMessage: 'Error archiving document'
          });
        });
    }
  }

  showDocument(id) {
    this.props.history.push({
      pathname: `/strataAdmin/document/view/${id}`,
      params: {
        documentFor: 'property'
      }
    });
  }

  editDocument(id) {
    this.props.history.push({
      pathname: `/strataAdmin/document/edit/${id}`,
      address: this.state.property.address
    });
  }

  getCategory(id) {
    const { categories } = this.state;
    if (categories === undefined || categories.length === 0) {
      return id;
    }

    let category;
    categories.forEach((c) => {
      if (c.id === id) {
        category = c.name;
      }
    });
    return category;
  }

  loadStaffInfo() {
    const s = this.state.staff;
    if (s === undefined || s.length === 0) {
      return (
        <p style={{ marginBottom: 10 }}>
          <span style={{ fontWeight: 'bold' }}>No Building Staff </span>
        </p>
      );
    }
    return s.map((staff) => {
      const daysArray = staff.hours.split(',');
      return (
        <Col md={6}>
          <Panel style={{ marginBottom: 5 }} id={staff.id}>
            <h4>
              {staff.position}{' '}
              <Button
                bsSize="xsmall"
                bsStyle="danger"
                style={{ float: 'right' }}
                onClick={() => {
                  this.deleteStaff(staff.id);
                }}>
                <Glyphicon glyph="trash" />
              </Button>
            </h4>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Name : </span>
              {staff.name}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Phone : </span>
              {staff.phone}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Email : </span>
              {staff.email}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Monday: </span>
              {daysArray[0]}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Tuesday: </span>
              {daysArray[1]}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Wednesday: </span>
              {daysArray[2]}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Thursday: </span>
              {daysArray[3]}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Friday: </span>
              {daysArray[4]}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Saturday: </span>
              {daysArray[5]}
            </p>
            <p style={{ marginBottom: 10 }}>
              <span style={{ fontWeight: 'bold' }}>Sunday: </span>
              {daysArray[6]}
            </p>
          </Panel>
        </Col>
      );
    });
  }

  handleClose() {
    this.setState({ show: false });
  }

  toggleAddUnit() {
    this.setState((prevState) => ({ showUnit: !prevState.showUnit }));
    this.loadStrataUnits();
  }

  toggleEditUnit() {
    this.setState((prevState) => ({ showEditUnit: !prevState.showEditUnit }));
  }

  toggleAndRefreshUnit() {
    this.setState((prevState) => ({ showUnit: !prevState.showUnit }));
    this.loadStrataUnits();
  }

  toggleUnitSubmit() {
    this.loadProperty();
    this.setState({ showUnit: false });
  }

  addFolder() {
    request.post('/directory/', this.state.folder).then(() => {
      this.handleClose();
      this.loadDirectories();
    });
  }

  generatePanels() {
    const { categories } = this.state;
    const { documents } = this.state;

    return categories.map((c) => {
      const catDocs = [];
      documents.forEach((d) => {
        if (c.id === d.strataDocumentCategoryId) {
          catDocs.push({ ...d, title: d.title || 'No File Name' });
        }
      });
      catDocs.sort(this.compare);
      if (catDocs.length > 0) {
        return (
          <div>
            <Grid>
              <Row>
                <Col md={12}>
                  <Accordion>
                    <Panel header={this.icon(c.name)} eventKey="1">
                      {catDocs.map((d) => (
                        <Grid>
                          <Row
                            id={d.id}
                            style={{
                              clear: ' both',
                              overflow: 'hidden',
                              paddingRight: '40px'
                            }}>
                            <Col md={6}>
                              <p style={{ fontSize: '16px' }}>
                                <a
                                  href={`${process.env.REACT_APP_SITE_API}/api/stratadocuments/${d.id}/file`}>
                                  {d.title}
                                </a>
                              </p>
                            </Col>
                            <Col md={6}>
                              <Button
                                bsSize="small"
                                bsStyle="warning"
                                style={{
                                  marginRight: 5,
                                  marginLeft: 5,
                                  float: 'right'
                                }}
                                onClick={() => {
                                  this.archiveDocument(d.id);
                                }}>
                                <Glyphicon glyph="remove" />
                              </Button>
                              <Button
                                bsSize="small"
                                bsStyle="primary"
                                style={{
                                  marginRight: 5,
                                  marginLeft: 5,
                                  float: 'right'
                                }}
                                onClick={() => {
                                  this.editDocument(d.id);
                                }}>
                                <Glyphicon glyph="cog" />
                              </Button>
                              <Button
                                bsSize="small"
                                bsStyle="info"
                                style={{
                                  marginRight: 5,
                                  marginLeft: 5,
                                  float: 'right'
                                }}
                                onClick={() => {
                                  this.showDocument(d.id);
                                }}>
                                <Glyphicon glyph="eye-open" />
                              </Button>
                              <Button
                                bsSize="small"
                                bsStyle="success"
                                style={{
                                  marginRight: 5,
                                  marginLeft: 5,
                                  float: 'right'
                                }}
                                href={`${process.env.REACT_APP_SITE_API}/api/stratadocuments/${d.id}/file`}>
                                <Glyphicon glyph="download-alt" />
                              </Button>
                            </Col>
                          </Row>
                        </Grid>
                      ))}
                    </Panel>
                  </Accordion>
                </Col>
              </Row>
              <Row>
                {this.state.error ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}
              </Row>
            </Grid>
          </div>
        );
      }
      return null;
    });
  }

  render() {
    return (
      <div>
        <section className="section m-none">
          <Grid>
            <Row>
              <Col md={12}>
                <Link
                  className="btn btn-primary btn-xs pull-right"
                  to={{
                    pathname: `/strataAdmin/document/upload/${this.state.id}`,
                    params: {
                      strataPlanNumber: this.state.property.strataPlanNumber,
                      documentFor: 'property'
                    }
                  }}>
                  Add Document
                </Link>
                <Link
                  className="btn btn-primary btn-xs pull-right"
                  style={{ marginRight: 10 }}
                  to={`/strataAdmin/staff/${this.state.id}`}>
                  Add Staff
                </Link>
                <Link
                  className="btn btn-primary btn-xs pull-right"
                  style={{ marginRight: 10 }}
                  to={`/strataAdmin/edit/${this.state.id}`}>
                  Edit Property
                </Link>
              </Col>
              <Col md={12}>
                <h3 style={{ marginBottom: 15 }}>
                  Strata Plan {this.state.property.strataPlanNumber} -{' '}
                  {this.state.property.buildingName} ||{' '}
                  {this.state.property.address}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Panel header="Building Details">
                  <p style={{ marginBottom: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>Building Name : </span>
                    {this.state.property.buildingName}
                  </p>
                  <p style={{ marginBottom: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>Strata Plan : </span>
                    {this.state.property.strataPlanNumber}
                  </p>
                  <p style={{ marginBottom: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>
                      Building Address :{' '}
                    </span>
                    {this.state.property.address}
                  </p>
                </Panel>
              </Col>
              <Col md={6}>
                <Panel header="Strata Agent">
                  <p style={{ marginBottom: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>Agent Name : </span>
                    {this.state.agent.name}
                  </p>
                  <p style={{ marginBottom: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>Agent Phone : </span>
                    {this.state.agent.phone}
                  </p>
                  <p style={{ marginBottom: 10 }}>
                    <span style={{ fontWeight: 'bold' }}>Agent Email : </span>
                    {this.state.agent.email}
                  </p>
                </Panel>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button
                  onClick={this.toggleAddUnit}
                  bsStyle="primary pull-right"
                  style={{ marginRight: 10 }}
                  bsSize="xsmall">
                  Add Unit
                </Button>
              </Col>
            </Row>
            <Row>
              <StrataUnitTable strataUnits={this.state.strataUnits} />
            </Row>
            <Accordion>
              <Panel header={this.icon('Onsite Staff')} eventKey="3">
                {this.loadStaffInfo()}
              </Panel>
            </Accordion>
          </Grid>
          <Grid>
            <Row>
              <Col md={6}>
                <h3 style={{ marginBottom: 15 }}>Documents</h3>
              </Col>
            </Row>
          </Grid>
          {this.generatePanels()}
          <div className="row">
            <div className="col-md-12 text-center">
              <Link
                to={`/strataAdmin/property/archived/${this.state.id}`}
                className="margin-5 btn btn-outline btn-primary text-uppercase margin-bottom">
                Archived Documents
              </Link>
              <Link
                to={`/strataAdmin/strataUnits/archived/property/${this.state.id}`}
                className="margin-5 btn btn-outline btn-primary text-uppercase margin-bottom">
                Archived Strata Units
              </Link>
            </div>
          </div>
          <StrataUnitAdd
            loadStrataUnits={this.loadStrataUnits}
            propertyId={this.state.id}
            property={this.state.property.address}
            show={this.state.showUnit}
            closeModal={this.toggleAddUnit}
          />
          <ReplaceOwner
            loadStrataUnits={this.loadStrataUnits}
            strataOwner={this.state.strataOwner}
            show={this.state.showReplaceModal}
            strataUnitId={this.state.selectedStrataUnit.id}
            closeModal={this.toggleReplaceOwner}
          />
          <EditStrataUnit
            loadStrataUnits={this.loadStrataUnits}
            strataUnit={this.state.selectedStrataUnit}
            inputChange={this.handleStrataUnitInputChange}
            propertyId={this.state.id}
            show={this.state.showEditUnit}
            closeModal={this.toggleEditUnit}
          />
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header>
              <Modal.Title>New Folder</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <FormControl
                name="name"
                type="text"
                placeholder="Folder Name"
                value={this.state.folder.name}
              />
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={this.handleClose}>Close</Button>
              <Button onClick={this.addFolder} bsStyle="primary">
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
      </div>
    );
  }
}

export default Detail;
