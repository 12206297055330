import React, { useState } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import request from '../../../utils/Request';
import UserForm from '../forms/CreateUser';
import ErrorAlert from '../../utils/ErrorAlert';

export default ({ history }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    role: '',
    email: '',
    password: '',
    username: ''
  });
  const [userValid, setUserValid] = useState(false);

  const create = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      ...user,
      portal: 'strata'
    };
    if (data.role === 'Strata Owner') {
      data.email = `${data.username}@korecki.ca`;
    }
    try {
      const response = await request.post(
        `/user?role=${data.role}&userName=${data.username}`,
        data
      );
      if (response) {
        history.push('/strataAdmin/user/list');
      } else {
        setError(`Error creating new user${response}${response.id}`);
        setLoading(false);
      }
    } catch (e) {
      setError('Error creating new user. User may already exist.');
      setLoading(false);
    }
  };

  return (
    <div>
      <section className="section m-none">
        <div>
          <UserForm user={user} setUser={setUser} setValid={setUserValid} />
          <Grid>
            <Row>
              <Col md={12}>
                {!loading && (
                  <Button
                    bsStyle="primary"
                    bsSize="large"
                    disabled={!userValid}
                    blocktype="submit"
                    onClick={create}>
                    Create User
                  </Button>
                )}
                {loading && <div className="loader" />}
              </Col>
            </Row>
            <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
          </Grid>
        </div>
      </section>
    </div>
  );
};
