import React, { useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import Datatable from 'react-bs-datatable';

const onSortFunction = {
  date(columnValue) {
    // Convert the string date format to UTC timestamp
    // So the table could sort it by number instead of by string
    return new Date(columnValue);
  }
};

// Removes any duplicate document types from the list for the
// filter options
function removeDuplicates(documents) {
  const documentTypes = [documents.map((doc) => doc.type)];
  return [...new Set(documentTypes[0])];
}

const DocumentTable = (props) => {
  const [filterText, setFilterText] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState([]);

  const documentTypes = [removeDuplicates(props.documents)];

  const header = [
    { title: 'File Name', prop: 'fileName', sortable: true, filterable: true },
    { title: 'Document Type', prop: 'type', sortable: true, filterable: true },
    { title: 'Date', prop: 'date', sortable: true, filterable: true },
    { title: 'Download', prop: 'link', sortable: true },
    { title: 'Action', prop: 'action_buttons' }
  ];

  const clientHeader = [
    { title: 'File Name', prop: 'fileName', sortable: true, filterable: true },
    { title: 'Document Type', prop: 'type', sortable: true, filterable: true },
    { title: 'Download', prop: 'link', sortable: true }
  ];

  function filterDocuments(option) {
    const newFilterText = option;

    if (newFilterText) {
      setFilteredDocuments(
        props.documents.filter((doc) => doc.type === newFilterText)
      );
    } else {
      setFilteredDocuments(props.documents);
    }
    setFilterText(newFilterText);
  }

  return (
    <div>
      <Grid>
        <Row>
          <Col md={12}>
            <h5>Documents</h5>
          </Col>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Col md={12}>
            <div>
              <select
                name="filter"
                value={filterText}
                onChange={(e) => filterDocuments(e.target.value)}
                className="documentTypeFilter">
                <option value="">Document Type</option>
                {documentTypes[0].map((document, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <option value={document.id} key={index}>
                    {document}
                  </option>
                ))}
              </select>
            </div>
            <Datatable
              tableHeader={props.hideAction ? clientHeader : header}
              tableBody={filterText ? filteredDocuments : props.documents}
              keyName="documentTable"
              tableClass="table table-striped table-bordered table-condensed table-hover"
              rowsPerPage={10}
              rowsPerPageOption={[5, 10, 15, 20]}
              initialSort={{ prop: 'date', isAscending: true }}
              onSort={onSortFunction}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default DocumentTable;
