import React, { Component } from 'react';
import {
  Grid,
  Col,
  ControlLabel,
  FormGroup,
  Form,
  FormControl,
  Row,
  Alert
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import request from '../../utils/Request';
import ErrorAlert from './ErrorAlert';

class EditDocument extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      documentTitle: '',
      propertyId: 0,
      propertyDocTypeId: '',
      docTypes: [],
      disabled: false,
      error: false,
      errorMessage: '',
      submit: false,
      form: {
        id: params.id,
        fileName: '',
        documentTypeId: 0
      }
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.save = this.save.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.loadDocument();
    this.loadDocTypes();
  }

  loadDocument() {
    const {
      match: { params }
    } = this.props;
    request
      .get(`/documents/${params.id}`)
      .then((document) => {
        console.log({ document });
        this.setState({
          documentTitle: document.fileName,
          propertyId: document.propertyId,
          propertyDocTypeId: document.documentTypeId
        });
      })
      .catch(() => {
        toast('Could Not Load Docs.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      });
  }

  async loadDocTypes() {
    const ownerDts = [];
    const propertyDts = [];
    try {
      const types = await request.get(`/documents/documentTypes`);
      types.forEach((type) => {
        if (
          type.name.toLowerCase() === 'financial statements' ||
          type.name.toLowerCase() === 'correspondence'
        ) {
          ownerDts.push(type);
        } else if (
          type.name.toLowerCase() === 'lease agreements' ||
          type.name.toLowerCase() === 'management contracts' ||
          type.name.toLowerCase() === 'strata documents' ||
          type.name.toLowerCase() === 'invoices'
        ) {
          propertyDts.push(type);
        } else {
          ownerDts.push(type);
          propertyDts.push(type);
        }
        if (this.state.propertyId == null) {
          this.setState({
            docTypes: ownerDts
          });
        } else {
          this.setState({
            docTypes: propertyDts
          });
        }
        console.log(this.state.propertyId);
      });
    } catch (e) {
      toast('Could not load document types', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: css({
          background: 'black'
        })
      });
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState((prevState) => ({
      form: { ...prevState.form, [name]: value }
    }));
  }

  save(e) {
    e.preventDefault();
    const data = this.state.form;
    if (!data.fileName) {
      data.fileName = this.state.documentTitle;
    } else if (!data.documentTypeId) {
      data.documentTypeId = this.state.propertyDocTypeId;
    }
    this.update(data); // If there is no file getting re-uploaded
  }

  update(data) {
    request
      .put('/documents/update', data)
      .then(() => {
        this.setState({
          submit: true
        });
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMessage: `${e.message}`
        });
      });
  }

  render() {
    return (
      <div>
        <section className="section m-none">
          <Grid>
            <Col md={12}>
              <h3>Edit Document</h3>
            </Col>
            <Form>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>New Document Title</ControlLabel>
                  <FormControl
                    name="fileName"
                    type="text"
                    placeholder="New Title"
                    value={this.state.form.fileName}
                    onChange={this.handleInputChange}
                  />
                  <p>Title: {this.state.documentTitle}</p>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Change Document Type</ControlLabel>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    name="documentTypeId"
                    value={this.state.form.documentTypeId}
                    onChange={this.handleInputChange}>
                    <option value="">Choose one:</option>
                    {this.state.docTypes &&
                      this.state.docTypes.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </FormControl>
                  <p>
                    Document Type:{' '}
                    {this.state.docTypes &&
                      this.state.docTypes.map((item) => {
                        if (item.id === this.state.propertyDocTypeId) {
                          return item.name;
                        }
                        return null;
                      })}
                  </p>
                </FormGroup>
                <button
                  type="submit"
                  onClick={this.save}
                  className="btn btn-primary"
                  disabled={this.state.disabled}>
                  Submit
                </button>
              </Col>
              <Row>
                {this.state.error ? (
                  <ErrorAlert errorMessage={this.state.errorMessage} />
                ) : null}
              </Row>
              <Row>
                {this.state.submit ? (
                  <div style={{ marginTop: '10px' }}>
                    <Alert bsStyle="success">
                      Document successfully updated!
                    </Alert>
                  </div>
                ) : null}
              </Row>
            </Form>
          </Grid>
        </section>
      </div>
    );
  }
}

export default EditDocument;
