import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  ButtonToolbar
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import { css } from 'glamor';
import ErrorAlert from '../../utils/ErrorAlert';
import request from '../../../utils/Request';
import Dropzone from '../../utils/Dropzone';

const List = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({
    propertyId: null,
    ownerId: null,
    documentTypeId: 0,
    fileName: '',
    documentDate: moment()
  });
  const [propertyDocTypes, setPropertyDocTypes] = useState(null);
  const [ownerDocTypes, setOwnerDocTypes] = useState(null);
  const [documentFor, setDocumentFor] = useState('property');
  const [files, setFiles] = useState([]);
  const [propertyHolderArray, setPropertyHolderArray] = useState(null);
  const [ownerHolderArray, setOwnerHolderArray] = useState(null);

  useEffect(() => {
    const loadDocTypes = async () => {
      const ownerDts = [];
      const propertyDts = [];

      try {
        const types = await request.get(`/documents/documentTypes`);
        types.forEach((type) => {
          if (
            type.name.toLowerCase() === 'financial statements' ||
            type.name.toLowerCase() === 'correspondence'
          ) {
            ownerDts.push(type);
          } else if (
            type.name.toLowerCase() === 'lease agreements' ||
            type.name.toLowerCase() === 'management contracts' ||
            type.name.toLowerCase() === 'strata documents' ||
            type.name.toLowerCase() === 'invoices'
          ) {
            propertyDts.push(type);
          } else {
            ownerDts.push(type);
            propertyDts.push(type);
          }
        });

        setOwnerDocTypes(ownerDts);
        setPropertyDocTypes(propertyDts);
      } catch (e) {
        toast('Could not load document types', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      }
    };

    if (!ownerDocTypes || !propertyDocTypes) {
      loadDocTypes();
    }
  }, [ownerDocTypes, propertyDocTypes]);

  useEffect(() => {
    const loadAddresses = async () => {
      try {
        const properties = await request.get('/property');

        const holderArray = properties.map((prop) => ({
          id: prop.id,
          street: prop.street
        }));

        setPropertyHolderArray(holderArray);
      } catch (e) {
        toast('Could not load properties', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      }
    };

    if (!propertyHolderArray) {
      loadAddresses();
    }
  }, [propertyHolderArray]);

  useEffect(() => {
    const loadOwners = async () => {
      try {
        const owners = await request.get('/ownerprofile');
        const holderArray = owners.map((owner) => ({
          id: owner.id,
          name: owner.name
        }));
        setOwnerHolderArray(holderArray);
      } catch (e) {
        toast('Could not load owners', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: css({
            background: 'black'
          })
        });
      }
    };

    if (!ownerHolderArray) {
      loadOwners();
    }
  }, [ownerHolderArray]);

  const create = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!(form.ownerId || form.propertyId)) {
      setLoading(false);
      setError(`Please select a ${documentFor.toLowerCase()}`);
      return;
    }

    if (!form.documentTypeId) {
      setLoading(false);
      setError('Please select a document type');
      return;
    }

    if (!files.length) {
      setLoading(false);
      setError('Please select a file!');
      return;
    }

    if (files.length > 1) {
      setLoading(false);
      setError('Please choose only one file!');
      return;
    }

    // Single file only
    try {
      const doc = await request.post('/documents', {
        ...form,
        fileName: form.fileName || files[0].name
      });
      await request.upload2(`/documents/upload/${doc.id}`, files[0]);

      setLoading(false);
      setError(null);

      toast('Document Uploaded Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: css({
          background: 'black'
        })
      });

      // reset everything
      setFiles([]);
      setForm({
        propertyId: null,
        ownerId: null,
        documentTypeId: 0,
        fileName: '',
        documentDate: moment()
      });
      setDocumentFor('property');
      setPropertyHolderArray(null);
      setOwnerHolderArray(null);
    } catch (err) {
      setLoading(false);
      setError(`Error submitting form. ${err.message}`);
    }
  };

  const onDrop = (acceptedFiles) => {
    const allFiles = [...files, ...acceptedFiles];
    setFiles(allFiles);
  };

  const clearFiles = () => {
    setFiles([]);
  };

  const handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === 'select' ? target.selected : target.value;
    const { name } = target;
    setForm((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDateChange = (date) => {
    setForm((prev) => ({
      ...prev,
      documentDate: date
    }));
  };

  const onPropertySelect = (selected) => {
    setForm((prev) => ({
      ...prev,
      propertyId: selected[0] != null ? selected[0].id : null
    }));
  };

  const onOwnerSelect = (selected) => {
    setForm((prev) => ({
      ...prev,
      ownerId: selected[0] != null ? selected[0].id : null
    }));
  };

  const handleDocumentFor = (event) => {
    const { target } = event;
    const value = target.type === 'select' ? target.selected : target.value;
    setDocumentFor(value);
  };

  return (
    <div style={{ padding: 35 }}>
      <ToastContainer />
      <h1 style={{ fontSize: 20 }}>Upload Documents</h1>
      <Form>
        <FormGroup>
          <ControlLabel>Document For:</ControlLabel>
          <FormControl
            componentClass="select"
            placeholder="select"
            name="documentFor"
            value={documentFor}
            onChange={handleDocumentFor}>
            <option value="property">Property</option>
            <option value="owner">Owner</option>
          </FormControl>
        </FormGroup>

        {documentFor === 'property' && (
          <>
            <FormGroup>
              <ControlLabel>Type of Document</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="select"
                name="documentTypeId"
                value={form.documentTypeId}
                onChange={handleInputChange}>
                <option value="">Choose one:</option>
                {propertyDocTypes &&
                  propertyDocTypes.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
              </FormControl>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Property Address</ControlLabel>
              <Typeahead
                id="propertyAddress"
                labelKey="street"
                name="propertyAddress"
                onChange={onPropertySelect}
                options={propertyHolderArray || []}
              />
            </FormGroup>
          </>
        )}

        {documentFor === 'owner' && (
          <>
            <FormGroup>
              <ControlLabel>Type of Document</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="select"
                name="documentTypeId"
                value={form.documentTypeId}
                onChange={handleInputChange}>
                <option value="">Choose one:</option>
                {ownerDocTypes &&
                  ownerDocTypes.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
              </FormControl>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Owner</ControlLabel>
              <Typeahead
                id="ownerName"
                labelKey="name"
                name="ownerName"
                onChange={onOwnerSelect}
                options={ownerHolderArray || []}
              />
            </FormGroup>
          </>
        )}

        <FormGroup>
          <ControlLabel>File Name</ControlLabel>
          <FormControl
            name="fileName"
            type="text"
            value={form.fileName}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>Document Date</ControlLabel>
          <DatePicker
            selected={form.documentDate}
            onChange={handleDateChange}
          />
        </FormGroup>

        <FormGroup>
          <ControlLabel>Upload PDF</ControlLabel>
          <Dropzone
            onDrop={onDrop}
            className="dropzone"
            accept="application/pdf">
            <p>Drop file here or click to select a file.</p>
          </Dropzone>
        </FormGroup>
        <Row>
          {files &&
            files.map((file) => (
              <Col md={4} key={file.name}>
                <object data={file.preview} type="application/pdf" width="100%">
                  <embed
                    src={file.preview}
                    type="application/pdf"
                    width="100%"
                  />
                </object>
                <p>{file.name}</p>
              </Col>
            ))}
        </Row>
        <ButtonToolbar>
          {!loading && (
            <Button type="submit" onClick={create}>
              Submit
            </Button>
          )}
          {loading && <div className="loader" style={{ margin: -10 }} />}
          <Button onClick={clearFiles}>Clear Files</Button>
        </ButtonToolbar>
        <Row>{error && <ErrorAlert errorMessage={error} />}</Row>
      </Form>
    </div>
  );
};

export default List;
